import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';


import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';


import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';

import icEdit from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icSearch from '@iconify/icons-ic/twotone-search';
import icAdd from '@iconify/icons-ic/twotone-add';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icMail from '@iconify/icons-ic/twotone-mail';
import icMap from '@iconify/icons-ic/twotone-map';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import icFolder from '@iconify/icons-ic/twotone-folder';
import icDateRange from '@iconify/icons-ic/twotone-date-range';
import icPerson from '@iconify/icons-ic/twotone-person';
import icRefresh from '@iconify/icons-ic/twotone-refresh';
import icBook from '@iconify/icons-ic/twotone-book';
import icCloudDownload from '@iconify/icons-ic/twotone-cloud-download';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';



import { MatMenu } from '@angular/material/menu';
import peopole from '@iconify/icons-ic/people-outline';
import { BeneficiariesService } from 'src/app/services/beneficiaries.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Customer } from '../../apps/aio-table/interfaces/customer.model';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { aioTableData, aioTableLabels } from 'src/static-data/aio-table-data';
import { SummaryData } from 'src/app/Models/models.interface';
import { AuthserviceService } from 'src/app/services/authservice.service';
import { TransactionsService } from 'src/app/services/transactions.service';
import { PendingBusinessModalComponent } from '../pending-business-modal/pending-business-modal.component';
import { SuspendedBusinessModalComponent } from '../suspended-business-modal/suspended-business-modal.component';
import { NoBusinessModalComponent } from '../no-business-modal/no-business-modal.component';
import { DisburseCashComponent } from '../disburse-cash/disburse-cash.component';
import { FilterFormComponent } from '../reports/all-transfers/filter-form/filter-form.component';
import { AddBeneficiaryComponent } from '../reports/all-transfers/add-beneficiary/add-beneficiary.component';
import { BUSINESS_DATA_KEY, COUNTRIES, SUMMARY_DATA_KEY, TRANSACTION_TABLE_LABELS, USER_SESSION_KEY } from 'src/app/Models/constants';
import { AddMobileBeneficiaryComponent } from '../reports/transactions-report/add-mobile-beneficiary/add-mobile-beneficiary.component';
import { TableUtil } from '../reports/transactions-report/tableUtil';
import { LOADING_BAR_CONFIG } from '@ngx-loading-bar/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';






@Component({
  selector: 'vex-beneficiaries',
  templateUrl: './beneficiaries.component.html',
  styleUrls: ['./beneficiaries.component.scss']
})
export class BeneficiariesComponent implements OnInit, OnDestroy {
  layoutCtrl = new FormControl('boxed');
  subject$: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);
  data$: Observable<Customer[]> = this.subject$.asObservable();
  unsubscribe$ = new Subject();
  @Input()

  beneficiariesDisplayedColumns: string[] = [

    'beneficiary_name',
    'bank_name',
    'account_number',
    'swift_code',
    'beneficiary_country',
    'options',

  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  transactionsDataSource: MatTableDataSource<any> | null;
  beneficiariesDataSource: MatTableDataSource<any> | null;
  selection = new SelectionModel<any>(true, []);
  searchCtrl = new FormControl();
  searchCtrlBeneficiary = new FormControl();
  exportOptions = {
    fileName: 'test',
    sheet: { reportsProps: { Author: 'PAL' } },
  };

  labels = aioTableLabels;
  form: FormGroup;

  statusLabels = TRANSACTION_TABLE_LABELS;
  banksData: any;
  countries = COUNTRIES;
  availableCountries = ['GH', 'BJ', 'CI', 'NGN'];
  networkProviders = ['mtn', 'orange'];
  currencies = ['GHS', 'XOF', 'XAF', 'NGN'];
  userData: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatTableExporterDirective)
  matTableExporter: MatTableExporterDirective;

  @ViewChild('menuMakeTransfer', { static: true })
  menuMakeTransfer: MatMenu;

  transactionsData: any;
  beneficiariesData: any;
  hasNoTransactions: boolean;
  isLoading: boolean = false;
  merchantSummaryData: SummaryData;

  userSessionData: any;
  hasError = false;
  errorMessage: string;

  customers: any;
  users: any;
  products: any;
  transactions: any;
  userBusinessData: any;
  credentials: string;

  icPhone = icPhone;
  icMail = icMail;
  icMap = icMap;
  icEdit = icEdit;
  icSearch = icSearch;
  icPerson = icPerson;
  icDelete = icDelete;
  icAdd = icAdd;
  icFilterList = icFilterList;
  icMoreHoriz = icMoreHoriz;
  icFolder = icFolder;
  icDateRange = icDateRange;
  icBook = icBook;
  icRefresh = icRefresh;
  icCloudDownload = icCloudDownload;
  icon_peapole = peopole;

  menu: { current: { name: string, value: string }, options: { name: string, value: string }[] } = {
    current: { name: 'Beneficiaries', value: "beneficiaries" },
    options: [
      { name: 'Bank Beneficiaries', value: "beneficiaries" },
      { name: 'Mobile Beneficiaries', value: "mb_beneficiaries" },

    ]
  };

  numberOfBeneficiaries = 0;
  beneficiaryOperationData: {
    message: string;
    selectedBankBeneficiary: any
  } = { message: null, selectedBankBeneficiary: null };












  // MOBILE BENEFICIARY

  MlayoutCtrl = new FormControl('boxed');
  Msubject$: ReplaySubject<Customer[]> = new ReplaySubject<Customer[]>(1);
  Mdata$: Observable<Customer[]> = this.subject$.asObservable();
  Munsubscribe$ = new Subject();
  @Input()
  MbeneficiariesColumns: TableColumn<Customer>[] = [
    // { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'ID', property: 'id', type: 'text', visible: true },
    {
      label: 'date',
      property: 'created_at',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Network',
      property: 'provider',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Wallet Number',
      property: 'phone_no',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Country',
      property: 'country',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Filter',
      property: 'filter',
      type: 'badge',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
  ];
  MpageSize = 10;
  MpageSizeOptions: number[] = [5, 10, 20, 50];
  MdataSource: MatTableDataSource<any> | null;
  MbeneficiariesDataSource: MatTableDataSource<any> | null;
  Mselection = new SelectionModel<any>(true, []);
  MsearchCtrl = new FormControl();
  MsearchCtrlBeneficiary = new FormControl();
  MexportOptions = {
    fileName: 'test',
    sheet: { reportsProps: { Author: 'PAL' } },
  };
  Mlabels = aioTableLabels;
  Mform: FormGroup;

  MstatusLabels = TRANSACTION_TABLE_LABELS;
  MdisplayedColumns: string[] = [];
  MtransactionColsOrder = [];
  MbeneficiariesDisplayedColumns: string[] = [
    'phone_no',
    'country',
    'bank_name',
    'options',
  ];
  statuses = [
    { name: 'Pending', value: 1 },
    { name: 'Completed', value: 3 },
    { name: 'Error', value: 4 },
    { name: 'Networ Error', value: 6 },
    { name: 'Processing', value: 2, },
    { name: 'Processing', value: 20, },
    { name: 'Cancelled', value: 0 },
  ];
  Mmenu: { current: { name: string, value: string }, options: { name: string, value: string }[] } = {
    current: { name: 'Payouts', value: "payouts" },
    options: [
      { name: 'Payouts', value: "payouts" },
      { name: 'Beneficiaries', value: "beneficiaries" },
    ]
  };
  MbeneficiaryOperationData: {
    message: string;
    selectedMobileBeneficiary: any
  } = { message: null, selectedMobileBeneficiary: null };
  /*
  countries = COUNTRIES;
  availableCountries = ['GH', 'BJ', 'CI'];
  networkProviders = ['mtn', 'orange'];
  currencies = ['GHS', 'XOF', 'XAF', 'NGN'];
  userData: any;
  */
  MnumberOfBeneficiaries = 0;

  @ViewChild(MatPaginator, { static: true }) Mpaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) Msort: MatSort;

  @ViewChild("menuMakeTransfer", { static: true })
  MmenuMakeTransfer: MatMenu;

  @ViewChild(MatTableExporterDirective)
  MmatTableExporter: MatTableExporterDirective;

  MtransactionsData: any;
  MtransactionType: any;
  MhasNoTransactions: boolean;
  MpalFee = 0;
  MisLoading: boolean;
  MmerchantSummaryData: SummaryData;
  MbeneficiariesData: any;

  MuserSessionData: any;
  MhasError = false;
  MerrorMessage: string;

  Mcustomers: any;
  Musers: any;
  Mproducts: any;
  Mtransactions: any;
  MuserBusinessData: any;
  //credentials: string;





  constructor(
    private authService: AuthserviceService,
    private router: Router,
    private fb: FormBuilder,
    private transactionService: TransactionsService,
    private beneficiariesService: BeneficiariesService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    const user = localStorage.getItem('current_user');
    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;

    this.merchantSummaryData = JSON.parse(localStorage.getItem(SUMMARY_DATA_KEY));

    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    if (businessData !== 'undefined') {
      this.userBusinessData = JSON.parse(businessData);
    }
    //this.credentials = `${this.userBusinessData?.api_secret_key_live}:${this.userBusinessData?.api_public_key_live}`;

    if (!sessionData) {
      router.navigate(['/auth/login']).then();
    }



  }

  getData() {
    return of(aioTableData.map((customer) => new Customer(customer)));
  }



  exportAsXlsx() {

    if (this.isAllowed("7")) {
      this.matTableExporter.exportTable('xlsx', {
        fileName: 'Transactions Report',
        sheet: 'report',
        Props: { Author: 'PAL Africa' },
      });
    } else {
      this.openSnackbar("You are not allowed");
    }

  }
  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }

  refreshData() {
    //vtxthis.getTransactionsList();

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {


    if (this.isAllowed('6') || this.isAllowed('1')) {





      this.form = this.fb.group({
        country: [''],
        dateFrom: [''],
        dateTo: [''],
        currency: [''],
        status: [''],
      });

      this.getBeneficiariesList();
      this.searchCtrlBeneficiary.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((value) => this.onFilterChangeBeneficiary(value));




      // MOBILE BENEFICIARY
      this.Mform = this.fb.group({
        status: [''],
        dateFrom: [''],
        dateTo: [''],
        currency: [''],
        operator: ['']
      });
      //this.MgetBeneficiariesList()


    } else {
      this.openSnackbar("You are not allowed");
    }


  }


  get isFormReady(): boolean {
    return true;
  }

  getCountryName(countryCode: string): string {
    const countryData = this.countries.find(
      (country) => country.code === countryCode
    );
    return countryData?.name;
  }

  getBankName(code: string) {
    const bank = this.banksData.find(
      (data) => data.code === code
    );
    return bank.name || 'N/A';
  }





  getStatusLabel(status: string) {
    return this.statusLabels.find((label) => label.text === status);
  }
  onFilterChangeBeneficiary(value: string) {
    if (!this.beneficiariesDataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.beneficiariesDataSource.filter = value;
  }


  getFlag(country) {


    switch (country.toLowerCase()) {

      case "benin":
        return "<img src='assets/contryFlg/flags/1/bj.png'>"
      case "ghana":
        return "<img src='assets/contryFlg/flags/1/gh.png'>"
      case "togo":
        return "<img src='assets/contryFlg/flags/1/tg.png'>"
      case "nigeria":
        return "<img src='assets/contryFlg/flags/1/ng.png'>"
      case "cote d'ivoire":
        return "<img src='assets/contryFlg/flags/1/ci.png'>"
      case "senegal":
        return "<img src='assets/contryFlg/flags/1/se.png'>"
      case "mali":
        return "<img src='assets/contryFlg/flags/1/ml.png'>"
      case "niger":
        return "<img src='assets/contryFlg/flags/1/ne.png'>"
      case "côte d'ivoire":
        return "<img src='assets/contryFlg/flags/1/ci.png'>"
      case "guinea-bissau":
        return "<img src='assets/contryFlg/flags/1/gw.png'>"
      case "burkina faso":
        return "<img src='assets/contryFlg/flags/1/bf.png'>"
      default:
        return ''

    }

  }



  openDisburseMobilePopup() {
    const config: MatDialogConfig = {
      width: '600px'
    };
    const dialogRef = this.dialog.open(DisburseCashComponent, config);
  }


  openAddBeneficiaryPopup() {

    if (this.isAllowed('6') || this.isAllowed('1')) {

      const config: MatDialogConfig = {
        width: '600px',
      };
      const dialogRef = this.dialog.open(AddBeneficiaryComponent, config);
      dialogRef.afterClosed().subscribe(
        (response) => {
          if (response) {
            this.openSnackbar(response.message);
            this.getBeneficiariesList();
          }
        },
        (error) => {
          this.showInternalServerErrorMessage()
        });


    } else {
      this.openSnackbar("You are not allowed");
    } 
  }

  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right',
    });
  }

  selectBankBeneficiary(id) {
    this.beneficiaryOperationData.selectedBankBeneficiary = id;
  }

  deleteBankBeneficiary(id: number) {
    this.beneficiariesService.deleteBankBeneficiary(this.credentials, { user_id: this.userData?.user_id, id: id })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.openSnackbar(response.message);
          this.getBeneficiariesList();
        },
        (error) => {
          this.isLoading = false;
          //console.error(error.message);
          this.showInternalServerErrorMessage()
        }
      );
  }

  getBeneficiariesList(filteredData = null) {
    this.isLoading = true;
    this.beneficiariesData = []
    this.beneficiariesDataSource = new MatTableDataSource(this.beneficiariesData);



    this.beneficiariesService.getBankBeneficiaries(this.userData?.user_id, this.credentials)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (beneficiaries) => {
          this.beneficiariesData = beneficiaries?.map((details) => {
            details.formatedDate = moment(details.created_at).fromNow();
            details.beneficiary_country = this.getCountryName(details.beneficiary_country);

            return details;
          });
          this.isLoading = false;
          this.beneficiariesDataSource = new MatTableDataSource(this.beneficiariesData);
          this.beneficiariesDataSource.paginator = this.paginator;
          this.beneficiariesDataSource.sort = this.sort;
          this.numberOfBeneficiaries = this.beneficiariesDataSource.data.length;
        },
        (error) => {
          this.isLoading = false;
          console.error(error.message);
        }
      );

  }













  selectOption(current: { name: string, value: string }) {

    if (this.isAllowed('6') || this.isAllowed('1')) {

      this.menu.current = current;
      this.beneficiariesData = []
      this.beneficiariesDataSource = new MatTableDataSource();
      this.MbeneficiariesData = []
      this.MbeneficiariesDataSource = new MatTableDataSource();
      if (this.menu?.current?.value === 'beneficiaries') {
        this.getBeneficiariesList();
      }
      if (this.menu?.current?.value === 'mb_beneficiaries') {
        this.MgetBeneficiariesList();
      }
    } else {
      this.openSnackbar("You are not allowed");
    }


  }

















  // MOBILE BENEFICIARY  
  MexportAsXlsx() {
    if (this.isAllowed("7")) {
      this.matTableExporter.exportTable('xlsx', {
        fileName: 'Transactions Report',
        sheet: 'report',
        Props: { Author: 'PAL Africa' },
      });
    } else {
      this.openSnackbar("You are not allowed");
    }
  }
  MisAllowed(habl: string) {
    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }
  MgetCountryName(countryCode: string): string {
    const countryData = this.countries.find(
      (country) => country.code === countryCode
    );
    return countryData.name;
  }
  getSalesRepName(user_id) {
    const salesRep = this.users?.find((user) => user.user_id === user_id);
    if (salesRep) {
      return salesRep.full_name;
    } else {
      return 'N/A';
    }
  }
  viewOrderDetails(order: any) {
    this.router.navigate(['/dashboards/orders/order-details/' + order.id]);
  }
  MgetStatusLabel(status: string) {
    return this.statusLabels.find((label) => label.text === status);
  }
  MonFilterChangeBeneficiary(value: string) {
    value = value.trim();
    value = value.toLowerCase();
    this.beneficiariesDataSource.filter = value;
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  exportTable() {
    TableUtil.exportTableToExcel('ExampleMaterialTable');
  }
  exportNormalTable() {
    TableUtil.exportTableToExcel('ExampleNormalTable');
  }



  MdeleteMobileBeneficiary(id: number) {
    this.beneficiariesService.deleteMobileBeneficiary(this.credentials, { user_id: this.userData?.user_id, id: id })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.openSnackbar(response.message);
          this.MgetBeneficiariesList();
        },
        (error) => {
          this.isLoading = false;
          //console.error(error.message);
          this.showInternalServerErrorMessage()
        }
      );
  }

  MgetBeneficiariesList(filteredData = null) {
    this.MisLoading = true;
    this.MbeneficiariesData = []
    this.MbeneficiariesDataSource = new MatTableDataSource();

    this.beneficiariesService.getMobileBeneficiaries(this.userData?.user_id, this.credentials)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (beneficiaries) => {
          this.MbeneficiariesData = beneficiaries?.map((details) => {
            details.formatedDate = moment(details.created_at).fromNow();
            details.country = this.MgetCountryName(details.country);
            return details;
          });
          this.MisLoading = false;
          this.MbeneficiariesDataSource = new MatTableDataSource(this.MbeneficiariesData);
          this.MbeneficiariesDataSource.paginator = this.Mpaginator;
          this.MbeneficiariesDataSource.sort = this.Msort;
          this.MnumberOfBeneficiaries = this.MbeneficiariesDataSource.data.length;
        },
        (error) => {
          this.MisLoading = false;
          console.error(error.message);
        }
      );
  }

  MselectMobileBeneficiary(id) {
    this.MbeneficiaryOperationData.selectedMobileBeneficiary = id;
  }

  MopenAddBeneficiaryPopup() {

    if (this.isAllowed('6') || this.isAllowed('1')) {
      const config: MatDialogConfig = {
        width: '600px',
      };
      const dialogRef = this.dialog.open(AddMobileBeneficiaryComponent, config);
      dialogRef.afterClosed().subscribe(
        (response) => {
          if (response) {
            this.openSnackbar(response.message);
            this.MgetBeneficiariesList();
          }
        },
        (error) => {
          this.showInternalServerErrorMessage()
        })

    } else {
      this.openSnackbar("You are not allowed");
    }

  }





  showInternalServerErrorMessage() {
    this.errorMessage = "Error. Please retry again later."
    this.openSnackbar("Error. Please retry again later.");
  }









}
