import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  BUSINESS_DATA_KEY,
  SUMMARY_DATA_KEY,
  USER_SESSION_KEY,
} from 'src/app/Models/constants';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'vex-make-transfer-choice',
  templateUrl: './make-transfer-choice.component.html',
  styleUrls: ['./make-transfer-choice.component.scss']
})
export class MakeTransferChoiceComponent implements OnInit {

  userData: any;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) { 
    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    //his.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;


    iconRegistry.addSvgIcon(
      'arrow',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/arrow.svg')
    );
  }

  ngOnInit(): void {
  }




  openDisburseBankPopup() {
    if (this.isAllowed('6') || this.isAllowed('1')) {
      this.router.navigate(['/dashboards/make-transfer']).then();
    } else {
      this.openSnackbar("You are not allowed");
    }
  }
  

  toggleBulkDisbursePopup() {
    if (this.isAllowed('6') || this.isAllowed('1')) {
      this.router.navigate(['/dashboards/bulk-disbursement']).then();
    } else {
      this.openSnackbar("You are not allowed");
    }
  }
  openDisburseMobilePopup() {
    if (this.isAllowed('6') || this.isAllowed('1')) {
      this.router.navigate(['/dashboards/make-mobile-disbursment']).then();
    } else {
      this.openSnackbar("You are not allowed");
    }


  }
  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }
  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 5000,
      horizontalPosition: 'right',
    });
  }


}
