import icEdit from '@iconify/icons-ic/twotone-edit';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import icSmartphone from '@iconify/icons-ic/twotone-smartphone';
import icPerson from '@iconify/icons-ic/twotone-person';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icMenu from '@iconify/icons-ic/twotone-menu';
import icCamera from '@iconify/icons-ic/twotone-camera';
import icPhone from '@iconify/icons-ic/twotone-phone';
import baselineFileUpload from '@iconify/icons-ic/baseline-file-upload';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { takeUntil, debounceTime, map, startWith } from 'rxjs/operators';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import outlineUpload from '@iconify/icons-ic/outline-upload';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import {
  BUSINESS_DATA_KEY,
  CATEGORIES,
  COUNTRIES,
  INDUSTRIES,
  NATIONALITIES,
  USER_SESSION_KEY,
} from 'src/app/Models/constants';
import { BusinessService } from 'src/app/services/business.service';
import { GeoLocationService } from 'src/app/services/geo-location.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MerchantData, User } from 'src/app/Models/models.interface';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { isEqual } from 'date-fns';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { AuthserviceService } from 'src/app/services/authservice.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { id } from 'date-fns/locale';
import { baseurlExp } from 'src/environments/environment';
import { VerificationService } from 'src/app/services/verification.service';

export interface CountryState {
  name: string;
  population: string;
  flag: string;
}

@Component({
  selector: 'vex-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      } as MatFormFieldDefaultOptions,
    },
  ],
})
export class ProfilComponent implements OnInit {

  panelOpenState = false;
  businessLogoName: string;
  idTypes = ['passport', 'Identity Card', 'Driving License'];
  isLegallyRegistered: boolean;

  businessForm: FormGroup;
  bsnessInfoForm: FormGroup;
  bsnessInfoInputStatus = {
    trading_name: '',
    business_name: '',
    description: '',
    industry: '',
    business_email: '',
    business_phone: '',
  };
  businessInformation: Object;
  business_email: string;
  emailAdssRegex = /\S+@\S+\.\S+/;
  menu: { current: { name: string, value: string }, options: { name: string, value: string }[] } = {
    current: { name: 'Profile', value: 'profile' },
    options: [
      { name: 'Profile', value: 'profile' },
      { name: 'Password & Security', value: 'security' },
      // { name: 'Owner', value: 'owner' },
      // { name: 'Business Profile', value: 'bussiness_settings' }, 
      //{ name: 'Collaborators & Team', value: "collaborators_team" },
    ]
  }
  alertBusinessLogo: boolean;
  businessLogo: File;
  locationData: any;
  countryData: any;
  waitingDisplayInput: boolean;
  prefixCountryCode: any;
  isValidCountry: Boolean;
  businessPhoneNumber: string;
  deliveryPhoneNumber: string;
  bsnessAdressForm: FormGroup;
  bsnessAdressInputStatus = {
    country: '',
    region: '',
    city: '',
    streetAddress: '',
  };
  bsnessOwnerForm: FormGroup;
  businessOwnerInformation: Object;
  bsnessOwnerInputStatus = {
    owner_lname: '',
    owner_fname: '',
    dob: '',
    nationality: '',
    owner_adresse: '',
    identification_document: '',
    is_legally_registered: '',
    business_legal_name: '',
    company_documents: '',
  };
  companyDocuments;
  idDocumentFile: File;
  companyDocumentFile: FileList;
  companyLogoFile: File;
  companyLogoUrl: string;
  unsubscribe$ = new Subject();
  errorMessage: string;

  isIdUpload: boolean;
  isCdUpload: boolean;
  idUploadMessge = 'Upload or drop';
  isCdUploadMessge = 'Upload or drop';
  rawDate = '';
  dateFormated = '';
  isLegalFomDisplay: boolean;
  // isLegallyRegistered = "";
  countries = COUNTRIES;
  nationalities = NATIONALITIES;
  categories = CATEGORIES;
  industries = INDUSTRIES;
  selectedFiles: FileList;
  isBusinessSubmitted = false;
  nameValidationPattern = /^[a-zA-Z0-9-\s]{0,25}$/;
  cityValidationPattern = /^[a-zA-Z-'\s]{0,25}$/;
  phoneNumberValidationPattern = /^[+][0-9]{0,15}$/;

  allCreatBusinessData: object;
  userData: User;
  validationMessages = {
    country: {
      required: 'This Field  is required.',
    },
    business_address: {
      required: 'This Field  is required.',
    },
    description: {
      required: 'This Field  is required.',
    },
    industry: {
      required: 'This Field  is required.',
    },
    business_email: {
      required: 'This Field  is required.',
      email: 'Please enter a valid email',
    },
    business_phone: {
      required: 'This Field  is required.',
      pattern: 'Only digits allowed',
    },
    staff_size: {
      required: 'This Field  is required.',
      pattern: 'Only digits allowed',
    },
    owner_full_name: {
      required: 'This Field  is required.',
      pattern: 'Only characters allowed',
    },
    dob: {
      required: 'This Field  is required.',
    },
    nationality: {
      required: 'This Field  is required.',
    },
    owner_address: {
      required: 'This Field  is required.',
    },
    id_type: {
      required: 'This Field  is required.',
    },
    business_legal_name: {
      required: 'This Field  is required.',
      pattern: 'Only characters and numbers allowed',
    },
    trading_name: {
      required: 'This Field  is required.',
    },


  };

  selectCtrl: FormControl = new FormControl();
  inputType = 'password';
  visible = false;

  afuConfig = {
    uploadAPI: {
      url: 'https://example-file-upload-api',
    },
    hideResetBtn: true,
  };

  profilPhotoConfig = {
    uploadAPI: {
      url: 'https://example-file-upload-api',
    },
    formatsAllowed: '.jpg,.png',
    hideResetBtn: true,
    replaceTexts: {
      selectFileBtn: 'select profile photo',
    },
  };

  icPhone = icPhone;
  icOutlineUpload = outlineUpload;
  icCamera = icCamera;
  icMenu = icMenu;
  icArrowDropDown = icArrowDropDown;
  icSmartphone = icSmartphone;
  icPerson = icPerson;
  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;
  icMoreVert = icMoreVert;
  icEdit = icEdit;

  stateCtrl = new FormControl();
  isAdding: boolean;
  hasError: boolean;
  updateBusinessForm: FormGroup;
  businessData: MerchantData;
  isUpdating: boolean;
  isUploading = false;
  isUploadingCompanyDoc = false

  credentials: string
  isTwoFactorAuthEnabled = false;
  haveABusiness: boolean = false;
  sessionData: any = {};

  browserLanguage: string
  personnalAccount: boolean = true
  createBusinessAccount: boolean = false
  alreadyHasBusiness: boolean = false
  newAccount: boolean = false



  showCertificateOfIncorporation: boolean = false
  showRccm: boolean = false
  showNinea: boolean = false
  showIfu: boolean = false
  showFiscalDeclaration: boolean = false
  showPersonnalID: boolean = true
  showCompanyDocument: boolean = true

  certificateOfIncorporation: FileList;
  rccm: FileList;
  ninea: FileList;
  ifu: FileList;
  fiscalDeclaration: FileList;
  // personnalID: FileList;
  //companyDocument: FileList;





  constructor(
    private cd: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private businessService: BusinessService,
    private geoLocationService: GeoLocationService,
    private snackBar: MatSnackBar,
    private authService: AuthserviceService,
    private translate: TranslateService,
    private route: ActivatedRoute,

    private verification: VerificationService
  ) {
    this.getLocationData();


    // vtx TRADUCTION 
    this.browserLanguage = window.navigator.language;
    let lang = this.browserLanguage.split('-')
    if (lang[0] == 'en') {
    }
    if (lang[0] == 'fr') {
      translate.setDefaultLang(lang[0]);
      this.translate.use(lang[0]);
    }

    //IF TEAM OPTION CLIQUED
    this.route.paramMap.subscribe((params) => {
      const id = params.get('team_option');
      if (id) {
        this.selectOption({ name: 'Collaborators & Team', value: "collaborators_team" })
      }
    });


    const sessionData = localStorage.getItem(USER_SESSION_KEY);
    if (sessionData) {
      this.sessionData = JSON.parse(sessionData);
      this.userData = JSON.parse(sessionData);
      this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;


      if (!this.sessionData['hasBusiness']) {
        this.newAccount = true
        this.isUploadingCompanyDoc = true //par defaut mettre sur true pour rendre le button new account valide
      } else {
        this.isUploading = true
      }
    }
    //
    const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
    if (businessData !== 'undefined') {
      this.businessData = JSON.parse(businessData);
      this.companyLogoUrl = this.businessData.business_logo;
      //this.credentials = `${this.businessData?.api_secret_key_live}:${this.businessData?.api_public_key_live}`;
      const baseURL = origin.includes('sandbox') || origin.includes('localhost:4200') ? 'https://api-test.pals.africa' : 'https://api.pals.africa'
      //this.companyLogoUrl = baseurlExp.imageBaseURL + `/public/uploads/company/business/${this.businessData?.business_logo}`;
      //this.companyLogoUrl = `https://api-prod-v0.pals.africa/api/uploads/company/business/logo?filename=${this.businessData?.business_logo}`;
      if (this.userData['two_factor_auth'] == true) {
        this.isTwoFactorAuthEnabled = true
      } else {
        this.isTwoFactorAuthEnabled = false
      }

      if (this.sessionData['business_type'] && this.sessionData['business_type'] == "business") {
        this.personnalAccount = false
        this.haveABusiness = true
        this.alreadyHasBusiness = true


        if (this.sessionData['hasBusiness']) {
          this.isUploading = true
          this.isUploadingCompanyDoc = true
        }
      }
      if (this.sessionData['business_type'] && this.sessionData['business_type'] == "personnal") {
        if (this.sessionData['hasBusiness']) {
          this.isUploading = true
          this.isUploadingCompanyDoc = false
        }
      }

    }






  }

    /**
   * PUBLIC prod_pk_BEVcBPK6BKFVLPIs6yC3l4tGr
APP 666c31cadda4fd003fe29a44
wIGT 66a7d829f699880040093452

   */


  requiredFilesUpload: boolean = false
  //app_id = '666c31cadda4fd003fe29a44'; // Remplacez par votre app_id
  //p_key = 'test_pk_NqtabBSxgMgbOWVD26K4n5vk1'; // Remplacez par votre clé publique

  app_id = '666c31cadda4fd003fe29a44'; // Remplacez par votre app_id
  p_key = 'prod_pk_BEVcBPK6BKFVLPIs6yC3l4tGr'; // Remplacez par votre clé publique

  ngOnInit() {

    this.verification.initializeWidget(this.app_id, this.p_key, this.userData['user_id']);





    this.businessForm = this.formBuilder.group({
      country: [''], //, Validators.required
      business_address: [''],//, Validators.required
      trading_name: [""],//, Validators.required
      description: [''],//, Validators.required
      industry: [''],//, Validators.required
      // category: ["", Validators.required],
      // business_email: ["", [Validators.required, Validators.email]],
      business_phone: [
        this.userData.mobile_phone,
        [
          Validators.required,
          //Validators.pattern(this.phoneNumberValidationPattern),
        ],
      ],
      owner_full_name: ['', Validators.required],
      dob: ['', Validators.required],
      nationality: ['', Validators.required],
      owner_address: ['', Validators.required],
      id_type: ['', Validators.required],
      id_proof_path: [''],
      company_documents: [''],
      business_legal_name: [''],//[Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+$')]
      company_documentUpload: [''],
      business_logo: [''],
      staff_size: [''],//, Validators.required
      website: [''],
      callback_url: [''],
    });




    let country = ""
    if (this.businessData?.country) country = this.businessData?.country
    let business_address = ""
    if (this.businessData?.business_address) business_address = this.businessData?.business_address
    let description = ""
    if (this.businessData?.description) description = this.businessData?.description
    let industry = ""
    if (this.businessData?.industry) industry = this.businessData?.industry
    let business_phone = ""
    if (this.businessData?.business_phone) business_phone = this.businessData?.business_phone
    let dob = ""
    if (this.businessData?.DOB) dob = this.businessData?.DOB
    let nationality = ""
    if (this.businessData?.nationality) nationality = this.businessData?.nationality
    let owner_address = ""
    if (this.businessData?.owner_address) owner_address = this.businessData?.owner_address
    let owner_full_name = ""
    if (this.businessData?.owner_full_name) owner_full_name = this.businessData?.owner_full_name
    let id_type = ""
    if (this.businessData?.id_type) id_type = this.businessData?.id_type
    let business_legal_name = ""
    if (this.businessData?.business_legal_name) business_legal_name = this.businessData?.business_legal_name
    let trading_name = ""
    if (this.businessData?.trading_name) trading_name = this.businessData?.trading_name
    let staff_size = ""
    if (this.businessData?.staff_size) staff_size = this.businessData?.staff_size
    this.updateBusinessForm = this.formBuilder.group({
      country: [country, Validators.required],
      business_address: [business_address,
        Validators.required,
      ],
      description: [description, Validators.required],
      industry: [industry, Validators.required],
      business_phone: [business_phone,
        [
          Validators.required,
          Validators.pattern(this.phoneNumberValidationPattern),
        ],
      ],
      owner_full_name: [owner_full_name,
        Validators.required,
      ],
      dob: [this.businessData?.DOB, Validators.required],
      nationality: [nationality, Validators.required],
      owner_address: [owner_address, Validators.required],
      id_type: [id_type, Validators.required],
      // id_proof_path: [""],
      company_documents: [''],
      business_legal_name: [business_legal_name, [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+$')]],
      trading_name: [trading_name || '', Validators.required],
      company_documentUpload: [''],
      business_logo: [this.businessData?.business_logo || ''],
      staff_size: [staff_size, Validators.required],
      website: [this.businessData?.website || ''],
      callback_url: [this.businessData?.callback_url || ''],
    });




    this.initForm()





    this.businessForm.get('country').valueChanges.subscribe(value => {
      this.showOptionalInputByBusinessCountry(value);
    });
    this.businessForm.get('nationality').valueChanges.subscribe(value => {
      this.showOptionalInputByNationality(value);
      this.requiredFilesUploaded()
    });



    this.updateBusinessForm.get('country').valueChanges.subscribe(value => {
      this.showOptionalInputByBusinessCountry(value);
    });
    this.updateBusinessForm.get('nationality').valueChanges.subscribe(value => {
      this.showOptionalInputByNationality(value);
      this.requiredFilesUploaded()
    });





  }


  openDojahWidget() {
    this.verification.openWidget();
  }


  initForm() {
    if (!this.sessionData['hasBusiness']) {
      this.newAccount = true
      this.personnalAccount = true


      this.businessForm = this.formBuilder.group({
        country: [''],
        business_address: [''],
        trading_name: [""],
        description: [''],
        industry: [''],
        business_phone: [
          this.userData.mobile_phone,
          [
            Validators.required,
            //Validators.pattern(this.phoneNumberValidationPattern),
          ],
        ],
        owner_full_name: ['', Validators.required],
        dob: ['', Validators.required],
        nationality: ['', Validators.required],
        owner_address: ['', Validators.required],
        id_type: ['', Validators.required],
        id_proof_path: [''],
        company_documents: [''],
        business_legal_name: [''],
        company_documentUpload: [''],
        business_logo: [''],
        staff_size: [''],
        website: [''],
        callback_url: [''],
      });

    } else {

      this.newAccount = false
      if (this.sessionData['business_type'] == "personnal") {
        this.personnalAccount = true
        let country = ""
        if (this.businessData?.country) country = this.businessData?.country
        let business_address = ""
        if (this.businessData?.business_address) business_address = this.businessData?.business_address
        let description = ""
        if (this.businessData?.description) description = this.businessData?.description
        let industry = ""
        if (this.businessData?.industry) industry = this.businessData?.industry
        let business_phone = ""
        if (this.businessData?.business_phone) business_phone = this.businessData?.business_phone
        let dob = ""
        if (this.businessData?.DOB) dob = this.businessData?.DOB
        let nationality = ""
        if (this.businessData?.nationality) nationality = this.businessData?.nationality
        let owner_address = ""
        if (this.businessData?.owner_address) owner_address = this.businessData?.owner_address
        let owner_full_name = ""
        if (this.businessData?.owner_full_name) owner_full_name = this.businessData?.owner_full_name
        let id_type = ""
        if (this.businessData?.id_type) id_type = this.businessData?.id_type
        let business_legal_name = ""
        if (this.businessData?.business_legal_name) business_legal_name = this.businessData?.business_legal_name
        let trading_name = ""
        if (this.businessData?.trading_name) trading_name = this.businessData?.trading_name
        let staff_size = ""
        if (this.businessData?.staff_size) staff_size = this.businessData?.staff_size

        this.updateBusinessForm = this.formBuilder.group({
          country: [country],
          business_address: [business_address],
          description: [description],
          industry: [industry],
          business_phone: [business_phone, [Validators.required, Validators.pattern(this.phoneNumberValidationPattern)]],
          owner_full_name: [owner_full_name,
            Validators.required,
          ],
          dob: [this.businessData?.DOB, Validators.required],
          nationality: [nationality, Validators.required],
          owner_address: [owner_address, Validators.required],
          id_type: [id_type, Validators.required],
          // id_proof_path: [""],
          company_documents: [''],
          business_legal_name: [business_legal_name],
          trading_name: [trading_name || ''],
          company_documentUpload: [''],
          business_logo: [this.businessData?.business_logo || ''],
          staff_size: [staff_size],
          website: [this.businessData?.website || ''],
          callback_url: [this.businessData?.callback_url || ''],
        });

      }
      if (this.sessionData['business_type'] == "business") {
        this.personnalAccount = false
        let country = ""
        if (this.businessData?.country) country = this.businessData?.country
        let business_address = ""
        if (this.businessData?.business_address) business_address = this.businessData?.business_address
        let description = ""
        if (this.businessData?.description) description = this.businessData?.description
        let industry = ""
        if (this.businessData?.industry) industry = this.businessData?.industry
        let business_phone = ""
        if (this.businessData?.business_phone) business_phone = this.businessData?.business_phone
        let dob = ""
        if (this.businessData?.DOB) dob = this.businessData?.DOB
        let nationality = ""
        if (this.businessData?.nationality) nationality = this.businessData?.nationality
        let owner_address = ""
        if (this.businessData?.owner_address) owner_address = this.businessData?.owner_address
        let owner_full_name = ""
        if (this.businessData?.owner_full_name) owner_full_name = this.businessData?.owner_full_name
        let id_type = ""
        if (this.businessData?.id_type) id_type = this.businessData?.id_type
        let business_legal_name = ""
        if (this.businessData?.business_legal_name) business_legal_name = this.businessData?.business_legal_name
        let trading_name = ""
        if (this.businessData?.trading_name) trading_name = this.businessData?.trading_name
        let staff_size = ""
        if (this.businessData?.staff_size) staff_size = this.businessData?.staff_size

        this.updateBusinessForm = this.formBuilder.group({
          country: [country, Validators.required],
          business_address: [business_address, Validators.required],
          description: [description, Validators.required],
          industry: [industry, Validators.required],
          business_phone: [business_phone, [Validators.required, Validators.pattern(this.phoneNumberValidationPattern)]],
          owner_full_name: [owner_full_name, Validators.required],
          dob: [this.businessData?.DOB, Validators.required],
          nationality: [nationality, Validators.required],
          owner_address: [owner_address, Validators.required],
          id_type: [id_type, Validators.required],
          // id_proof_path: [""],
          company_documents: [''],
          business_legal_name: [business_legal_name, [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+$')]],
          trading_name: [trading_name || '', Validators.required],
          company_documentUpload: [''],
          business_logo: [this.businessData?.business_logo || ''],
          staff_size: [staff_size, Validators.required],
          website: [this.businessData?.website || ''],
          callback_url: [this.businessData?.callback_url || ''],
        });


      }
    }
  }
  resetFormValidators() {

    // IF NEW ACCOUNT 
    if (this.newAccount) {
      this.setaddBusinessFormValidators()
    } else {
      this.setUpdateBusinessFormValidators()
    }


  }


  setaddBusinessFormValidators0(): FormGroup {
    if (!this.personnalAccount) {
      let country = ""
      if (this.businessForm.value.country) country = this.businessForm.value.country
      let business_address = ""
      if (this.businessForm.value.business_address) business_address = this.businessForm.value.business_address
      let description = ""
      if (this.businessForm.value.description) description = this.businessForm.value.description
      let industry = ""
      if (this.businessForm.value.industry) industry = this.businessForm.value.industry
      let business_phone = ""
      if (this.businessForm.value.business_phone) {
        business_phone = this.businessForm.value.business_phone
      } else {
        business_phone = this.userData.mobile_phone
      }
      let dob = ""
      if (this.businessForm.value.dob) dob = this.businessForm.value.dob
      let nationality = ""
      if (this.businessForm.value.nationality) nationality = this.businessForm.value.nationality
      let owner_address = ""
      if (this.businessForm.value.owner_address) owner_address = this.businessForm.value.owner_address
      let owner_full_name = ""
      if (this.businessForm.value.owner_full_name) owner_full_name = this.businessForm.value.owner_full_name
      let id_type = ""
      if (this.businessForm.value.id_type) id_type = this.businessForm.value.id_type
      let business_legal_name = ""
      if (this.businessForm.value.business_legal_name) business_legal_name = this.businessForm.value.business_legal_name
      let trading_name = ""
      if (this.businessForm.value.trading_name) trading_name = this.businessForm.value.trading_name
      let staff_size = ""
      if (this.businessForm.value.staff_size) staff_size = this.businessForm.value.staff_size
      let website = ""
      if (this.businessForm.value.website) website = this.businessForm.value.website
      let callback_url = ""
      if (this.businessForm.value.callback_url) callback_url = this.businessForm.value.callback_url

      return this.formBuilder.group({
        country: [country, Validators.required],
        business_address: [business_address, Validators.required],
        trading_name: [trading_name, Validators.required],
        description: [description, Validators.required],
        industry: [industry, Validators.required],
        business_phone: [
          business_phone,
          [
            Validators.required,
            Validators.pattern(this.phoneNumberValidationPattern),
          ],
        ],
        owner_full_name: [owner_full_name, Validators.required],
        dob: [dob, Validators.required],
        nationality: [nationality, Validators.required],
        owner_address: [owner_address, Validators.required],
        id_type: [id_type, Validators.required],
        id_proof_path: [''],
        company_documents: [''],
        business_legal_name: [business_legal_name, [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+$')]],
        company_documentUpload: [''],
        business_logo: [''],
        staff_size: [staff_size, Validators.required],
        website: [website],
        callback_url: [callback_url],
      });
    } else {
      let country = ""
      let isValidForm = true
      if (this.businessForm.value.country) country = this.businessForm.value.country
      let business_address = ""
      if (this.businessForm.value.business_address) business_address = this.businessForm.value.business_address
      let description = ""
      if (this.businessForm.value.description) description = this.businessForm.value.description
      let industry = ""
      if (this.businessForm.value.industry) industry = this.businessForm.value.industry
      let business_phone = ""
      if (this.businessForm.value.business_phone) {
        business_phone = this.businessForm.value.business_phone
      } else {
        business_phone = this.userData.mobile_phone
      }
      let dob = ""
      if (this.businessForm.value.dob) {
        dob = this.businessForm.value.dob
      } else {
        isValidForm = false
      }
      let nationality = ""
      if (this.businessForm.value.nationality) {
        nationality = this.businessForm.value.nationality
      } else {
        isValidForm = false
      }
      let owner_address = ""
      if (this.businessForm.value.owner_address) {
        owner_address = this.businessForm.value.owner_address
      } else {
        isValidForm = false
      }
      let owner_full_name = ""
      if (this.businessForm.value.owner_full_name) {
        owner_full_name = this.businessForm.value.owner_full_name
      } else {
        isValidForm = false
      }
      let id_type = ""
      if (this.businessForm.value.id_type) {
        id_type = this.businessForm.value.id_type
      } else {
        isValidForm = false
      }
      let business_legal_name = ""
      if (this.businessForm.value.business_legal_name) business_legal_name = this.businessForm.value.business_legal_name
      let trading_name = ""
      if (this.businessForm.value.trading_name) trading_name = this.businessForm.value.trading_name
      let staff_size = ""
      if (this.businessForm.value.staff_size) staff_size = this.businessForm.value.staff_size
      let website = ""
      if (this.businessForm.value.website) website = this.businessForm.value.website
      let callback_url = ""
      if (this.businessForm.value.callback_url) callback_url = this.businessForm.value.callback_url

      Object.keys(this.businessForm.controls).forEach(field => {
        const control = this.businessForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      if (isValidForm) {
        this.businessForm.setErrors(null);
        //this.businessForm.markAsValid();
      }
      //return this.businessForm 



      return this.formBuilder.group({
        country: [''],
        business_address: [''],
        trading_name: [""],
        description: [''],
        industry: [''],
        business_phone: [
          business_phone
        ],
        owner_full_name: [owner_full_name],//, Validators.required
        dob: [dob], //, Validators.required
        nationality: [nationality],//, Validators.required
        owner_address: [owner_address],//, Validators.required
        id_type: [id_type],//, Validators.required
        id_proof_path: [''],
        company_documents: [''],
        business_legal_name: [''],
        company_documentUpload: [''],
        business_logo: [''],
        staff_size: [''],
        website: [''],
        callback_url: [''],
      });

    }
  }


  setaddBusinessFormValidators(): void {
    if (!this.personnalAccount) {
      this.applyBusinessValidators();
    } else {
      this.applyPersonalValidators();
      this.clearBusinessFields()
    }
  }





  applyBusinessValidators(): void {

    this.businessForm.get('country').setValidators(Validators.required);
    this.businessForm.get('business_address').setValidators(Validators.required);
    this.businessForm.get('trading_name').setValidators(Validators.required);
    this.businessForm.get('description').setValidators(Validators.required);
    this.businessForm.get('industry').setValidators(Validators.required);
    this.businessForm.get('business_phone').setValidators([
      Validators.required,
      Validators.pattern(this.phoneNumberValidationPattern),
    ]);
    this.businessForm.get('owner_full_name').setValidators(Validators.required);
    this.businessForm.get('dob').setValidators(Validators.required);
    this.businessForm.get('nationality').setValidators(Validators.required);
    this.businessForm.get('owner_address').setValidators(Validators.required);
    this.businessForm.get('id_type').setValidators(Validators.required);
    this.businessForm.get('business_legal_name').setValidators([
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9 ]+$'),
    ]);
    this.businessForm.get('staff_size').setValidators(Validators.required);

    this.updateFormValidators();
  }
  applyPersonalValidators(): void {

    this.businessForm.get('country').clearValidators();
    this.businessForm.get('business_address').clearValidators();
    this.businessForm.get('trading_name').clearValidators();
    this.businessForm.get('description').clearValidators();
    this.businessForm.get('industry').clearValidators();
    this.businessForm.get('business_phone').clearValidators();
    this.businessForm.get('owner_full_name').clearValidators();
    this.businessForm.get('dob').clearValidators();
    this.businessForm.get('nationality').clearValidators();
    this.businessForm.get('owner_address').clearValidators();
    this.businessForm.get('id_type').clearValidators();
    this.businessForm.get('business_legal_name').clearValidators();
    this.businessForm.get('staff_size').clearValidators();

    this.updateFormValidators();
  }
  clearBusinessFields(): void {

    this.businessForm.patchValue({
      country: '',
      business_address: '',
      trading_name: '',
      description: '',
      industry: '',
      business_phone: this.userData.mobile_phone,
      //owner_full_name: '',
      //dob: '',
      //nationality: '',
      //owner_address: '',
      //id_type: '',
      business_legal_name: '',
      staff_size: '',
      website: '',
      callback_url: '',
    });
  }
  updateFormValidators(): void {
    Object.keys(this.businessForm.controls).forEach(field => {
      const control = this.businessForm.get(field);
      control.updateValueAndValidity();
    });
  }











  setUpdateBusinessFormValidators0(): FormGroup {
    if (this.personnalAccount) {
      let country = ""
      if (this.businessData?.country) country = this.businessData?.country
      let business_address = ""
      if (this.businessData?.business_address) business_address = this.businessData?.business_address
      let description = ""
      if (this.businessData?.description) description = this.businessData?.description
      let industry = ""
      if (this.businessData?.industry) industry = this.businessData?.industry
      let business_phone = ""
      if (this.businessData?.business_phone) business_phone = this.businessData?.business_phone
      let dob = ""
      if (this.businessData?.DOB) dob = this.businessData?.DOB
      let nationality = ""
      if (this.businessData?.nationality) nationality = this.businessData?.nationality
      let owner_address = ""
      if (this.businessData?.owner_address) owner_address = this.businessData?.owner_address
      let owner_full_name = ""
      if (this.businessData?.owner_full_name) owner_full_name = this.businessData?.owner_full_name
      let id_type = ""
      if (this.businessData?.id_type) id_type = this.businessData?.id_type
      let business_legal_name = ""
      if (this.businessData?.business_legal_name) business_legal_name = this.businessData?.business_legal_name
      let trading_name = ""
      if (this.businessData?.trading_name) trading_name = this.businessData?.trading_name
      let staff_size = ""
      if (this.businessData?.staff_size) staff_size = this.businessData?.staff_size
      return this.formBuilder.group({
        country: [''],
        business_address: [''],
        trading_name: [""],
        description: [''],
        industry: [''],
        business_phone: [
          this.userData.mobile_phone,
          [
            Validators.required,
            Validators.pattern(this.phoneNumberValidationPattern),
          ],
        ],
        owner_full_name: [owner_full_name, Validators.required],
        dob: [dob, Validators.required],
        nationality: [nationality, Validators.required],
        owner_address: [owner_address, Validators.required],
        id_type: [id_type, Validators.required],
        id_proof_path: [''],
        company_documents: [''],
        business_legal_name: [''],
        company_documentUpload: [''],
        business_logo: [''],
        staff_size: [''],
        website: [''],
        callback_url: [''],
      });
    } else { // A DEJA UN ACCOUNT BUSINESS


      let country = ""
      if (this.businessData?.country) country = this.businessData?.country
      let business_address = ""
      if (this.businessData?.business_address) business_address = this.businessData?.business_address
      let description = ""
      if (this.businessData?.description) description = this.businessData?.description
      let industry = ""
      if (this.businessData?.industry) industry = this.businessData?.industry
      let business_phone = ""
      if (this.businessData?.business_phone) business_phone = this.businessData?.business_phone
      let dob = ""
      if (this.businessData?.DOB) dob = this.businessData?.DOB
      let nationality = ""
      if (this.businessData?.nationality) nationality = this.businessData?.nationality
      let owner_address = ""
      if (this.businessData?.owner_address) owner_address = this.businessData?.owner_address
      let owner_full_name = ""
      if (this.businessData?.owner_full_name) owner_full_name = this.businessData?.owner_full_name
      let id_type = ""
      if (this.businessData?.id_type) id_type = this.businessData?.id_type
      let business_legal_name = ""
      if (this.businessData?.business_legal_name) business_legal_name = this.businessData?.business_legal_name
      let trading_name = ""
      if (this.businessData?.trading_name) trading_name = this.businessData?.trading_name
      let staff_size = ""
      if (this.businessData?.staff_size) staff_size = this.businessData?.staff_size

      return this.formBuilder.group({
        country: [country, Validators.required],
        business_address: [business_address,
          Validators.required,
        ],
        description: [description, Validators.required],
        industry: [industry, Validators.required],
        business_phone: [business_phone,
          [
            Validators.required,
            Validators.pattern(this.phoneNumberValidationPattern),
          ],
        ],
        owner_full_name: [owner_full_name,
          Validators.required,
        ],
        dob: [this.businessData?.DOB, Validators.required],
        nationality: [nationality, Validators.required],
        owner_address: [owner_address, Validators.required],
        id_type: [id_type, Validators.required],
        // id_proof_path: [""],
        company_documents: [''],
        business_legal_name: [business_legal_name, [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+$')]],
        trading_name: [trading_name || '', Validators.required],
        company_documentUpload: [''],
        business_logo: [this.businessData?.business_logo || ''],
        staff_size: [staff_size, Validators.required],
        website: [this.businessData?.website || ''],
        callback_url: [this.businessData?.callback_url || ''],
      });












    }
  }

  setUpdateBusinessFormValidators(): void {
    if (!this.personnalAccount) {
      this.applyBusinessValidatorsForUpdate();
    } else {
      this.applyPersonalValidatorsForUpdate();
      this.clearBusinessFields()
    }
  }
  applyBusinessValidatorsForUpdate(): void {

    this.updateBusinessForm.get('country').setValidators(Validators.required);
    this.updateBusinessForm.get('business_address').setValidators(Validators.required);
    this.updateBusinessForm.get('trading_name').setValidators(Validators.required);
    this.updateBusinessForm.get('description').setValidators(Validators.required);
    this.updateBusinessForm.get('industry').setValidators(Validators.required);
    this.updateBusinessForm.get('business_phone').setValidators([
      Validators.required,
      Validators.pattern(this.phoneNumberValidationPattern),
    ]);
    this.updateBusinessForm.get('owner_full_name').setValidators(Validators.required);
    this.updateBusinessForm.get('dob').setValidators(Validators.required);
    this.updateBusinessForm.get('nationality').setValidators(Validators.required);
    this.updateBusinessForm.get('owner_address').setValidators(Validators.required);
    this.updateBusinessForm.get('id_type').setValidators(Validators.required);
    this.updateBusinessForm.get('business_legal_name').setValidators([
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9 ]+$'),
    ]);
    this.updateBusinessForm.get('staff_size').setValidators(Validators.required);

    this.updateFormValidatorsForUpdate();
  }
  applyPersonalValidatorsForUpdate(): void {

    this.updateBusinessForm.get('country').clearValidators();
    this.updateBusinessForm.get('business_address').clearValidators();
    this.updateBusinessForm.get('trading_name').clearValidators();
    this.updateBusinessForm.get('description').clearValidators();
    this.updateBusinessForm.get('industry').clearValidators();
    this.updateBusinessForm.get('business_phone').clearValidators();
    this.updateBusinessForm.get('owner_full_name').clearValidators();
    this.updateBusinessForm.get('dob').clearValidators();
    this.updateBusinessForm.get('nationality').clearValidators();
    this.updateBusinessForm.get('owner_address').clearValidators();
    this.updateBusinessForm.get('id_type').clearValidators();
    this.updateBusinessForm.get('business_legal_name').clearValidators();
    this.updateBusinessForm.get('staff_size').clearValidators();

    this.updateFormValidators();
  }
  clearBusinessFieldsForUpdate(): void {

    this.updateBusinessForm.patchValue({
      country: '',
      business_address: '',
      trading_name: '',
      description: '',
      industry: '',
      business_phone: this.userData.mobile_phone,
      //owner_full_name: '',
      //dob: '',
      //nationality: '',
      //owner_address: '',
      //id_type: '',
      business_legal_name: '',
      staff_size: '',
      website: '',
      callback_url: '',
    });
  }
  updateFormValidatorsForUpdate(): void {
    Object.keys(this.updateBusinessForm.controls).forEach(field => {
      const control = this.updateBusinessForm.get(field);
      control.updateValueAndValidity();
    });
  }












  requiredFilesUploaded() {


    if (this.personnalAccount) {
      if (this.businessForm.get('nationality').value == "Nigerian" || this.updateBusinessForm.get('nationality').value == "Nigerian") {
        this.requiredFilesUpload = true
      } else {
        if (this.idDocumentFile) {
          this.requiredFilesUpload = true
        } else {
          this.requiredFilesUpload = false
        }
      }
    }

    if (!this.personnalAccount) {
      let id_uploaded = false
      if (this.businessForm.get('nationality').value == "Nigerian" || this.updateBusinessForm.get('nationality').value == "Nigerian") {
        id_uploaded = true
      } else {
        if (this.idDocumentFile) {
          id_uploaded = true
        } else {
          id_uploaded = false
        }
      }

      let selectedCountry
      //(this.newAccount) ?  selectedCountry = this.businessForm.get('country').value : this.updateBusinessForm.get('country').value
      selectedCountry = (this.newAccount) ? this.businessForm.get('country').value : this.updateBusinessForm.get('country').value
      let business_doc = false
      switch (selectedCountry) {
        case "NG":
          business_doc = (this.certificateOfIncorporation) ? true : false
          break;
        case "BJ":
          business_doc = (this.rccm && this.ifu) ? true : false
          break;
        case "SN":
          business_doc = (this.rccm && this.ninea) ? true : false
          break;
        case "CI":
          business_doc = (this.rccm && this.fiscalDeclaration) ? true : false
          break;
        default:
          business_doc = (this.companyDocumentFile) ? true : false
          break;
      }


      if (id_uploaded && business_doc) {
        this.requiredFilesUpload = true
      } else {
        this.requiredFilesUpload = false
      }

    }


  }







  getProcessedphoneNumber(phoneNumber) {
    let phoneNumberWithoutSpace = phoneNumber.split(/\s/).join('');
    return this.prefixCountryCode + phoneNumberWithoutSpace.substr(1);
  }











  uploadID(event) {
    this.idDocumentFile = event.target.files[0];
    this.isUploading = true;

    this.requiredFilesUploaded()
  }
  uploadCompanyLogo(event) {
    this.companyLogoFile = event.target.files[0];
    this.isUploading = true;
    const reader: FileReader = new FileReader();
    reader.onload = () => {
      this.companyLogoUrl = reader.result as string;
    };
    reader.readAsDataURL(this.companyLogoFile);
  }

  validateID() {
    if (!this.idDocumentFile) {
      return {
        missingIDFile: true,
      };
    } else {
      return null;
    }
  }
  uploadCompanyDoc(event) {
    const files = event.target.files;
    this.companyDocumentFile = files;
    this.isUploadingCompanyDoc = true
    this.requiredFilesUploaded()
  }
  uploadCompanyDocCertificateOfIncorporation(event) {
    const files = event.target.files;
    this.certificateOfIncorporation = files;
    this.isUploadingCompanyDoc = true
    this.requiredFilesUploaded()

  }
  uploadCompanyDocFiscalDeclaration(event) {
    const files = event.target.files;
    this.fiscalDeclaration = files;
    this.isUploadingCompanyDoc = true
    this.requiredFilesUploaded()

  }
  uploadCompanyDocRccm(event) {
    const files = event.target.files;
    this.rccm = files;
    this.isUploadingCompanyDoc = true
    this.requiredFilesUploaded()

  }
  uploadCompanyDocNinea(event) {
    const files = event.target.files;
    this.ninea = files;
    this.isUploadingCompanyDoc = true
    this.requiredFilesUploaded()

  }
  uploadCompanyDocIfu(event) {
    const files = event.target.files;
    this.ifu = files;
    this.isUploadingCompanyDoc = true
    this.requiredFilesUploaded()
  }
  uploadCompanyDoc2(event) {
    const files = event.target.files;

  }









  updateCompanyLogo(event) {
    this.companyLogoFile = event.target.files[0];
    this.isUploading = true;
    const reader: FileReader = new FileReader();
    reader.onload = () => {
      this.companyLogoUrl = reader.result as string;
    };
    reader.readAsDataURL(this.companyLogoFile);
    let justUpdateLogo = true
    this.updateBusinessProfile(justUpdateLogo)
  }
  updateBusinessProfile(justUpdateLogo = false) { 
    if (this.isAllowed("2")) {


      this.isBusinessSubmitted = true;
      this.isUpdating = true;

      const businessData = this.updateBusinessForm.value;
      businessData.id_proof_path = this.idDocumentFile || '';
      businessData.business_logo = this.companyLogoFile || this.businessData?.business_logo;

      businessData.company_documentUpload = this.companyDocumentFile || this.businessData?.company_document_path;
      businessData.certificate_of_incorporation = this.certificateOfIncorporation || this.businessData?.certificate_of_incorporation;
      businessData.fiscal_declaration = this.fiscalDeclaration || this.businessData?.fiscal_declaration;
      businessData.rccm = this.rccm || this.businessData?.rccm;
      businessData.ninea = this.ninea || this.businessData?.ninea;
      businessData.ifu = this.ifu || this.businessData?.ifu;

      businessData.user_id = this.userData.user_id;
      businessData.status = this.businessData?.status;
      businessData.business_email = this.userData.email;
      businessData.business_phone = this.updateBusinessForm.value['business_phone'];

      if (justUpdateLogo == true) {
        businessData.update_profile = true
      }


      this.businessService
        .updateBusinessData(businessData, this.businessData.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((response: any) => {
          this.isUpdating = false;
          if ((response.status = true && response.data)) {

            if (justUpdateLogo == true) {
              //businessData.update_profile = true
              //ne rien faire
            } else {

              //DESACTIVATION DE DOJAH 
              /*
              this.openSnackbar(response['message']);
              localStorage.setItem(
                BUSINESS_DATA_KEY,
                JSON.stringify(response.data)
              );
              const parsedUrl = new URL(window.location.href);
              const baseUrl = parsedUrl.origin;
              window.location.replace(`${baseUrl}/dashboards/analytics`); 
              */
              //FIN DE DESACTIVATION 

              
              if (businessData.nationality === 'Nigerian') {
               
                this.verification.openWidget()
                  .then((response: any) => {
                    this.openSnackbar(response['message']);
                    localStorage.setItem(
                      BUSINESS_DATA_KEY,
                      JSON.stringify(response.data)
                    );
                    const parsedUrl = new URL(window.location.href);
                    const baseUrl = parsedUrl.origin;
                    window.location.replace(`${baseUrl}/dashboards/analytics`);
                  })
                  .catch((err: any) => {
                    console.error('Dojah error:', err);
                  });
                   
              } else {
                this.openSnackbar(response['message']);
                localStorage.setItem(
                  BUSINESS_DATA_KEY,
                  JSON.stringify(response.data)
                );
                const parsedUrl = new URL(window.location.href);
                const baseUrl = parsedUrl.origin;
                window.location.replace(`${baseUrl}/dashboards/analytics`);
              } 
               





            }




          } else {
            if (response.status = false) {
              this.errorMessage = response.message || 'something went wrong';
              this.isBusinessSubmitted = false;
              this.hasError = true;
              this.openSnackbar(response['message']);
            } else {
              this.isUpdating = false;
              this.hasError = true;
              this.showInternalServerErrorMessage()
            }

          }
        }),
        (error: any) => {
          this.isUpdating = false;
          this.hasError = true;
          this.showInternalServerErrorMessage()
        };





    } else {
      this.openSnackbar("You are not allowed");
    }
  }
  showInternalServerErrorMessage() {
    this.errorMessage = "Error. Please retry again later."
    this.openSnackbar("Error. Please retry again later.");
  }

  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      if (this.userData.habilitations != undefined) {
        habilitations = this.userData.habilitations.split(',')
        for (let hab of habilitations) {
          if (hab == habl) {
            isAllow = true
          }
        }
      }
    } else {
      isAllow = true
    }
    return isAllow
  }

  isValidForm() {
    //updateBusinessForm.invalid || !updateBusinessForm.dirty
    if (this.personnalAccount) {
      if (!this.updateBusinessForm.dirty) {
        return true
      }
    } else {
      if (this.updateBusinessForm.invalid) {
        return true
      }
    }

  }
  isValidFormForNewAccount() {
    //return true 
    if (this.newAccount) {
      if (this.personnalAccount) {
        return true
      } else {
        if (this.businessForm.invalid) {
          return false
        } else {
          return true
        }
      }

    }
  }

  customCheckValidForm() {
    return true
    if (this.personnalAccount) {
      return true
    } else {
      if (this.businessForm.invalid || !this.idDocumentFile) {
        alert('Business Form Invalid')
        Object.keys(this.businessForm.controls).forEach(field => {
          const control = this.businessForm.get(field);
          control.markAsTouched({ onlySelf: true });
        });
        return false
      } else {
        return true
      }

    }
  }




  showOptionalInputByBusinessCountry(businessCountry) {
    this.showCompanyDocument = true
    this.showCertificateOfIncorporation = false
    this.showFiscalDeclaration = false
    this.showRccm = false
    this.showNinea = false
    this.showIfu = false



    switch (businessCountry) {
      case "NG":
        this.showCompanyDocument = false;
        this.showCertificateOfIncorporation = true;
        this.showFiscalDeclaration = false;
        this.showRccm = false;
        this.showNinea = false;
        this.showIfu = false;

        this.certificateOfIncorporation = null;
        break;
      case "BJ":
        this.showCompanyDocument = false;
        this.showCertificateOfIncorporation = false;
        this.showFiscalDeclaration = false;
        this.showRccm = true;
        this.showNinea = false;
        this.showIfu = true;

        this.rccm = null;
        this.ifu = null;
        break;
      case "SN":
        this.showCompanyDocument = false;
        this.showCertificateOfIncorporation = false;
        this.showFiscalDeclaration = false;
        this.showRccm = true;
        this.showNinea = true;
        this.showIfu = false;

        this.rccm = null;
        this.ninea = null;
        break;
      case "CI":
        this.showCompanyDocument = false;
        this.showCertificateOfIncorporation = false;
        this.showFiscalDeclaration = true;
        this.showRccm = true;
        this.showNinea = false;
        this.showIfu = false;

        this.rccm = null;
        this.fiscalDeclaration = null;
        break;
      default:
        //this.companyDocumentFile = null
        break;
    }

    //console.logg("relancer le recheck apres avoir change le country")
    this.requiredFilesUploaded()


  }
  showOptionalInputByNationality(businessCountry) {
    this.showPersonnalID = true
    if (businessCountry == "Nigerian") {
      this.showPersonnalID = false
    }

  }







  addBusiness1() {

    if (!this.customCheckValidForm()) {
    } else {





      this.isBusinessSubmitted = true;
      this.isAdding = true;
      const businessData = this.businessForm.value;
      businessData.id_proof_path = this.idDocumentFile || '';
      businessData.company_documentUpload = this.companyDocumentFile || '';
      businessData.business_logo = this.companyLogoFile || '';
      businessData.user_id = this.userData.user_id;
      businessData.business_email = this.userData.email;
      businessData.business_phone = this.businessForm.value['business_phone'];
      businessData.website = this.businessForm.value['website'];
      if (this.personnalAccount) {
        businessData.business_type = "personnal";
      }

      this.continueAddbusiness(businessData);

      /*
      if (businessData.nationality === 'Nigerian') {
        this.verification.openWidget()
          .then((response: any) => { 
            this.continueAddbusiness(businessData);
          })
          .catch((err: any) => {
            console.error('Dojah error:', err);
          });
      } else {
        this.continueAddbusiness(businessData);
      }
        */




    }
  }
  addBusiness() {



    if (!this.customCheckValidForm()) {
    } else {





      this.isBusinessSubmitted = true;
      this.isAdding = true;
      const businessData = this.businessForm.value;
      businessData.id_proof_path = this.idDocumentFile || '';

      businessData.company_documentUpload = this.companyDocumentFile || '';
      businessData.certificate_of_incorporation = this.certificateOfIncorporation || '';
      businessData.fiscal_declaration = this.fiscalDeclaration || '';
      businessData.rccm = this.rccm || '';
      businessData.ninea = this.ninea || '';
      businessData.ifu = this.ifu || '';

      businessData.business_logo = this.companyLogoFile || '';
      businessData.user_id = this.userData.user_id;
      businessData.business_email = this.userData.email;
      businessData.business_phone = this.businessForm.value['business_phone'];
      businessData.website = this.businessForm.value['website'];
      if (this.personnalAccount) {
        businessData.business_type = "personnal";
      }

      this.continueAddbusiness(businessData);


      /*
      if (businessData.nationality === 'Nigerian') {

        this.verification.openWidget()
          .then((response: any) => { 
            this.continueAddbusiness(businessData);
          })
          .catch((err: any) => {
            console.error('Dojah error:', err);
            this.errorMessage = 'Something went wrong. Please retry';
            this.isBusinessSubmitted = false;
            this.hasError = true;
            this.openSnackbar(this.errorMessage);
          });
      } else {
        this.continueAddbusiness(businessData); 
      }
        */

    }
  }
  //JUST CREATE BUSINESS
  continueAddbusiness0(businessData: any) {
    this.businessService
      .createNewBusiness(businessData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: any) => {
        this.isAdding = false;
        if ((response.status = true && response.data)) {
          //this.openSnackbar(response['message']);
          localStorage.setItem(
            BUSINESS_DATA_KEY,
            JSON.stringify(response.data)
          );
          const parsedUrl = new URL(window.location.href);
          const baseUrl = parsedUrl.origin;
          // window.location.replace(`${baseUrl}/dashboards/analytics`);
          this.logout();

        } else {
          this.errorMessage = response.message || 'Something went wrong. Please Make sure you filled both Owner and Business Profile information';
          this.isBusinessSubmitted = false;
          this.hasError = true;
          this.openSnackbar(this.errorMessage);
        }
      }),
      (error) => {
        this.isAdding = false;
        this.hasError = true;
      };
  }
  //CREATE BUSINESS ANG CHECK DOJAH
  continueAddbusiness(businessData: any) {
    this.businessService
      .createNewBusiness(businessData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: any) => {
        this.isAdding = false;
        if ((response.status = true && response.data)) {

          //DESACTIVATION DE DOJAH 
          /*
          this.openSnackbar(response['message']);
          localStorage.setItem(
            BUSINESS_DATA_KEY,
            JSON.stringify(response.data)
          );
          const parsedUrl = new URL(window.location.href);
          const baseUrl = parsedUrl.origin;
          this.logout();
          */
          //FIN DE DESACTIVATION 

           
          if (businessData.nationality === 'Nigerian') {  
            this.verification.openWidget()
              .then((response: any) => { 

                this.openSnackbar(response['message']);
                localStorage.setItem(
                  BUSINESS_DATA_KEY,
                  JSON.stringify(response.data)
                );
                const parsedUrl = new URL(window.location.href);
                const baseUrl = parsedUrl.origin; 
                this.logout();
              })
              .catch((err: any) => {
                console.error('Dojah error:', err);
            });  
          } else {
            this.openSnackbar(response['message']);
            localStorage.setItem(
              BUSINESS_DATA_KEY,
              JSON.stringify(response.data)
            );
            const parsedUrl = new URL(window.location.href);
            const baseUrl = parsedUrl.origin; 
            this.logout();
          }
          




        } else {


          this.errorMessage = response.message || 'Something went wrong. Please Make sure you filled both Owner and Business Profile information';
          this.isBusinessSubmitted = false;
          this.hasError = true;
          this.openSnackbar(this.errorMessage);
        }
      }),
      (error) => {
        this.isAdding = false;
        this.hasError = true;
      };
  }






  getBusinessLogo(File) {
    this.businessLogo = File.item(0);
  }

  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right',
    });
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  getLocationData() {
    new Promise((resolve) => {
      this.geoLocationService.getLocation().subscribe((data) => {
        resolve((this.locationData = data));
      });
    })
      .then(() => {
        if (!this.locationData) {
          this.waitingDisplayInput = false;
        } else {
          this.waitingDisplayInput = true;
          this.countryData = {
            preferredCountries: [`${this.locationData}`],
            localizedCountries: { ng: 'Nigeria', gh: 'Ghana' },
            onlyCountries: ['GH', 'NG'],
          };
        }
      })
      .then(() => {
        if (
          this.locationData.country_code === 'GH' ||
          this.locationData.country_code === 'NG'
        ) {
          this.prefixCountryCode = this.locationData.country_calling_code;
          this.isValidCountry = false;
        } else {
          this.isValidCountry = true;
          this.prefixCountryCode = '+233';
        }
      });
  }

  // businessPhoneInputStyl() {
  //   document
  //     .getElementsByTagName("input")[4]
  //     .setAttribute(
  //       "style",
  //       "border-radius:6px; opacity: 1;width: 476px;border-color:blue"
  //     );
  // }
  // deliveryManInputStyl() {
  //   document
  //     .getElementsByTagName("input")[5]
  //     .setAttribute(
  //       "style",
  //       "border-radius:6px; opacity: 1;width: 476px;border-color:blue"
  //     );
  // }

  onSelectIdocument(file: File) {
    this.idDocumentFile = file;
    this.idUploadMessge = this.idDocumentFile['name'];
  }

  onSelectCompanyDocuments(file: File) {
    this.companyDocuments = file;
    this.isCdUploadMessge = this.idDocumentFile['name'];
  }

  // processingData(businessOwnerInformation) {
  //   if (this.isLegallyRegistered === "NO") {
  //     businessOwnerInformation.business_legal_name = "null";
  //     this.companyDocuments = "null";
  //   }

  //   this.isIdUpload = this.businessOwnerInformation[
  //     "identification_documentUpload"
  //   ]
  //     ? false
  //     : true;
  //   this.isCdUpload = this.businessOwnerInformation["company_documentUpload"]
  //     ? false
  //     : true;
  // }

  bsnssRegiStatus(data) {
    if (data.value == 'YES') {
      this.isLegallyRegistered = true;
    } else {
      this.isLegallyRegistered = false;
    }
  }

  selectOption(current: { name: string, value: string }) {
    this.menu.current = current;
  }



  toggleTwoFactorAuth(event: any) {

    this.isTwoFactorAuthEnabled = event.checked;
    if (this.isTwoFactorAuthEnabled === true) {
      this.enableTwoFactorAuth();
    } else {
      this.disableTwoFactorAuth();
    }
  }
  enableTwoFactorAuth() {

    this.authService.enableTwoFactorAuth(this.userData['user_id'], this.credentials)
      .pipe(take(1))
      .subscribe((response: any) => {

        if (response?.status) {
          //
          const newStatusAuth = 1;
          this.sessionData.two_factor_auth = newStatusAuth;
          localStorage.setItem(USER_SESSION_KEY, JSON.stringify(this.sessionData));
        } else {
          //
        }

      })
  }
  disableTwoFactorAuth() {

    this.authService.disableTwoFactorAuth(this.userData['user_id'], this.credentials)
      .pipe(take(1))
      .subscribe((response: any) => {

        if (response?.status) {
          //
          const newStatusAuth = 0;
          this.sessionData.two_factor_auth = newStatusAuth;
          localStorage.setItem(USER_SESSION_KEY, JSON.stringify(this.sessionData));
        } else {
          //
        }

      })
  }


  createBusinessOption(event: any) {
    this.haveABusiness = event.checked;
    this.personnalAccount = !this.personnalAccount
    this.createBusinessAccount = !this.createBusinessAccount

    this.isUploadingCompanyDoc = false // pour require le upload


    this.resetFormValidators()



    this.businessForm.get('country').valueChanges.subscribe(value => {
      this.showOptionalInputByBusinessCountry(value);
      this.requiredFilesUploaded()
    });
    this.updateBusinessForm.get('country').valueChanges.subscribe(value => {
      this.showOptionalInputByBusinessCountry(value);
      this.requiredFilesUploaded()
    });


    this.requiredFilesUploaded()

  }
  cancelCreateBusiness() {
    this.personnalAccount = true
    this.createBusinessAccount = false
    this.isUploadingCompanyDoc = true // pour passer la validation du boutton
    this.resetFormValidators()
  }
  haveBusinessOption() {

    //https://identity.dojah.io?widget_id=667579ea40de420040820b88
  }





}
