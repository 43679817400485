<!-- (ngSubmit)="save()" -->


 

<div *ngIf="userCountry != 'BJ' && userCountry != 'GH' && userCountry != 'CI'">
  <form [formGroup]="topUpForm">

    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
      <h2 class="m-0 headline" fxFlex="auto">{{"RECHARGE YOUR ACCOUNT"| translate}} </h2>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>
    <mat-dialog-content fxLayout="column" class="content-part">
      <div class="grid grid-flow-col grid-cols-1 grid-rows-2 gap-8">


        <div>
          <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
            <div class="label">
              <label for="">{{"Select your account" | translate}}.</label>
            </div>
          </div>
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-select formControlName="currency" (selectionChange)="setCountry($event)">
              <mat-option value="XOF">{{"XOF ACCOUNT"| translate}} </mat-option>
              <mat-option value="XAF">{{"XAF ACCOUNT"| translate}}</mat-option>
              <mat-option value="GHS">{{"GHS ACCOUNT"| translate}}</mat-option>
              <mat-option value="NGN">{{"NGN ACCOUNT"| translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>



        <div>
          <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
            <div class="label"><label for="" class="label">{{"Enter the amount."| translate}} </label></div>
          </div>
          <mat-form-field appearance="outline" style="width: 100%;">
            <!-- <mat-label>Outline form field</mat-label> -->
            <input matInput formControlName="amount" placeholder="" type="number">
            <mat-icon matSuffix>
              <h6>{{ currency }}</h6>
            </mat-icon>
            <mat-error>
              <span *ngFor="
                  let error of topUpForm.get('amount').errors
                    | keyvalue
                ">
                {{ validationMessages.amount[error.key] | translate }}
              </span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="button ">
      <mat-error *ngIf="hasError">
        {{ errorMessage }}
      </mat-error>
      <button mat-button mat-dialog-close type="button" class="cancel-button">{{"CANCEL"| translate}} </button>
      <button color="primary" mat-button type="button" (click)="rechargeAccount()" class="send-button"
        [disabled]="isProcessing || topUpForm.invalid">{{ isProcessing === true? 'Processing...' : 'Request Top UP' |
        translate}}</button>
    </mat-dialog-actions>
  </form>
</div>



<div *ngIf="userCountry == 'BJ' || userCountry == 'GH' || userCountry == 'CI' " style="  border-radius: 4px;">



  <div *ngIf="!mobileMoneyTopUp && !merchantDepositTopUp && !bankDepositTopUp">
    <div class="text-center">
      <strong>{{"Recharge via:"| translate}} </strong>
    </div>
    <hr>
    <div class="inline-block-container" style="cursor: pointer;" (click)="selectmobileMoneyTopUp()">
      <div class="inline-block1">

        <h5><strong>{{"Mobile Money"| translate}} </strong> </h5>
        {{"Automatically fund your PAL account using your linked account"| translate}} mobile money. <br>
        {{"Fee: "+networkfee+" - The fees are deducted by the network."| translate}}
      </div>
      <div class="inline-block2">
        <mat-icon [svgIcon]="'arrow2'" style="position: relative;  left: 50%;" class="menu-item-icon mr-1" matListIcon
          aria-hidden="false"></mat-icon>
      </div>
    </div>



    <hr>
    <div class="inline-block-container" style="cursor: pointer;" (click)="selectmerchantDepositTopUp()">
      <div class="inline-block1">
        <h5> <strong>{{"Merchant Deposit" | translate}} </strong> </h5>
        {{"Go to an MTN super merchant and make a deposit into our" | translate}} <br>
        <ul>
          <li>{{"Merchant Number:"| translate}} <strong>{{merchantNumber}}</strong> </li>
          <li>{{"Name :"| translate}} <strong>{{merchantAccountName}}</strong> </li>
        </ul>
        {{"Free"| translate}}
      </div>
      <div class="inline-block2">
        <mat-icon [svgIcon]="'arrow2'" style="position: relative;  left: 50%;" class="menu-item-icon mr-1" matListIcon
          aria-hidden="false"></mat-icon>
      </div>
    </div>



    <hr>
    <div class="inline-block-container" style="cursor: pointer;" (click)="selectbankDepositTopUp()">
      <div class="inline-block1">
        <h5><strong>{{"Deposit or Bank transfer"| translate}} </strong> </h5>
        {{"Make a deposit or bank transfer to our UBA account."}} <br>
        <ul>
          <li>{{"Bank code:" | translate}} <strong>{{bankCode}} </strong> - {{"Branch code:"|translate}} <strong>
              {{branchCode}} </strong>
          </li>
          <li>{{"Account number:"|translate}} <strong>{{bankAccountNumber}}</strong> - {{"RIB key:"| translate}}
            <strong>{{bankRib}}</strong>
          </li>
          <li>{{"Account Title:"| translate}} <strong>{{bankAccountName}} </strong> </li>
        </ul>
        {{"Free"|translate}}
      </div>
      <div class="inline-block2">
        <mat-icon [svgIcon]="'arrow2'" style="position: relative;  left: 50%;" class="menu-item-icon mr-1" matListIcon
          aria-hidden="false"></mat-icon>
      </div>
    </div>




  </div>

  <div *ngIf="mobileMoneyTopUp">
    <div class="text-center">
      <strong> {{"Mobile Money"| translate}} </strong>
    </div>
    <hr>
    <div>


      <!--<form [formGroup]="mobileMoneytopUpForm">


        <mat-dialog-content fxLayout="column" class="content-part">
          <div class="grid grid-flow-col grid-cols-1 grid-rows-2 gap-8">
            <div>
              <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                <div class="label"><label for="" class="label">{{"Enter the amount."| translate}} </label></div>
              </div>
              <mat-form-field appearance="outline" style="width: 370px;">
                <input matInput formControlName="amount" placeholder="" type="number">
                <mat-icon matSuffix style="width: 70px;">
                  <h3>{{ currency }}</h3>
                </mat-icon>
                <mat-error>
                  <span *ngFor="let error of mobileMoneytopUpForm.get('amount').errors | keyvalue ">
                    {{ validationMessages.amount[error.key] | translate }}
                  </span>
                </mat-error>
              </mat-form-field>
              <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                {{"Network fees:"| translate}} {{(mobileMoneytopUpForm.value.amount*1.25) / 100}} - {{"Net à payer:" |
                translate}}
                {{((mobileMoneytopUpForm.value.amount*1.25) / 100) + mobileMoneytopUpForm.value.amount}}
              </div>
            </div>
            <div>
              <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                <div class="label"><label for="" class="label">{{"Enter phone number."| translate}} </label></div>
              </div>
              <mat-form-field appearance="outline" style="width: 370px;">
                <input matInput formControlName="phone_no" placeholder="" maxlength="8" min="8" type="text">
                <mat-icon matSuffix style="width: 70px;">
                  <h3> MTN</h3>
                </mat-icon>
                <mat-error>
                  <span *ngFor=" let error of mobileMoneytopUpForm.get('phone_no').errors | keyvalue ">
                    {{ validationMessages.phone_no[error.key] | translate }}
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-dialog-content>
        <br> <br>
        <button color="primary" mat-button type="button" (click)="rechargeAccount()"
          [ngClass]="(isProcessing || mobileMoneytopUpForm.invalid || !isPrefixValid)? 'invalid-send-button': 'valid-send-button'"
          [disabled]="isProcessing || mobileMoneytopUpForm.invalid || !isPrefixValid">{{ isProcessing === true?
          'Processing...' :'Approve' | translate}}
        </button>
      </form>-->



      <form [formGroup]="mobileMoneytopUpForm"> 

        <mat-dialog-content fxLayout="column" class="content-part">
          <div class="grid grid-flow-col grid-cols-1 grid-rows-2 gap-8">
            <div>
              <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                <div class="label"><label for="" class="label">{{"Enter the amount."| translate}} </label></div>
              </div>
              <mat-form-field appearance="outline" style="width: 370px;">
                <input matInput formControlName="amount" placeholder="" type="number">
                <mat-icon matSuffix style="width: 70px;">
                  <h3>{{ currency }}</h3>
                </mat-icon>
                <mat-error>
                  <span *ngFor="let error of mobileMoneytopUpForm.get('amount').errors | keyvalue ">
                    {{ validationMessages.amount[error.key] | translate }}
                  </span>
                </mat-error>
              </mat-form-field>
              <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                {{"Network fees:"| translate}} {{(mobileMoneytopUpForm.value.amount*networkfee) / 100}} - {{"Net à payer:" |
                translate}}
                {{((mobileMoneytopUpForm.value.amount*networkfee) / 100) + mobileMoneytopUpForm.value.amount}}
              </div>
            </div>
 
            <!--<div>
              <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                <div class="label"><label for="" class="label">{{"Enter phone number."| translate}} </label></div>
              </div>
              <mat-form-field appearance="outline" style="width: 370px;">
                <input matInput formControlName="phone_no" placeholder="" maxlength="8" min="8" type="text">
                <mat-icon matSuffix style="width: 100px;">
                  <h3> MTN</h3> 
                </mat-icon>
                <mat-error>
                  <span *ngFor=" let error of mobileMoneytopUpForm.get('phone_no').errors | keyvalue ">
                    {{ validationMessages.phone_no[error.key] | translate }}
                  </span>
                </mat-error>
              </mat-form-field> 
            </div>-->

 
            <div>


              <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                <div class="label"><label for="" class="label">{{"Enter phone number."| translate}} </label></div>
              </div>
              <div class="flex">


                <div  style="width: 60%;">
                  <mat-form-field appearance="outline" class="flex-auto w-full">
                    <input matInput formControlName="phone_no" placeholder="" maxlength="8" min="8" type="text">
                    <mat-error>
                      <span *ngFor=" let error of mobileMoneytopUpForm.get('phone_no').errors | keyvalue ">
                        {{ validationMessages.phone_no[error.key] | translate }}
                      </span>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div style="width: 120px;">
                  <mat-form-field appearance="outline" style="width: 150px; height: 100px;">
                    <mat-select placeholder="Select an Operator" formControlName="operator" >
                      <mat-option value=""> Select operator </mat-option>
                      <mat-option value="{{network.code}}" *ngFor="let network of networks"> {{network.name}} </mat-option> 
                    </mat-select>
                  </mat-form-field>
                </div>


              </div>
            </div>












          </div>






        </mat-dialog-content>
        <br> <br>
        <button color="primary" mat-button type="button" (click)="rechargeAccount()"
          [ngClass]="(isProcessing || mobileMoneytopUpForm.invalid || !isPrefixValid)? 'invalid-send-button': 'valid-send-button'"
          [disabled]="isProcessing || mobileMoneytopUpForm.invalid || !isPrefixValid">{{ isProcessing === true?
          'Processing...' :'Approve' | translate}}
        </button>

        <!--<button class="validButton" mat-raised-button type="button"
          [disabled]="isProcessing || mobileMoneytopUpForm.invalid || !isPrefixValid" >
          {{ isProcessing === true?
          'Processing...' :'Approve' | translate}}
        </button>-->


      </form>




    </div>
  </div>






  <div *ngIf="merchantDepositTopUp">
    <div class="text-center">
      <strong> {{"Merchant Deposit"| translate}} </strong>
    </div>
    <hr>
    <div class="mt-5" style="text-align: center; ">
      {{"Please upload the receipt for the deposited amount, thank you."| translate}} <br>
      {{"At the super merchant. Our team will proceed to"| translate}} <br>
      {{"approve your recharge."| translate}}
    </div>
    <div>
      <form [formGroup]="merchantDeposittopUpForm">
        <mat-divider class="-mx-6 text-border"></mat-divider>
        <mat-dialog-content fxLayout="column" class="content-part">
          <div class="grid grid-flow-col grid-cols-1 grid-rows-3 gap-8">


            <div>
              <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                <div class="label"><label for="" class="label">{{"Select Account"| translate}} </label></div>
              </div>
              <select mat-select formControlName="currency" name="" id="" style="width: 400px; height: 40px;">
                <option value="">{{ "Select currency" | translate }}</option>
                <option value="XOF">{{ 'XOF ACCOUNT' | translate }}</option>
                <option value="XAF">{{ 'XAF ACCOUNT' | translate }}</option>
                <option value="GHS">{{ 'GHS ACCOUNT' | translate }}</option>
                <option value="NGN">{{ 'NGN ACCOUNT' | translate }}</option>
              </select>
            </div>




            <div>
              <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                <div class="label"><label for="" class="label">{{"Deposited amount."| translate}} </label></div>
              </div>
              <mat-form-field appearance="outline" style="width: 400px;">
                <input matInput formControlName="amount" placeholder="" type="number">
                <mat-icon matSuffix style="width: 70px;">
                  <h3>{{ merchantDeposittopUpForm.value.currency }}</h3>
                </mat-icon>
                <mat-error>
                  <span *ngFor="
                    let error of merchantDeposittopUpForm.get('amount').errors
                      | keyvalue
                  ">
                    {{ validationMessages.amount[error.key] | translate }}
                  </span>
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start">
              <mat-file-upload [labelText]="''" style="height: 50px; width: 400px; border: solid 1px #ccc;"
                class="text-center" [uploadButtonText]="'upload'" [uploadButtonType]="'button'"
                [showUploadButton]="false" [selectButtonText]="'Upload your proof*'"
                [acceptedTypes]="'.doc, .pdf, .jpg, .jpeg, .png'" (uploadClicked)="uploadID($event)"
                (change)="uploadID($event)">
              </mat-file-upload>
            </div>


          </div>
        </mat-dialog-content>

        <button color="primary" mat-button type="button" (click)="rechargeAccount()"
          [ngClass]="(isProcessing || merchantDeposittopUpForm.invalid || !isUploading)? 'invalid-send-button2' : 'valid-send-button2' "
          [disabled]="isProcessing || merchantDeposittopUpForm.invalid || !isUploading">{{ isProcessing === true?
          'Processing...' :
          'Approve' | translate}}
        </button>


      </form>
    </div>
  </div>


  <div *ngIf="bankDepositTopUp">
    <div class="text-center">
      <strong> {{"Deposit or bank transfer"| translate}} </strong>
    </div>
    <hr>
    <div class="mt-5" style="text-align: center; ">
      {{"Please upload the receipt for the amount."| translate}} <br>
      {{"Deposit made or bank transfer to our account." | translate}} <br>
      {{"Our team will proceed with the approval of your recharge."| translate}}
    </div>
    <div>
      <form [formGroup]="bankDeposittopUpForm">
        <mat-divider class="-mx-6 text-border"></mat-divider>
        <mat-dialog-content fxLayout="column" class="content-part">
          <div class="grid grid-flow-col grid-cols-1 grid-rows-2 gap-8">
            <div>
              <div class="grid grid-flow-col gap-4 grid-cols grid-rows">
                <div class="label"><label for="" class="label">{{"Deposited or transferred amount."| translate}}
                  </label></div>
              </div>
              <mat-form-field appearance="outline" style="width: 400px;">
                <input matInput formControlName="amount" placeholder="" type="number">
                <mat-icon matSuffix style="width: 70px; float: right;">
                  <h3>{{ bankDeposittopUpForm.value.currency }}</h3>
                </mat-icon>
                <mat-error>
                  <span *ngFor="
                    let error of bankDeposittopUpForm.get('amount').errors
                      | keyvalue
                  ">
                    {{ validationMessages.amount[error.key] | translate }}
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <div fxLayout="row" fxLayoutAlign="start">
                <mat-file-upload [labelText]="''" style="height: 50px; width: 400px; border: solid 1px #ccc;"
                  class="text-center" [uploadButtonText]="'upload'" [uploadButtonType]="'button'"
                  [showUploadButton]="false" [selectButtonText]="'Upload your proof*'"
                  [acceptedTypes]="'.doc, .pdf, .jpg, .jpeg, .png'" (uploadClicked)="uploadID($event)"
                  (change)="uploadID($event)">
                </mat-file-upload>
              </div>
            </div>
          </div>
        </mat-dialog-content>
        <button color="primary" mat-button type="button" (click)="rechargeAccount()"
          [ngClass]="(isProcessing || bankDeposittopUpForm.invalid || !isUploading)? 'invalid-send-button2': 'valid-send-button2'"
          [disabled]="isProcessing || bankDeposittopUpForm.invalid || !isUploading">{{ isProcessing === true?
          'Processing...' :
          'Approve' | translate}}
        </button>


      </form>
    </div>
  </div>







</div>