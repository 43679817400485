<div class="overflow-scroll">


    <mat-toolbar class="">
        <div class="primary-btns">
            <button mat-button *ngFor="let option of this.menu.options"
                color="{{option.value==this.menu.current.value?'primary':''}}"
                (click)="selectOption(option)">{{option.name | translate}}</button>
        </div>
        <div class="secondary-btns">
            <mat-spinner *ngIf="!!businessForm === false" color="primary">
            </mat-spinner>
        </div>
    </mat-toolbar>





    <mat-divider class="-mx-6 text-border toolbar-mat-divider"></mat-divider>
    <div [@stagger]="true" class="p-gutter" vexContainer>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
            <div @fadeInUp class="card" fxFlex="auto">



                <div class="top" *ngIf="this.menu.current.value=='all_transfers'">
                    <div class="profilePhotoContainer" *ngIf="companyLogoFile && companyLogoUrl"> 
                        <img *ngIf="companyLogoUrl" class="rounded-full h-9 w-9 " src="{{companyLogoUrl}}" alt="" >
                    </div>
                </div>


                <div *ngIf="this.menu.current.value=='all_transfers'">
                    <div [formGroup]="businessForm" *ngIf="!userData.hasBusiness && !!businessData === false">
                        <div class="top">
                            <div>
                                <mat-file-upload [labelText]="''" [uploadButtonText]="'upload'"
                                    [showUploadButton]="false" [selectButtonText]="'Upload Company Logo'"
                                    [acceptedTypes]="'.png, .jpg, .jpeg'" (uploadClicked)="uploadCompanyLogo($event)"
                                    (change)="uploadCompanyLogo($event)">
                                </mat-file-upload>
                            </div>
                        </div>
                        <div class="px-6 py-4" fxLayout="column">
                            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                                <mat-form-field fxFlex="auto">
                                    <mat-label>{{"Company legal name"| translate}} </mat-label>
                                    <input matInput #input formControlName="business_legal_name">
                                </mat-form-field>
                                <mat-form-field fxFlex="auto">
                                    <mat-label>{{"Website" | translate}} </mat-label>
                                    <input matInput #input formControlName="website">
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="66px" fxLayoutGap.lt-sm="0">
                                <mat-form-field>
                                    <mat-label class="text-xs text-gray-300"> {{"Country" | translate}} </mat-label>
                                    <mat-select formControlName="country">
                                        <mat-option value="" disabled>Select</mat-option>
                                        <mat-option *ngFor="let country of countries" [value]="country.code">{{
                                            country.name }}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        <span *ngFor="
                        let error of businessForm.get('country').errors | keyvalue
                      ">
                                            {{ validationMessages.country[error.key] }}
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>{{"Staff Size"| translate}} </mat-label>
                                    <mat-select formControlName="staff_size">
                                        <mat-option value="1-10">{{"1-10 people" | translate}} </mat-option>
                                        <mat-option value="10-50">{{"10-50 people" | translate}} </mat-option>
                                        <mat-option value="50+">{{"+50 people" | translate}} </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field fxFlex="auto">
                                    <mat-label>{{"Describe what your company does" | translate}} </mat-label>
                                    <input matInput #input formControlName="description">
                                    <mat-error>
                                        <span *ngFor="
                        let error of businessForm.get('description').errors
                          | keyvalue
                      ">
                                            {{ validationMessages.description[error.key] | translate}}
                                        </span>
                                    </mat-error>
                                </mat-form-field>

                            </div>
                            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                                <mat-form-field>
                                    <mat-label class="text-xs text-gray-300">{{"Industry" | translate}} </mat-label>
                                    <mat-select formControlName="industry">
                                        <mat-option value="" disabled>Select</mat-option>
                                        <mat-option *ngFor="let industry of industries" [value]="industry">{{ industry
                                            }}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        <span *ngFor="
                        let error of businessForm.get('industry').errors | keyvalue
                      ">
                                            {{ validationMessages.industry[error.key]|translate }}
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field fxFlex="auto">
                                    <mat-label>{{"Business phone"| translate}} </mat-label>
                                    <input matInput required formControlName="business_phone">
                                    <mat-error>
                                        <span *ngFor="
                        let error of businessForm.get('business_phone').errors
                          | keyvalue
                      ">
                                            {{ validationMessages.business_phone[error.key] | translate }}
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field fxFlex="auto">
                                    <mat-label>{{"Company physical address"| translate}} </mat-label>
                                    <input matInput required formControlName="business_address">
                                    <mat-error>
                                        <span *ngFor="
                        let error of businessForm.get('business_address').errors
                          | keyvalue
                      ">
                                            {{ validationMessages.business_address[error.key] | translate }}
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row">
                                <mat-label class="upload">{{"Upload your company registration documents (Multiple filesselection allowed)" | translate}}
                                </mat-label>
                            </div>


                            <!--<div fxLayout="row" fxLayoutAlign="start" *ngIf="showCompanyDocument"> 
                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                <mat-file-upload [allowMultipleFiles]="true" [labelText]="''"
                                    [uploadButtonText]="'upload'" [showUploadButton]="false"
                                    [selectButtonText]="'Upload Company documentpppppppppppp'"
                                    (uploadClicked)="uploadCompanyDoc($event, 'CompanyDocument')"
                                    (selectedFilesChanged)="uploadCompanyDoc($event, 'CompanyDocument')">
                                </mat-file-upload>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start" *ngIf="showCertificateOfIncorporation">
                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon> 
                                <mat-file-upload [allowMultipleFiles]="true" [labelText]="''"
                                    [uploadButtonText]="'upload'" [showUploadButton]="false"
                                    [selectButtonText]="'Upload Certificate of Incorporation'"
                                    (uploadClicked)="uploadCompanyDoc($event, 'CertificateOfIncorporation')"
                                    (selectedFilesChanged)="uploadCompanyDoc($event, 'CertificateOfIncorporation')">
                                </mat-file-upload>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start" *ngIf="showFiscalDeclaration"> 
                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                <mat-file-upload [allowMultipleFiles]="true" [labelText]="''"
                                    [uploadButtonText]="'upload'" [showUploadButton]="false"
                                    [selectButtonText]="'Upload Fiscal Declaration'"
                                    (uploadClicked)="uploadCompanyDoc($event, 'FiscalDeclaration')"
                                    (selectedFilesChanged)="uploadCompanyDoc($event, 'FiscalDeclaration')">
                                </mat-file-upload>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start" *ngIf="showRccm"> 
                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                <mat-file-upload [allowMultipleFiles]="true" [labelText]="''"
                                    [uploadButtonText]="'upload'" [showUploadButton]="false"
                                    [selectButtonText]="'Upload RCCM'"
                                    (uploadClicked)="uploadCompanyDoc($event, 'Rccm')"
                                    (selectedFilesChanged)="uploadCompanyDoc($event, 'Rccm')">
                                </mat-file-upload>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start" *ngIf="showNinea"> 
                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                <mat-file-upload [allowMultipleFiles]="true" [labelText]="''"
                                    [uploadButtonText]="'upload'" [showUploadButton]="false"
                                    [selectButtonText]="'Upload Ninea'"
                                    (uploadClicked)="uploadCompanyDoc($event, 'Ninea')"
                                    (selectedFilesChanged)="uploadCompanyDoc($event, 'Ninea')">
                                </mat-file-upload>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start" *ngIf="showIfu"> 
                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                <mat-file-upload [allowMultipleFiles]="true" [labelText]="''"
                                    [uploadButtonText]="'upload'" [showUploadButton]="false"
                                    [selectButtonText]="'Upload Ifu'"
                                    (uploadClicked)="uploadCompanyDoc($event, 'Ifu')"
                                    (selectedFilesChanged)="uploadCompanyDoc($event, 'Ifu')">
                                </mat-file-upload>
                            </div>-->



                            <div class="px-6 py-4 owner" fxLayout="column">
                                <div class="px-6 py-4 border-b" fxLayout="row" fxLayoutAlign="start center">
                                    <h2 class="m-0 title">{{"Owner"| translate}} </h2>
                                </div>
                                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                                    <mat-form-field fxFlex="auto">
                                        <mat-label>{{"Your Full name"| translate}} </mat-label>
                                        <input matInput #input formControlName="owner_full_name">
                                        <mat-error>
                                            <span *ngFor="
                          let error of businessForm.get('owner_full_name').errors
                            | keyvalue
                        ">
                                                {{ validationMessages.owner_full_name[error.key] | translate }}
                                            </span>
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="auto">
                                        <mat-label>{{"Your address" | translate}} </mat-label>
                                        <input matInput required formControlName="owner_address">
                                        <mat-error>
                                            <span *ngFor="
                          let error of businessForm.get('owner_address').errors
                            | keyvalue
                        ">
                                                {{ validationMessages.owner_address[error.key] | translate }}
                                            </span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                                    <mat-form-field>
                                        <mat-label>{{"ID type" | translate}} </mat-label>
                                        <mat-select formControlName="id_type">
                                            <mat-option value="Driver Licence">
                                                {{"Driver licence"| translate}}
                                            </mat-option>
                                            <mat-option value="Passport">Passport</mat-option>
                                            <mat-option value="National ID card">
                                                {{"National ID card"| translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field fxFlex="auto">
                                        <mat-label>{{"Dirth of birth" | translate}} </mat-label>
                                        <input type="date" matInput required #input formControlName="dob">
                                    </mat-form-field>
                                    <mat-form-field fxFlex="auto">
                                        <mat-label class="text-xs text-gray-300">{{"Nationnality"| translate}}
                                        </mat-label>
                                        <mat-select formControlName="nationality">
                                            <mat-option value="" disabled>{{"Select" | translate}} </mat-option>
                                            <mat-option *ngFor="let nationality of nationalities"
                                                [value]="nationality">{{ nationality }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>
                                            <span *ngFor="
                          let error of businessForm.get('nationality').errors
                            | keyvalue
                        ">
                                                {{ validationMessages.nationality[error.key] | translate }}
                                            </span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row">
                                    <mat-label class="upload">{{"Upload your ID document" | translate}} </mat-label>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start">
                                    <!-- <angular-file-uploader [config]="afuConfig">
                                </angular-file-uploader> -->
                                    <mat-file-upload [labelText]="''" [uploadButtonText]="'upload'"
                                        [uploadButtonType]="'button'" [showUploadButton]="false"
                                        [selectButtonText]="'Upload your ID *'"
                                        [acceptedTypes]="'.doc, .pdf, .jpg, .jpeg, .png'"
                                        (uploadClicked)="uploadID($event)" (change)="uploadID($event)">
                                    </mat-file-upload>
                                    <mat-error *ngIf="!this.idDocumentFile">
                                        {{"Please upload your ID" | translate}}
                                    </mat-error>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
                                    <button color="primary" mat-raised-button type="button"
                                        [disabled]="isAdding || businessForm.invalid" (click)="addBusiness()">
                                        {{ isAdding === true ? 'SUBMITTING...' : 'SUBMIT'| translate }}
                                    </button> 
                                    {{ errorMessage }}
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <ng-container> 
                    <div [formGroup]="businessForm" *ngIf="!userData.hasBusiness || !!businessData === false">
                        <div class="py-4" fxLayout="column">


                            <!--PROFILE-->
                            <div *ngIf="this.menu.current.value=='profile'" class="custom-container">

                                <!--PERSONNAL PROFILE-->
                                <div class="custom-column">

                                    <div fxLayoutAlign="center" class="border px-5 mx-5 mt-5">
                                        <div class="w-full">
                                            <div fxLayout="row" fxLayoutAlign="between center" class="bloc-header my-3">
                                                <h3 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 rtl:border-l"
                                                    fxFlex="none">
                                                    {{"Owner informations" | translate}} 
                                                </h3>
                                            </div>
                                            <mat-divider class="-mx-12 text-border"></mat-divider>
                                            <div class="h-3"></div>
                                            <div class="input-row">
                                                <div class="customInput">
                                                    <span>{{"Full name"| translate}} </span>
                                                    <mat-form-field>
                                                        <input matInput class="bg-white" #input
                                                            placeholder="{{'Enter your full name'| translate}}"
                                                            formControlName="owner_full_name">
                                                        <mat-error>
                                                            <span *ngFor="
                                let error of businessForm.get('owner_full_name').errors
                                    | keyvalue
                                ">
                                                                {{ validationMessages.owner_full_name[error.key] |
                                                                translate }}
                                                            </span>
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>

                                            <div class="input-row">
                                                <div class="customInput mr-1">
                                                    <span class="">{{"ID type"| translate}} </span>
                                                    <mat-form-field>
                                                        <mat-select readOnly formControlName="id_type">
                                                            <mat-option value="Driver Licence">
                                                                {{"Driver licence" | translate}}
                                                            </mat-option>
                                                            <mat-option value="Passport">Passport</mat-option>
                                                            <mat-option value="National ID card">
                                                                {{"National ID card"| translate}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                                <div class="customInput  ml-1">
                                                    <span>{{"Dirth of birth"| translate}} </span>
                                                    <mat-form-field>
                                                        <input type="date" matInput required #input
                                                            formControlName="dob" style="height: 1rem;">
                                                    </mat-form-field>
                                                </div>
                                            </div>

                                            
                                            <div class="input-row">
                                                <div class="customInput">
                                                    <span>{{"Nationnality" | translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <mat-select formControlName="nationality">
                                                            <mat-option value="" disabled>Select</mat-option>
                                                            <mat-option *ngFor="let nationality of nationalities"
                                                                [value]="nationality">{{ nationality }}
                                                            </mat-option>
                                                        </mat-select>
                                                        <mat-error>
                                                            <span *ngFor="
                                                let error of businessForm.get('nationality').errors
                                                    | keyvalue
                                                ">
                                                                {{ validationMessages.nationality[error.key] | translate
                                                                }}
                                                            </span>
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="input-row">
                                                <div class="customInput">
                                                    <span>{{"Residential address" | translate}} </span>
                                                    <mat-form-field>
                                                        <input matInput placeholder="House location address" required
                                                            formControlName="owner_address">
                                                        <mat-error>
                                                            <span *ngFor="
                                  let error of businessForm.get('owner_address').errors
                                    | keyvalue
                                ">
                                                                {{ validationMessages.owner_address[error.key] |
                                                                translate }}
                                                            </span>
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div fxLayout="row" fxLayoutAlign="start" *ngIf="showPersonnalID">
                                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                                <mat-file-upload [labelText]="''" [uploadButtonText]="'upload'"
                                                    [uploadButtonType]="'button'" [showUploadButton]="false"
                                                    [selectButtonText]="'Upload your ID *'"
                                                    [acceptedTypes]="'.doc, .pdf, .jpg, .jpeg, .png'"
                                                    (uploadClicked)="uploadID($event)" (change)="uploadID($event)">
                                                </mat-file-upload>
                                                <mat-error *ngIf="!this.idDocumentFile">
                                                    {{"Please upload your ID" | translate}}
                                                </mat-error>
                                            </div>

                                        </div>
                                    </div><br><br>

                                    <!--SELCTION BUSINESS ACCOUNT OPTION-->
                                    <div fxLayout="row" fxLayoutAlign="start center" class=" px-5">
                                        <h5>{{"Have you a business ?"| translate}}</h5>
                                        <mat-slide-toggle class="ml-1" color="primary" [checked]="haveABusiness"
                                            (change)="createBusinessOption($event)"></mat-slide-toggle>
                                    </div>

                                </div><br><br>


                                

                                <!--BUSINESS PROFILE -->
                                <div class="custom-column" *ngIf="!personnalAccount">

                                    <div fxLayoutAlign="center" class="border px-5 mx-5 mt-5">

                                        <div *ngIf="this.menu.current.value=='profile'" fxLayout="column"
                                            class="w-full">
                                            <div fxLayout="row" fxLayoutAlign="between center" class="bloc-header my-3">
                                                <h3 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 rtl:border-l"
                                                    fxFlex="none">
                                                    {{"Business Profile" | translate}}
                                                </h3>
                                            </div>
                                            <mat-divider class="-mx-12 text-border"></mat-divider>
                                            <div class="h-3"></div>
                                            <div fxLayout="row" fxLayoutAlign="start">
                                                <div class="proFilPhoto mb-5" style="background-color: red;">
                                                    <mat-icon *ngIf="!companyLogoUrl" [icIcon]="icPerson"></mat-icon> 
                                                        <img *ngIf="companyLogoUrl" class="rounded-full h-9 w-9 " src="{{companyLogoUrl}}" alt="" >
                                                </div>
                                                <div>
                                                    <mat-file-upload [labelText]="''" [uploadButtonText]="'upload'"
                                                        [showUploadButton]="false" [selectButtonText]="'Logo'"
                                                        [acceptedTypes]="'.png, .jpg, .jpeg'"
                                                        (uploadClicked)="uploadCompanyLogo($event)"
                                                        (change)="uploadCompanyLogo($event)">
                                                    </mat-file-upload>
                                                    <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>

                                                </div>
                                            </div>
                                            <div class="input-row">
                                                <div class="customInput">
                                                    <span>{{"Trade name" | translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <input matInput maxlength="14" #input
                                                            formControlName="business_legal_name">
                                                        <mat-error>
                                                            <span
                                                                *ngFor="
                                                                let error of businessForm.get('business_legal_name').errors | keyvalue  ">
                                                                {{ validationMessages.business_legal_name[error.key] }}
                                                            </span>
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="input-row">
                                                <div class="customInput">
                                                    <span> {{"Company legal name" | translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <input matInput #input formControlName="trading_name">
                                                        <mat-error>
                                                            <span
                                                                *ngFor="
                                                                let error of businessForm.get('trading_name').errors | keyvalue  ">
                                                                {{ validationMessages.trading_name[error.key] }}
                                                            </span>
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>


                                            <div class="input-row">
                                                <div class="customInput mr-1">
                                                    <span>{{"Country" | translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <mat-select formControlName="country">
                                                            <mat-option value="" disabled>Select</mat-option>
                                                            <mat-option *ngFor="let country of countries"
                                                                [value]="country.code">{{
                                                                country.name }}
                                                            </mat-option>

                                                        </mat-select>
                                                        <mat-error>
                                                            <span
                                                                *ngFor="
                                                                let error of businessForm.get('country').errors | keyvalue  ">
                                                                {{ validationMessages.country[error.key] | translate}}
                                                            </span>
                                                        </mat-error>

                                                    </mat-form-field>
                                                </div>
                                                <div class="customInput ml-1">
                                                    <span>{{"Staff Size" | translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <mat-select formControlName="staff_size">
                                                            <mat-option value="1-10">{{"1-10 people"| translate}}
                                                            </mat-option>
                                                            <mat-option value="10-50">{{"10-50 people"| translate}}
                                                            </mat-option>
                                                            <mat-option value="50+">{{"+50 people" | translate}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <mat-error>
                                                            <span
                                                                *ngFor=" let error of businessForm.get('staff_size').errors | keyvalue ">
                                                                {{ validationMessages.staff_size[error.key] |
                                                                translate}}
                                                            </span>
                                                        </mat-error>
                                                    </mat-form-field>

                                                </div>
                                            </div>
                                            <div class="input-row">
                                                <div class="customInput">
                                                    <span>{{"Industry"| translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <mat-select formControlName="industry">
                                                            <mat-option value="" disabled>{{"Select" | translate}}
                                                            </mat-option>
                                                            <mat-option *ngFor="let industry of industries"
                                                                [value]="industry">{{
                                                                industry }}</mat-option>
                                                        </mat-select>
                                                        <mat-error>
                                                            <span
                                                                *ngFor=" let error of businessForm.get('industry').errors | keyvalue  ">
                                                                {{ validationMessages.industry[error.key] | translate }}
                                                            </span>
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="input-row">
                                                <div class="customInput mr-1">
                                                    <span>{{"Website" | translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <input matInput #input formControlName="website">

                                                    </mat-form-field>
                                                </div>
                                                <div class="customInput ml-1">
                                                    <span>{{"Business phone"| translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <input matInput required formControlName="business_phone">

                                                        <mat-error>
                                                            <span *ngFor="
                                let error of businessForm.get('business_phone').errors
                                  | keyvalue
                              ">
                                                                {{ validationMessages.business_phone[error.key] |
                                                                translate }}
                                                            </span>
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="input-row">
                                                <div class="customInput">
                                                    <span> {{"Describe what your company does"| translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <input matInput #input formControlName="description">
                                                        <mat-error>
                                                            <span
                                                                *ngFor="  let error of businessForm.get('description').errors | keyvalue  ">
                                                                {{ validationMessages.description[error.key] | translate
                                                                }}
                                                            </span>
                                                        </mat-error>

                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="input-row">
                                                <div class="customInput">
                                                    <span>{{"Company physical address" | translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <input matInput required formControlName="business_address">
                                                        <mat-error>
                                                            <span
                                                                *ngFor="  let error of businessForm.get('business_address').errors | keyvalue  ">
                                                                {{ validationMessages.business_address[error.key] |
                                                                translate }}
                                                            </span>
                                                        </mat-error>

                                                    </mat-form-field>
                                                </div>
                                            </div> 

                                            
                                           


                                            
                                            <div fxLayout="row" *ngIf="showCompanyDocument">
                                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                                <mat-file-upload class="" [labelText]="''" [uploadButtonText]="'upload'"
                                                    [showUploadButton]="false"
                                                    [selectButtonText]="'Upload Company documents'"
                                                    [acceptedTypes]="'.doc, .pdf'"
                                                    (uploadClicked)="uploadCompanyDoc($event)"
                                                    (change)="uploadCompanyDoc($event)"> 
                                                </mat-file-upload>
                                            </div>


                                            <div fxLayout="row" *ngIf="showCertificateOfIncorporation">
                                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                                <mat-file-upload class="" [labelText]="''" [uploadButtonText]="'upload'"
                                                    [showUploadButton]="false"
                                                    [selectButtonText]="'Upload Certificate of Incorporation'"
                                                    [acceptedTypes]="'.doc, .pdf'"
                                                    (uploadClicked)="uploadCompanyDocCertificateOfIncorporation($event)"
                                                    (change)="uploadCompanyDocCertificateOfIncorporation($event)">
                                                </mat-file-upload>
                                            </div>
 
                                            <div fxLayout="row" fxLayoutAlign="start" *ngIf="showFiscalDeclaration"> 
                                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                                <mat-file-upload class=""  [labelText]="''" [uploadButtonText]="'upload'" 
                                                    [showUploadButton]="false"
                                                    [selectButtonText]="'Upload Fiscal Declaration'"
                                                    [acceptedTypes]="'.doc, .pdf'"
                                                    (uploadClicked)="uploadCompanyDocFiscalDeclaration($event)"
                                                    (change)="uploadCompanyDocFiscalDeclaration($event)">
                                                </mat-file-upload>
                                            </div>
                                            <div fxLayout="row" fxLayoutAlign="start" *ngIf="showRccm"> 
                                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                                <mat-file-upload class=""  [labelText]="''" [uploadButtonText]="'upload'" 
                                                    [showUploadButton]="false"
                                                    [selectButtonText]="'Upload RCCM'"
                                                    [acceptedTypes]="'.doc, .pdf'"
                                                    (uploadClicked)="uploadCompanyDocRccm($event)"
                                                    (change)="uploadCompanyDocRccm($event)">
                                                </mat-file-upload>
                                            </div>
                                            <div fxLayout="row" fxLayoutAlign="start" *ngIf="showNinea"> 
                                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                                <mat-file-upload class="" [labelText]="''" [uploadButtonText]="'upload'" 
                                                    [showUploadButton]="false"
                                                    [selectButtonText]="'Upload Ninea'"
                                                    [acceptedTypes]="'.doc, .pdf'"
                                                    (uploadClicked)="uploadCompanyDocNinea($event)"
                                                    (change)="uploadCompanyDocNinea($event)">
                                                </mat-file-upload>
                                            </div>
                                            <div fxLayout="row" fxLayoutAlign="start" *ngIf="showIfu"> 
                                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                                <mat-file-upload class=""  [labelText]="''" [uploadButtonText]="'upload'" 
                                                    [showUploadButton]="false"
                                                    [selectButtonText]="'Upload Ifu'"
                                                    [acceptedTypes]="'.doc, .pdf'"
                                                    (uploadClicked)="uploadCompanyDocIfu($event)"
                                                    (change)="uploadCompanyDocIfu($event)">
                                                </mat-file-upload>
                                            </div>
                                            
                                             
                                            
                                        </div>

                                    </div>

                                </div>


 

                            </div> 
                            <br>
                            
                            <!--BUSINESS VALID : {{businessForm.valid}} <br>
                            REQUIRED FILE UPLOAD :  {{requiredFilesUpload }} <br> <br>
                            {{businessForm.value | json}}-->

                            <div *ngIf="this.menu.current.value=='profile'" class="mt-5 my-5 mx-5 px-5">
                                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
                                    <!--!isUploading ||   !isUploadingCompanyDoc      -->
                                    <button class="confirm-btn" mat-flat-button type="button"
                                        [disabled]="businessForm.invalid || requiredFilesUpload == false"
                                        (click)="addBusiness()">
                                        {{ isAdding === true ? 'SAVING...' : 'SAVE' | translate }}
                                    </button>
                                </div>
                            </div>


                            <!--SECURITY-->
                            <div *ngIf="this.menu.current.value=='security'" style="margin: 25px;">

                                <vex-change-password></vex-change-password>
                                <div>
                                    <div fxLayout="row" fxLayoutAlign="between center" class="bloc-header my-3">
                                        <h3 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 rtl:border-l"
                                            fxFlex="none" fxHide.xs>
                                            {{"2F Authentification Security" | translate}}
                                        </h3>
                                    </div>
                                    <mat-divider class="-mx-12 text-border"></mat-divider>

                                    <div fxLayout="row" fxLayoutAlign="start center" class="">
                                        {{"Enable 2F Authentification"| translate}}
                                        <mat-slide-toggle class="ml-1" color="primary"
                                            [checked]="isTwoFactorAuthEnabled"
                                            (change)="toggleTwoFactorAuth($event)"></mat-slide-toggle>
                                    </div>

                                </div>
                            </div>


                            <div *ngIf="this.menu.current.value=='collaborators_team'" fxLayout="column">
                                <vex-collaborators-team>
                                </vex-collaborators-team>
                            </div>


                        </div>
                    </div>
                </ng-container>








 









                <!--HAVE ALREADY BUSINESS-->
                <ng-container>

                    <div [formGroup]="updateBusinessForm" *ngIf="userData.hasBusiness || !!businessData === true">
                        <div class="py-4" fxLayout="column">



                            <div *ngIf="this.menu.current.value=='profile'" class="custom-container">



                                <!--PERSONNAL INFORMATIONS-->
                                <div fxLayoutAlign="center" class="border px-5 mt-5">
                                    <div class="w-full">
                                        <div fxLayout="row" fxLayoutAlign="between center" class="bloc-header my-3">
                                            <h3 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 rtl:border-l"
                                                fxFlex="none">
                                                {{"Owner informations"| translate}} 
                                            </h3>
                                        </div>
                                        <mat-divider class="-mx-12 text-border"></mat-divider>
                                        <div class="h-3 mb-5"></div>
                                        <div class="input-row mt-5 h-100">
                                            <div class="customInput mt-5">
                                                <span>{{"Full name"| translate}} </span>
                                                <mat-form-field>
                                                    <input matInput class="bg-white" #input
                                                        placeholder="{{'Enter your full name'| translate}}"
                                                        formControlName="owner_full_name">
                                                    <mat-error>
                                                        <span *ngFor="
                                    let error of businessForm.get('owner_full_name').errors
                                        | keyvalue
                                    ">
                                                            {{ validationMessages.owner_full_name[error.key] |
                                                            translate }}
                                                        </span>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="input-row">
                                            <div class="customInput mr-1">
                                                <span class="">{{"ID type"| translate}} </span>
                                                <mat-form-field>
                                                    <mat-select readOnly formControlName="id_type">
                                                        <mat-option value="Driver Licence">
                                                            {{"Driver licence"| translate}}
                                                        </mat-option>
                                                        <mat-option value="Passport">Passport</mat-option>
                                                        <mat-option value="National ID card">
                                                            {{"National ID card" | translate}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="customInput  ml-1">
                                                <span>{{"Dirth of birth"| translate}} </span>
                                                <mat-form-field>
                                                    <input type="date" matInput required #input formControlName="dob"
                                                        style="height: 1rem;">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="input-row">
                                            <div class="customInput">
                                                <span>{{"Nationnality"| translate}} </span>
                                                <mat-form-field fxFlex="auto">
                                                    <mat-select formControlName="nationality">
                                                        <mat-option value="" disabled>Select</mat-option>
                                                        <mat-option *ngFor="let nationality of nationalities"
                                                            [value]="nationality">{{ nationality }}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error>
                                                        <span *ngFor="
                                                    let error of businessForm.get('nationality').errors
                                                        | keyvalue
                                                    ">
                                                            {{ validationMessages.nationality[error.key] | translate
                                                            }}
                                                        </span>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="input-row">
                                            <div class="customInput">
                                                <span>{{"Residential address"| translate}} </span>
                                                <mat-form-field>
                                                    <input matInput placeholder="House location address" required
                                                        formControlName="owner_address">
                                                    <mat-error>
                                                        <span *ngFor="
                                      let error of businessForm.get('owner_address').errors
                                        | keyvalue
                                    ">
                                                            {{ validationMessages.owner_address[error.key] |
                                                            translate }}
                                                        </span>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="start" *ngIf="showPersonnalID">
                                            <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                            <mat-file-upload [labelText]="''" [uploadButtonText]="'upload'"
                                                [uploadButtonType]="'button'" [showUploadButton]="false"
                                                [selectButtonText]="'Upload your ID *'"
                                                [acceptedTypes]="'.doc, .pdf, .jpg, .jpeg, .png'"
                                                (uploadClicked)="uploadID($event)" (change)="uploadID($event)">
                                            </mat-file-upload>
                                        </div>
                                    </div>
                                </div>
                                <br> <br>
                                
                                <!--SELCTION BUSINESS ACCOUNT OPTION-->
                                <div fxLayout="row" fxLayoutAlign="start center" class=" px-5" style="margin-left:10px"
                                    *ngIf="!alreadyHasBusiness">
                                    <h5>{{"Have you a business ?"| translate}}</h5>
                                    <mat-slide-toggle class="ml-1" color="primary" [checked]="haveABusiness"
                                        (change)="createBusinessOption($event)"></mat-slide-toggle>
                                </div>
                                <br><br>

                                <!--BUSINESS ACCOUNT-->
                                <!-- class="custom-column"-->
                                <div *ngIf="!personnalAccount">

                                    <div fxLayoutAlign="center" class="border px-5 ">
                                        <div fxLayout="column" class="w-full">


                                            <div fxLayout="row" fxLayoutAlign="between center" class="bloc-header my-3">
                                                <h3 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 rtl:border-l"
                                                    fxFlex="none">
                                                    {{"Business Profile"| translate}}
                                                </h3>
                                            </div>
                                            <mat-divider class="-mx-12 text-border"></mat-divider>
                                            <div class="h-3"></div>
                                            <div fxLayout="row" fxLayoutAlign="start">
                                                <div class="proFilPhoto mb-5">
                                                    <mat-icon *ngIf="!companyLogoUrl" [icIcon]="icPerson"></mat-icon>
                                                    <img *ngIf="companyLogoUrl" class="rounded-full h-9 w-9 " src="{{companyLogoUrl}}" alt="" >
                                                </div>
                                                <div>
                                                    <mat-file-upload [labelText]="''" [uploadButtonText]="'upload'"
                                                        [showUploadButton]="false" [selectButtonText]="'Logo'"
                                                        [acceptedTypes]="'.png, .jpg, .jpeg'"
                                                        (uploadClicked)="updateCompanyLogo($event)"
                                                        (change)="updateCompanyLogo($event)">
                                                    </mat-file-upload>
                                                    <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>

                                                </div>
                                            </div>

                                            <div class="input-row">
                                                <div class="customInput">
                                                    <span>{{"Trade name"| translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <input matInput maxlength="14" #input
                                                            formControlName="business_legal_name">
                                                    </mat-form-field>
                                                    <mat-error>
                                                        <span
                                                            *ngFor="
                                                            let error of updateBusinessForm.get('business_legal_name').errors | keyvalue  ">
                                                            {{ validationMessages.business_legal_name[error.key] }}
                                                        </span>
                                                    </mat-error>
                                                </div>
                                            </div>
                                            <div class="input-row">
                                                <div class="customInput">
                                                    <span>{{"Company legal name"| translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <input matInput #input formControlName="trading_name">
                                                    </mat-form-field>
                                                    <mat-error>
                                                        <span
                                                            *ngFor="
                                                            let error of updateBusinessForm.get('trading_name').errors | keyvalue  ">
                                                            {{ validationMessages.trading_name[error.key] }}
                                                        </span>
                                                    </mat-error>
                                                </div>
                                            </div>

                                            <div class="input-row">
                                                <div class="customInput mr-1">
                                                    <span>{{"Country"| translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <mat-select formControlName="country">
                                                            <mat-option value="" disabled>Select</mat-option>
                                                            <mat-option *ngFor="let country of countries"
                                                                [value]="country.code">{{
                                                                country.name }}</mat-option>
                                                        </mat-select>

                                                        <mat-error>
                                                            <span
                                                                *ngFor=" let error of businessForm.get('country').errors | keyvalue ">
                                                                {{ validationMessages.country[error.key] }}
                                                            </span>
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="customInput ml-1">
                                                    <span>{{"Staff Size"| translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <mat-select formControlName="staff_size">
                                                            <mat-option value="1-10">{{"1-10 people" | translate}}
                                                            </mat-option>
                                                            <mat-option value="10-50">{{"10-50 people" | translate}}
                                                            </mat-option>
                                                            <mat-option value="50+">{{"+50 people" | translate}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="input-row">
                                                <div class="customInput">
                                                    <span>{{"Industry"| translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <mat-select formControlName="industry">
                                                            <mat-option value="" disabled>Select</mat-option>
                                                            <mat-option *ngFor="let industry of industries"
                                                                [value]="industry">{{
                                                                industry }}</mat-option>
                                                        </mat-select>

                                                        <mat-error>
                                                            <span *ngFor="
                                        let error of businessForm.get('industry').errors | keyvalue
                                      ">
                                                                {{ validationMessages.industry[error.key] | translate }}
                                                            </span>
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="input-row">
                                                <div class="customInput mr-1">
                                                    <span>{{"Website"| translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <input matInput #input formControlName="website">

                                                    </mat-form-field>
                                                </div>
                                                <div class="customInput ml-1">
                                                    <span>{{"Business phone"| translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <input matInput required formControlName="business_phone">

                                                        <mat-error>
                                                            <span *ngFor="
                                        let error of businessForm.get('business_phone').errors
                                          | keyvalue
                                      ">
                                                                {{ validationMessages.business_phone[error.key] |
                                                                translate }}
                                                            </span>
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="input-row">
                                                <div class="customInput">
                                                    <span>{{"Describe what your company does"| translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <input matInput #input formControlName="description">
                                                        <mat-error>
                                                            <span *ngFor="
                                        let error of businessForm.get('description').errors
                                          | keyvalue
                                      ">
                                                                {{ validationMessages.description[error.key] | translate
                                                                }}
                                                            </span>
                                                        </mat-error>

                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="input-row">
                                                <div class="customInput">
                                                    <span>{{"Company physical address" | translate}} </span>
                                                    <mat-form-field fxFlex="auto">
                                                        <input matInput required formControlName="business_address">
                                                    </mat-form-field>
                                                </div>
                                            </div>


                                            <div fxLayout="row" *ngIf="showCompanyDocument">
                                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                                <mat-file-upload class="" [labelText]="''" [uploadButtonText]="'upload'"
                                                    [showUploadButton]="false"
                                                    [selectButtonText]="'Upload Company documents'"
                                                    [acceptedTypes]="'.doc, .pdf'"
                                                    (uploadClicked)="uploadCompanyDoc($event)"
                                                    (change)="uploadCompanyDoc($event)"> 
                                                </mat-file-upload>
                                            </div>


                                            <div fxLayout="row" *ngIf="showCertificateOfIncorporation">
                                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                                <mat-file-upload class="" [labelText]="''" [uploadButtonText]="'upload'"
                                                    [showUploadButton]="false"
                                                    [selectButtonText]="'Upload Certificate of Incorporation'"
                                                    [acceptedTypes]="'.doc, .pdf'"
                                                    (uploadClicked)="uploadCompanyDocCertificateOfIncorporation($event)"
                                                    (change)="uploadCompanyDocCertificateOfIncorporation($event)">
                                                </mat-file-upload>
                                            </div>
 
                                            <div fxLayout="row" fxLayoutAlign="start" *ngIf="showFiscalDeclaration"> 
                                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                                <mat-file-upload class=""  [labelText]="''" [uploadButtonText]="'upload'" 
                                                    [showUploadButton]="false"
                                                    [selectButtonText]="'Upload Fiscal Declaration'"
                                                    [acceptedTypes]="'.doc, .pdf'"
                                                    (uploadClicked)="uploadCompanyDocFiscalDeclaration($event)"
                                                    (change)="uploadCompanyDocFiscalDeclaration($event)">
                                                </mat-file-upload>
                                            </div>
                                            <div fxLayout="row" fxLayoutAlign="start" *ngIf="showRccm"> 
                                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                                <mat-file-upload class=""  [labelText]="''" [uploadButtonText]="'upload'" 
                                                    [showUploadButton]="false"
                                                    [selectButtonText]="'Upload RCCM'"
                                                    [acceptedTypes]="'.doc, .pdf'"
                                                    (uploadClicked)="uploadCompanyDocRccm($event)"
                                                    (change)="uploadCompanyDocRccm($event)">
                                                </mat-file-upload>
                                            </div>
                                            <div fxLayout="row" fxLayoutAlign="start" *ngIf="showNinea"> 
                                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                                <mat-file-upload class="" [labelText]="''" [uploadButtonText]="'upload'" 
                                                    [showUploadButton]="false"
                                                    [selectButtonText]="'Upload Ninea'"
                                                    [acceptedTypes]="'.doc, .pdf'"
                                                    (uploadClicked)="uploadCompanyDocNinea($event)"
                                                    (change)="uploadCompanyDocNinea($event)">
                                                </mat-file-upload>
                                            </div>
                                            <div fxLayout="row" fxLayoutAlign="start" *ngIf="showIfu"> 
                                                <mat-icon class="py-2" [icIcon]="icOutlineUpload"></mat-icon>
                                                <mat-file-upload class=""  [labelText]="''" [uploadButtonText]="'upload'" 
                                                    [showUploadButton]="false"
                                                    [selectButtonText]="'Upload Ifu'"
                                                    [acceptedTypes]="'.doc, .pdf'"
                                                    (uploadClicked)="uploadCompanyDocIfu($event)"
                                                    (change)="uploadCompanyDocIfu($event)">
                                                </mat-file-upload>
                                            </div>
                                            



                                        </div>
                                    </div>

                                </div>



                            </div>
                            <!--BUTTONS SAVE--> 
                           <!-- VALID :  {{updateBusinessForm.valid}} <br> <br>
                            DIRTY : {{updateBusinessForm.dirty}} <br> <br>
                            REGUIRED :{{requiredFilesUpload}}-->



                            <!-- || !requiredFilesUpload -->
                            <div *ngIf="this.menu.current.value=='profile'" class="mt-5 my-5 mx-5 px-5">
                                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
                                    <button class="confirm-btn" mat-flat-button type="button"
                                        [disabled]="!updateBusinessForm.dirty || !updateBusinessForm.valid"
                                        (click)="updateBusinessProfile()">
                                        {{ isUpdating === true ? 'SAVING...' : 'SAVE' | translate }}
                                    </button> 
                                </div>
                            </div>





                            <div *ngIf="this.menu.current.value=='collaborators_team'" fxLayout="column">
                                <vex-collaborators-team>
                                </vex-collaborators-team>
                            </div>
                            <div *ngIf="this.menu.current.value=='security'" class="owner" fxLayout="column"
                                style="margin-left:20px">

                                <vex-change-password></vex-change-password>
                                <div>
                                    <div fxLayout="row" fxLayoutAlign="between center" class="bloc-header my-3">
                                        <h3 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 rtl:border-l"
                                            fxFlex="none" fxHide.xs>
                                            {{"2F Authentification Security"| translate}}
                                        </h3>
                                    </div>
                                    <mat-divider class="-mx-12 text-border"></mat-divider>
                                    <div fxLayout="row" fxLayoutAlign="start center" class="">
                                        {{"Enable 2F Authentification"| translate}}
                                        <mat-slide-toggle class="ml-1" color="primary"
                                            [checked]="isTwoFactorAuthEnabled"
                                            (change)="toggleTwoFactorAuth($event)"></mat-slide-toggle>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    
                </ng-container>



            </div>
        </div>
    </div>
</div>