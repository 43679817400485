<vex-page-layout class="px-5 py-5 mb-5">
    <mat-toolbar class="py-3" color="primary">
        <div class="primary-btns">
            <h3 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 rtl:border-l" fxFlex="none" fxHide.xs>
                {{"Make Bank Transfer" | translate}}
            </h3>
        </div>
        <!--<div class="secondary-btns">
            <div class="flex">
                    AVANT
                    <button *ngIf="!confirmedTransfer" mat-flat-button [class.confirm-btn]="form.valid"
                    [disabled]="!form.valid" (click)="submitAction()" type="submit"> {{submitBtnText}} </button>
                    <button *ngIf="confirmedTransfer" mat-flat-button class="confirm-btn py-1"
                    (click)="initiateBankTransfer()" type="submit"> {{submitBtnText}} </button>
                 

                    APRES
                    <button *ngIf="!confirmedTransfer" mat-flat-button [class.confirm-btn]="form.valid"
                    [disabled]="!form.valid" (click)="submitAction()" type="submit"> {{submitBtnText}} </button>
                    <button [disabled]="isDisbursing" *ngIf="confirmedTransfer" mat-flat-button class="confirm-btn"
                    (click)="initiateBankTransfer()" type="submit"> {{ !isDisbursing ? submitBtnText :
                    "sending..." }} </button> 
            </div>
        </div>-->
    </mat-toolbar>
    <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
        [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6 pb-5 mb-5">

        <div [@stagger]="true" class="w-full" vexContainer>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px">
                <div *ngIf="!isFormReady" class="card overflow-auto -mt-16">
                    <div style="
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                  background: white;">
                        <mat-spinner color="primary"></mat-spinner>
                    </div>
                </div>


                <form (ngSubmit)="submitAction()" [formGroup]="form" @fadeInUp class="-mx-6 mb-5 pb-5 py-5"
                    fxFlex="auto">

                    <mat-divider class="-mx-6 text-border"></mat-divider>

                    <div class="input-bloc w-full" *ngIf="!confirmedTransfer  && afterBeneficiarySelected == false">


                        <!--<div class="customInput mx-5 my-5" *ngIf="!selectBenefeficiary">
                            <div class="flex flex-row">
                                <ng-container class="mx-5">
                                    <mat-checkbox formControlName="beneficiary" class="checkbox-option">
                                        <span style="color: #2BB996 ;"> <b>Select a beneficiary</b> </span>
                                    </mat-checkbox>
                                </ng-container>
                            </div>
                        </div> <br> 
                        -->
 
                        <div class="my-3" *ngIf="!selectBenefeficiary">
                            <button [disabled]="isLoadingBankData" (click)="onSelectBeneficiary()" class="centered-button btn-bottom-bloc" style="background-color: #2BB996; color: white; height: 45px;">
                                <div class="flex items-center flex-none speci-nav sidenav-toolbar">
                                    <mat-icon [svgIcon]="'side-cash-in'" class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
                                    <span> {{"Select a beneficiary" | translate}} </span>
                                </div>
                            </button>
                        </div> <br>

                         
                        
                       
                        <!--<button mat-button type="submit" class="disburs" style="background-color: #2BB996; color: white; height: 45px; width: 520px;">
                            <mat-icon [svgIcon]="'side-cash-in'" class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
                                    Make Transfer
                                </button>-->


 
                        <div class="customInput customSelect mt-5"
                            *ngIf="selectBenefeficiary && !afterBeneficiarySelected">
                            <span class="confirm-form-title flex-auto">{{"Select the beneficiary"| translate}} </span>
                            <ng-container *ngIf="beneficiaries.length > 0; else notFoundMessage">
                                <mat-form-field class="sm:ml-6 flex-auto w-full">
                                    <mat-select placeholder="{{'Select a Beneficiary' | translate}}" formControlName="beneficiary_id">
                                        <mat-option *ngFor="let beneficiary of beneficiaries" [value]="beneficiary.id">
                                            {{beneficiary.beneficiary_name}} | {{beneficiary.account_number}} |
                                            {{getCountryNameByCode(beneficiary.beneficiary_country)}}
 
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <ng-template class="mt-5" #notFoundMessage>
                                <br>
                                <mat-error class="mx-5 my-5">
                                    {{"Sorry, you have not set a beneficiary"| translate}}
                                </mat-error>
                            </ng-template>
                        </div>



                        <div class="customInput customSelect" *ngIf="!selectBenefeficiary">
                            <span class="confirm-form-title flex-auto">{{"Select the recipient's country"| translate}} </span>
                            <mat-form-field class="sm:ml-6 flex-auto w-full" *ngIf="!isLoadingBankData">
                                <mat-select placeholder="Select a Country" formControlName="country" >
                                    <mat-option *ngFor="let country of availableCountries" [value]="country.code">
                                        <div style="display: flex; align-items: center;"> 
                                            <img src="assets/contryFlg/flags/1/{{country.code | lowercase}}.png" style="height: 20px; width: auto; margin-right: 10px;">
                                            {{ country.label }}
                                          </div>
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="formControl?.country?.errors?.required">
                                    {{"This field is required"| translate}}
                                </mat-error>
                            </mat-form-field>
                            <div class="mx-6">
                                <mat-spinner style="font-size: small;" *ngIf="!availableCountries || isLoadingBankData" color="primary">
                                </mat-spinner>
                            </div>
                        </div>
                        <div class="customInput" *ngIf="showAmountInput">
                            <span class="confirm-form-title flex-auto">{{"How much to transfer?"| translate}} </span>
                            <mat-form-field class="sm:ml-6 flex-auto w-full">
                                <div matPrefix class="currency-prefix">
                                    <span> {{ currency }} </span>
                                </div>
                                <input matInput type="number" formControlName="amount" required>
                                <mat-error *ngIf="formControl?.amount?.errors?.required">
                                    {{"This field is required"| translate}}
                                </mat-error>
                                <mat-error *ngIf="formControl?.amount?.errors?.max">{{"You're limited to" | translate}} {{maxAmount}} {{
                                    currency }} {{"per transaction "| translate}}
                                </mat-error>
                                <mat-error *ngIf="formControl?.amount?.errors?.pattern">
                                    {{"This value is invalid"| translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>


                        <!--[value]="bank?.code ? bank?.code : bank?.id"-->
                        <div *ngIf="!isSwiftCodeNeeded && banksData && !selectBenefeficiary"
                            class="customInput customSelect">
                            <span class="confirm-form-title flex-auto">{{"Bank name"| translate}} </span>
                            <mat-form-field class="sm:ml-6 flex-auto w-full">
                                <mat-select placeholder="Select a Bank" formControlName="account_bank">
                                    <mat-option *ngFor="let bank of banksData"
                                    [value]="bank?.code ? bank?.code : bank?.id"
                                    >
                                        {{ bank?.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="formControl.account_bank?.errors?.required">{{"This field is required"| translate}} </mat-error>
                            </mat-form-field>
                        </div>
                         
                        <div class="mx-6" *ngIf="!selectBenefeficiary">
                            <mat-spinner style="font-size: small;" *ngIf="isLoadingBankData" color="primary">
                            </mat-spinner>
                        </div>
                         

                        <div *ngIf="!isSwiftCodeNeeded && !selectBenefeficiary ">
                            <div *ngIf="bankBranches" class="customInput customSelect">
                                <span class="confirm-form-title flex-auto">{{"Bank Branch"| translate}} </span>
                                <mat-form-field class="sm:ml-6 flex-auto w-full">
                                    <mat-select [(ngModel)]="this.bankBranches[0].branch_name"
                                        placeholder="Select a Branch" formControlName="bank_branch">
                                        <mat-option *ngFor="let branch of bankBranches" [value]="branch?.branch_name">
                                            {{ branch?.branch_name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="formControl.bank_branch?.errors?.required">{{"This field is required"| translate}} </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="mx-6" *ngIf="!bankBranches && isBankBranchNeeded">
                                <mat-spinner style="font-size: small;" color="primary">
                                </mat-spinner>
                            </div>
                        </div>
                        <div *ngIf="isSwiftCodeNeeded && !selectBenefeficiary" class="customInput">
                            <span class="confirm-form-title flex-auto">{{"Bank name"| translate}} </span>
                            <mat-form-field class="sm:ml-6 flex-auto w-full">
                                <input matInput type="text" formControlName="account_bank" />
                                <mat-error *ngIf="formControl.account_bank?.errors?.required">{{"This field is required"| translate}} </mat-error>
                            </mat-form-field>
                        </div>
                        <!-- && !selectBenefeficiary-->
                        <div *ngIf="isSwiftCodeNeeded" class="customInput">
                            <span class="confirm-form-title flex-auto">Swift Code</span>
                            <mat-form-field class="sm:ml-6 flex-auto w-full">
                                <input matInput type="text" formControlName="swift_code"/>
                                <mat-error *ngIf="formControl.swift_code.errors?.required">{{"This field is required"| translate}}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="customInput" *ngIf="!selectBenefeficiary">
                            <span class="confirm-form-title flex-auto"> {{accountNumberTitle}} </span> <!--/ IBAN-->

                            <mat-form-field class="sm:ml-6 flex-auto w-full">
                                <input [maxlength]="maxAccountNumberLength" [placeholder]="accountNumberPlaceholder"
                                    matInput type="text" formControlName="account_number" required>
                                <mat-error *ngIf="formControl?.account_number?.errors?.required">
                                    {{"This field is required"| translate}}
                                </mat-error>
                                <mat-error *ngIf="formControl?.account_number?.errors?.pattern">
                                    {{"This field must contain exactly"| translate}} {{accountNumberLength}} {{"characters"| translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="customInput" *ngIf="!selectBenefeficiary">
                            <span class="confirm-form-title flex-auto">{{"Account name"| translate}} </span>
                            <mat-form-field class="sm:ml-6 flex-auto w-full">
                                <input matInput type="text" formControlName="beneficiary_name">
                                <mat-error *ngIf="formControl?.beneficiary_name?.errors?.required">{{"This field is required"| translate}}</mat-error>
                            </mat-form-field>
                        </div>
                        <!-- <div class="customInput customSelect">
                            <span class="confirm-form-title flex-auto">Recipient Phone</span>
                            <mat-form-field class="sm:ml-6 flex-auto w-full">
                                <input matInput type="text" placeholder="{{placeHolder}}" formControlName="phone_no"
                                    required>
                                <mat-error *ngIf="formControl?.phone_no?.errors?.required">
                                    This field is required
                                </mat-error>
                                <mat-error>
                                    <span *ngFor="
                                        let error of form?.get('phone_no').errors | keyvalue
                                        ">
                                        {{error.key=='pattern'?validationMessages.phone_no[error.key]+dailingCode+"
                                        correct number length":validationMessages.phone_no[error.key]}}
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>-->
                        <!--*ngIf="!selectBenefeficiary"-->
                        <div class="customInput customSelect" *ngIf="showTransferReciepientEmail">
                            <span class="confirm-form-title flex-auto">{{"Recipient Email" | translate}} </span>
                            <mat-form-field class="sm:ml-6 flex-auto w-full">
                                <input matInput type="email" formControlName="email">
                            </mat-form-field>
                        </div>


                        <div class="customInput customSelect" *ngIf="showTransferReasonInput">
                            <span class="confirm-form-title flex-auto">{{"Reason for the transfer" | translate}} </span>
                            <mat-form-field class="sm:ml-6 flex-auto w-full">
                                <input matInput type="text" formControlName="narration">
                            </mat-form-field>
                        </div>
                        <div class="2xl:alert-error error-bloc" *ngIf="errorMessage && !form.valid">
                            <span>{{errorMessage | translate}}</span>
                        </div>
                    </div>

                    <div class="approve-bloc" *ngIf="confirmedTransfer">

                        <div class="approve-info">
                            <span class="approve-title">{{"Recipient's country"| translate}} </span>
                            <span class="approve-content">{{getCountryName()}}</span>
                        </div>
                        <div class="approve-info">
                            <span class="approve-title">{{"How much to transfer?" | translate}} </span>
                            <span class="price-info">
                                <!--{{form.get("currency").value}}--> {{currency}} <span>{{form.get("amount").value}}</span>
                            </span>
                        </div>
                        <div class="approve-info">
                            <span class="approve-title">{{"Bank name"| translate}} </span>
                            <span class="approve-content">{{ getBankName() }}</span>
                        </div>
                        <div *ngIf="!isSwiftCodeNeeded && currency != 'NGN'" class="approve-info">
                            <span class="approve-title">Bank Branch</span>
                            <span class="approve-content">{{form.get("bank_branch").value}}</span>
                        </div>
                        <div *ngIf="isSwiftCodeNeeded" class="approve-info">
                            <span class="approve-title">{{"Swift Code" | translate}} </span>
                            <span class="approve-content">{{form.get("swift_code").value}}</span>
                        </div>
                        <div class="approve-info">
                            <span class="approve-title">{{accountNumberTitle}} </span>
                            <span class="approve-content">{{form.get("account_number").value}}</span>
                        </div>
                        <div class="approve-info">
                            <span class="approve-title">{{"Account name" | translate}} </span>
                            <span class="approve-content">{{form.get("beneficiary_name").value}}</span>
                        </div>

                        <div class="approve-info">
                            <span class="approve-title">{{"Recipient Email" | translate}} </span>
                            <span class="approve-content">{{form.get("email").value}}</span>
                        </div>

                        <!--
                        <div class="approve-info">
                            <span class="approve-title">Recipient Phone</span>
                            <span class="approve-content">{{form.get("phone_no").value}}</span>
                        </div>
                        -->

                        <div class="approve-info">
                            <span class="approve-title">{{"Reason for the transfer" | translate}} </span>
                            <span class="approve-content">{{form.get("narration").value}}</span>
                        </div>


                    </div>
                    <div class="btn-bottom-bloc  pb-5">

                        

                        <button *ngIf="selectBenefeficiary && !confirmedTransfer" mat-flat-button  class="cancel-btn"
                            (click)="cancelSelectBenef()">
                           {{" Cancel"| translate}} 
                        </button>
                        <button *ngIf="confirmedTransfer" mat-flat-button type="button" class="cancel-btn"
                            (click)="cancelAction()">
                            {{"Back"| translate}}
                        </button>
                        <button *ngIf="!confirmedTransfer" mat-flat-button [class.confirm-btn]="form.valid"
                            [disabled]="!form.valid" (click)="submitAction()" type="submit"> {{submitBtnText | translate}} </button>

                        <button [disabled]="isDisbursing" *ngIf="confirmedTransfer" mat-flat-button class="confirm-btn"
                            (click)="initiateBankTransfer()" type="submit"> {{ !isDisbursing ? submitBtnText :
                            'sending...'| translate }} </button>
                    </div>




                </form>

            </div>

        </div>
    </vex-page-layout-content>
</vex-page-layout>