import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Observable, throwError as observableThrowError } from "rxjs";

@Injectable({
  providedIn: "root",
})

export class BeneficiariesService {
  constructor(private http: HttpClient) { }

  getBankBeneficiaries(
    userId: string,
    credentials,
    range = null
  ): Observable<any> {
    let params = new HttpParams();
    if (range) {
      params = params.append("from", range.from);
      params = params.append("to", range.to);
    } else {
      params = null;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${credentials}`,
      "Content-Type": "application/json",
    });

    const url = `${environment.getbankbeneficiaries}${userId}`;
    return this.http.get(url, { headers: headers, params }).pipe(
      map((beneficiary: any) => {
        const beneficiaries = beneficiary.data.map((values) => {
          return values;
        });
        return beneficiaries;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error("Error", error.message);
        return observableThrowError(error);
      })
    );
  }

  addBankBeneficiary(
    credentials,
    data: {
      user_id: string;
      currency: string;
      beneficiary_name: string;
      phone_no: string;
      rib: string;
      bank_code: string;
      account_number: string;
      Swift_code: string;
      bank_name: string;
      bank_branch: string;
      beneficiary_country: string;
    }
  ) {
    const body = data;

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${credentials}`,
    });
    const url = `${environment.addbankbeneficiaryUrl}`;
    let params = new HttpParams();

    params = params.append("account_number", body.account_number);
    params = params.append("bank_code", body.bank_code);
    params = params.append("currency", body.currency);

    return this.http.post(url, body, { responseType: "json", headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error("Error", error.message);
        return observableThrowError(error);
      })
    );
  }

  deleteBankBeneficiary(credentials, data: { user_id: any; id: any }) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${credentials}`,
    });
    const url = `${environment.deletebankbeneficiaryUrl}/${data.user_id}/${data.id}`;

    return this.http.delete(url, { headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error("Error", error.message);
        return observableThrowError(error);
      })
    );
  }

  getMobileBeneficiaries(
    userId: string,
    credentials,
    range = null
  ): Observable<any> {
    let params = new HttpParams();
    if (range) {
      params = params.append("from", range.from);
      params = params.append("to", range.to);
    } else {
      params = null;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${credentials}`,
      "Content-Type": "application/json",
    });

    const url = `${environment.getmobilebeneficiaries}${userId}`;
    return this.http.get(url, { headers: headers, params }).pipe(
      map((beneficiary: any) => {
        const beneficiaries = beneficiary.data.map((values) => {
          return values;
        });
        return beneficiaries;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error("Error", error.message);
        return observableThrowError(error);
      })
    );
  }
  addMobileBeneficiary(
    credentials,
    data: {
      user_id: string;
      operation: string;
      phone_no: string;
      country: string;
      is_merchant: boolean;
    }
  ) {
    const body = data;

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${credentials}`,
    });
    const url = `${environment.addmobilebeneficiaryUrl}`;
    let params = new HttpParams();

    return this.http.post(url, body, { responseType: "json", headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error("Error", error.message);
        return observableThrowError(error);
      })
    );
  }
  deleteMobileBeneficiary(credentials, data: { user_id: any; id: any }) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${credentials}`,
    });
    const url = `${environment.deletemobilebeneficiaryUrl}/${data.user_id}/${data.id}`;

    return this.http.delete(url, { headers }).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error("Error", error.message);
        return observableThrowError(error);
      })
    );
  }
}
