<div class="cardContainerCustom mb-5">

  <hr style="border-color: rgba(0, 0, 0, 0.169);">
  <!--class="grid grid-cols-4 gap-20 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gridContainer"-->
  <div class="ms-5 pl-5 pb-5">
    <hr>
    <form (ngSubmit)="submitAction()" [formGroup]="form" @fadeInUp class="mt-5">
      <select mat-select class="account-select" name="" id="" (change)="changeAction($event)">
        <option value="">{{ "Select currency" | translate }}</option>
        <option value="XOF">{{ 'XOF ACCOUNT' | translate }}</option>
        <option value="XAF">{{ 'XAF ACCOUNT' | translate }}</option>
        <option value="GHS">{{ 'GHS ACCOUNT' | translate }}</option>
        <option value="NGN">{{ 'NGN ACCOUNT' | translate }}</option>
      </select>
    </form><br>
    <h1>
      <span style="font-size: 20px; font-weight: 900;">
        {{currentBalanceCurrency}}
      </span>
      <b>
        <span *ngIf="isAllowed('3') || isAllowed('1')">
          {{currentBalance | number: "1.2-2"}}
        </span>
        <span *ngIf="!isAllowed('3') && !isAllowed('1')">
          ********
        </span>
      </b>
    </h1>

  </div>
  <hr style="border-color: rgba(0, 0, 0, 0.169);">
</div>

 

<!--
<div class="row">
  <div class="col-4">
    <button class=" text-left rounded btn-box   pl-2 pb-2" style=" border: solid 1px; ">
      <h4>
        <span class="mt-5 pt-5">
          <mat-icon class="pt-5 mt-5" [svgIcon]="'naira'" style="position: relative; top: 8px; "
            class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
        </span>
        <b>{{"Nigeria"| translate}} </b>
        <mat-icon [svgIcon]="'arrow'" class="menu-item-icon mr-1 " style="position: relative; top: 7px; left: 101px;"
          matListIcon aria-hidden="false"></mat-icon>
      </h4>
      <span style="font-size: 10px;">
        {{"Send to Nigeria bank account" | translate}}
      </span>
    </button>
  </div>
  <div class="col-4">
    <button class=" text-left rounded btn-box   pl-2 pb-2" style=" border: solid 1px; ">
      <h4>
        <span class="mt-5 pt-5">
          <mat-icon class="pt-5 mt-5" [svgIcon]="'naira'" style="position: relative; top: 8px; "
            class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
        </span>
        <b>{{"Nigeria"| translate}} </b>
        <mat-icon [svgIcon]="'arrow'" class="menu-item-icon mr-1 " style="position: relative; top: 7px; left: 101px;"
          matListIcon aria-hidden="false"></mat-icon>
      </h4>
      <span style="font-size: 10px;">
        {{"Send to Nigeria bank account" | translate}}
      </span>
    </button>
  </div>

  <div class="col-4">
    <button class=" text-left rounded btn-box   pl-2 pb-2" style=" border: solid 1px; ">
      <h4>
        <span class="mt-5 pt-5">
          <mat-icon class="pt-5 mt-5" [svgIcon]="'naira'" style="position: relative; top: 8px; "
            class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
        </span>
        <b>{{"Nigeria"| translate}} </b>
        <mat-icon [svgIcon]="'arrow'" class="menu-item-icon mr-1 " style="position: relative; top: 7px; left: 101px;"
          matListIcon aria-hidden="false"></mat-icon>
      </h4>
      <span style="font-size: 10px;">
        {{"Send to Nigeria bank account" | translate}}
      </span>
    </button>
  </div>

  <div class="col-4">
    <button class=" text-left rounded btn-box   pl-2 pb-2" style=" border: solid 1px; ">
      <h4>
        <span class="mt-5 pt-5">
          <mat-icon class="pt-5 mt-5" [svgIcon]="'naira'" style="position: relative; top: 8px; "
            class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
        </span>
        <b>{{"Nigeria"| translate}} </b>
        <mat-icon [svgIcon]="'arrow'" class="menu-item-icon mr-1 " style="position: relative; top: 7px; left: 101px;"
          matListIcon aria-hidden="false"></mat-icon>
      </h4>
      <span style="font-size: 10px;">
        {{"Send to Nigeria bank account" | translate}}
      </span>
    </button>
  </div>
</div> 
-->

<h5 class="ms-5 pl-5"><b>{{"Quick Action" | translate}} </b> </h5> <br>



<div @stagger style="margin: 20px; display: flex;" class="-mb-16 mx-auto" fxLayout="row" fxLayout.xs="column"
  fxLayoutGap="24px" fxLayoutGap.xs="16px">

  <div style="flex: 1;">

    <a #rla="routerLinkActive" @fadeInUp [class.border-primary]="rla.isActive"
      class="card block text-left p-6 hover:bg-app-bar trans-ease-out cursor-pointer relative no-underline text-dark border-2"
      fxFlex fxFlex.xs="auto" matRipple routerLinkActive style="text-align: left;"
      (click)="openMakeTransferChoicePopup()">

      <!-- Votre contenu ici, aligné à gauche -->
      <h4>
        <mat-icon [svgIcon]="'send-mail3'" style="position: relative; top: 8px; " class="menu-item-icon mr-1"
          matListIcon aria-hidden="false"></mat-icon>
        <b>{{"Make Transfer" | translate}} </b>
        <mat-icon [svgIcon]="'arrow'" class="menu-item-icon mr-1 " style="position: relative; top: 7px; left: 52px;"
          matListIcon aria-hidden="false"></mat-icon>
      </h4>
      <span class="ms-5" style="font-size: 10px;">
        {{"Send to merchant or consumer's wallet" | translate}}
      </span>
    </a>
  </div>
  <div style="flex: 1;">
    <a #rla="routerLinkActive" @fadeInUp [class.border-primary]="rla.isActive"
      class="card block text-left p-6 hover:bg-app-bar trans-ease-out cursor-pointer relative no-underline text-dark border-2"
      fxFlex fxFlex.xs="auto" matRipple routerLinkActive style="text-align: left;"
      (click)="openDisburseBankNigeriaPopup()">

      <!-- Votre contenu ici, aligné à gauche -->
      <h4>
        <span class="mt-5 pt-5">
          <mat-icon class="pt-5 mt-5" [svgIcon]="'naira'" style="position: relative; top: 8px; "
            class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
        </span>
        <b>{{"Nigeria"| translate}} </b>
        <mat-icon [svgIcon]="'arrow'" class="menu-item-icon mr-1 " style="position: relative; top: 7px; left: 110px;"
          matListIcon aria-hidden="false"></mat-icon>
      </h4>
      <span style="font-size: 10px;">
        {{"Send to Nigeria bank account" | translate}}
      </span>
    </a>
  </div>

  <div style="flex: 1;">
    <a #rla="routerLinkActive" @fadeInUp [class.border-primary]="rla.isActive"
      class="card block text-left p-6 hover:bg-app-bar trans-ease-out cursor-pointer relative no-underline text-dark border-2"
      fxFlex fxFlex.xs="auto" matRipple routerLinkActive style="text-align: left;"
      (click)="openExchangePupop()">

      <!-- Votre contenu ici, aligné à gauche -->
      <h4>
        <span class="mt-5 pt-5">
          <mat-icon class="pt-5 mt-5" [svgIcon]="'refresh'" style="position: relative; top: 8px; "
            class="menu-item-icon mr-1" matListIcon aria-hidden="false"></mat-icon>
        </span>
        <b>{{"Swap"| translate}} </b>
        <mat-icon [svgIcon]="'arrow'" class="menu-item-icon mr-1 " style="position: relative; top: 7px; left: 110px;"
          matListIcon aria-hidden="false"></mat-icon>
      </h4>
      <span style="font-size: 10px;">
        {{"Swap currencies instantly" | translate}}
      </span>
    </a>
  </div> <!-- La deuxième moitié vide -->
  <div style="flex: 1;"></div>

</div>




<div @stagger style="margin: 20px; display: flex;" class="-mb-16 mx-auto" fxLayout="row" fxLayout.xs="column"
  fxLayoutGap="24px" fxLayoutGap.xs="16px">
  <div style="flex: 1;">
    <h5 class="ms-5 mt-5 me-auto"><b>{{"Team" | translate}} </b> </h5>
  </div>
  <div style="flex: 1; text-align: right;">
    <button mat-stroked-button color="primary" class="add-funds me-5" type="button"
      (click)="openTeamCollaboratorsPopup2()">
      <mat-icon class="mr-2">add_circle_outlined</mat-icon> {{"Add Team Member" | translate }}
    </button>
  </div>
</div>
<div *ngIf="!hasTeam" class="card   me-5 ms-5" style="border: dotted 1px; margin-right: 20px; margin-left: 20px;">
  <div style="height: 300px; " class="text-center">
    <button style="position: relative; top: 130px; " mat-button color="primary" class="add-funds" type="button"
      (click)="openTeamCollaboratorsPopup2()">
      <mat-icon class="mr-2">add_circle_outlined</mat-icon> {{"Add your Team" | translate }}
    </button>
  </div>
</div>



<div class="card overflow-auto mt-5 mx-5" *ngIf="hasTeam">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef>{{"Last Name" | translate}} </th>
      <td mat-cell *matCellDef="let element">
        {{element.last_name}}
      </td>
    </ng-container>
    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef>{{"First Name"| translate}} </th>
      <td mat-cell *matCellDef="let element">
        {{element.first_name}}
      </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element">
        {{element.email}}
      </td>
    </ng-container>
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let element">
        <span *ngFor="let role of userRole">
          <span *ngIf="role.email == element.email">
            {{role.role}}
          </span>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{"Status"| translate}} </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="getStatus(element.status) == 'Pending'">
          <span class="underline cursor-pointer pending-text" [ngClass]="getStatus(element.status)"> <b>{{
              getStatus(element.status) }}</b> </span>
        </span>
        <span *ngIf="getStatus(element.status) == 'Joined'">
          <span class="underline cursor-pointer joined-text" [ngClass]="getStatus(element.status)"> <b>{{
              getStatus(element.status) }}</b> </span>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" style="text-align: right;">
        <button mat-icon-button [matMenuTriggerFor]="columnFilterMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #columnFilterMenu="matMenu">
          <button mat-menu-item (click)="openTeamCollaboratorsPopup(element)">
            <mat-icon color="primary"> dialpad </mat-icon>
            <span color="primary"> Permissions </span>
          </button>
          <button mat-menu-item (click)="removeUser(element)">
            <mat-icon color="warn">delete</mat-icon>
            <span color="warn">{{"Remove"| translate}} </span>
          </button>

        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>


</div>
<br>


<!--ANALYTICS-->
<!--<div *ngIf="userData.hasBusiness && isLoading && !dataLoaded" class="-mt-16 overflow-auto card">
  <br />
  <br />
  <br />
  <div style="
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
    ">
    <mat-spinner color="primary"> </mat-spinner>
  </div>
</div>

<div *ngIf="userData.hasBusiness && dataLoaded" class="p-gutter" gdColumns="1fr 1fr 1fr 1fr" gdColumns.lt-md="1fr 1fr"
  gdColumns.lt-sm="1fr" gdGap="16px" vexContainer>
  <vex-widget-large-goal-chart [series]="salesSeries" gdColumn="1 / -1" gdColumn.lt-sm="1" [options]="options"
    [totalAmount]="currentMonthAmount" [total]="currentMonthTransactions"></vex-widget-large-goal-chart>
</div>-->