import { CollaborationTeamService } from './../../../../services/collaboration-team.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { AuthorizationConfimationComponent } from '../../authorization-confimation/authorization-confimation.component';
import { BANK_BRANCHES, BUSINESS_DATA_KEY, COUNTRIES, COUNTRY_CODES, USER_SESSION_KEY } from '../../../../Models/constants';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatMenu } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'vex-collaborators-team',
  templateUrl: './collaborators-team.component.html',
  styleUrls: ['./collaborators-team.component.scss']
})
export class CollaboratorsTeamComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatTableExporterDirective)
  matTableExporter: MatTableExporterDirective;

  validationMessages = {
    email: {
      required: 'This Field  is required.',
      requiredPattern: 'Enter correct email'
    },
  };

  defaultIDTemaMember: any;

  businessForm: FormGroup;
  invitation: boolean = false;
  updating: boolean = false;
  errorMessage: string;
  collaboratorsDataSource: MatTableDataSource<any> | null;
  isChechGroupsValid: boolean = false;
  userRole = []
  inviting : boolean = false

  /*
  checkBoxGroups = [
    {
      title: "Admin authorization",
      checkBoxes: [
        { id: "1", text: "Will have all right as you", checked: false }
      ]
    },
    {
      title: "Business authorization",
      checkBoxes: [
        { id: "2", text: "Can edit business profile", checked: false },
      ]
    },
    {
      title: "Balance authorization",
      checkBoxes: [
        { id: "3", text: "Can view balance", checked: false },
        { id: "4", text: "Can fund balance", checked: false },
        { id: "5", text: "Can view balance histories", checked: false },
      ]
    },
    {
      title: "Transaction authorization",
      checkBoxes: [
        { id: "6", text: "Can create transaction", checked: false },
        { id: "7", text: "Can download report", checked: false },
      ]
    },
    {
      title: "Developer authorization",
      checkBoxes: [
        { id: "8", text: "Can view API keys", checked: false },
      ]
    }
  ]
  */
  checkBoxGroups = [


    {
      id: "1",
      roleTitle: "Admin",
      roleCheckBoxes: [
        { id: "1", text: "Will have all right as you", disabled: false, checked: false }
      ],
      disabled: false,
      checked: false
    },
    {
      id: "2",
      roleTitle: "Compliance Officer",
      roleCheckBoxes: [
        { id: "2", text: "Can edit business profile", disabled: false, checked: false },
      ],
      disabled: false,
      checked: false
    },
    {
      id: "3",
      roleTitle: "Account Manager",
      roleCheckBoxes: [
        { id: "3", text: "Can view balance", disabled: false, checked: false },
        { id: "4", text: "Can fund balance", disabled: false, checked: false },
        { id: "5", text: "Can view balance histories", disabled: false, checked: false },
        { id: "6", text: "Can create and view transactions", disabled: false, checked: false },
        { id: "7", text: "Can download report", disabled: false, checked: false },
      ],
      disabled: false,
      checked: false
    },
    {
      id: "4",
      roleTitle: "Developper",
      roleCheckBoxes: [
        { id: "8", text: "Can view API keys", disabled: false, checked: false },
      ],
      disabled: false,
      checked: false
    }
 


  ]



  collaboratorsDisplayedColumns: string[] = [
    'first_name',
    'last_name',
    'email',
    'role',
    'status',
    'action'
  ];
  columnFilterMenu: MatMenu;
  userData: any;
  credentials: string;
  unsubscribe$ = new Subject();
  secondariesAccount: any
  selectedUser: any
  selectedUserHabilitations: any
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private collaborationTeamService: CollaborationTeamService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,

  ) {

     //IF TEAM OPTION CLIQUED
     this.route.paramMap.subscribe((params) => {
      const id = params.get('id_team_member');
      if (id) {
         this.defaultIDTemaMember = id
      }
    });



    const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
    this.userData = sessionData;
    this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;



    this.businessForm = this.formBuilder.group({
      first_name: [''],
      last_name: [''],
      email: ['', [Validators.required, Validators.pattern(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)]],
    });

    this.collaboratorsDataSource = new MatTableDataSource([

    ]);
    this.collaboratorsDataSource.paginator = this.paginator;
    this.collaboratorsDataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.getSecondariesAccount()
  }

  getSecondariesAccount() {

    this.collaborationTeamService
      .getSecondariesAccount(
        this.credentials
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          if (response['status'] === true) {
            //this.transactions = response['data'];
            this.secondariesAccount = response['data'].reverse()
            let liste = []

            for (let secondaryAccount of this.secondariesAccount) { 
              liste.push({ last_name: secondaryAccount.last_name, first_name: secondaryAccount.first_name, email: secondaryAccount.email, status: secondaryAccount.status, authorization: "A" })
              //this.collaboratorsDataSource = new MatTableDataSource(liste); 
            }
            this.collaboratorsDataSource = new MatTableDataSource(liste); 
            this.userRole = []
            for (let secondaryAccount of this.secondariesAccount) {
              this.getSecondaryUserRole(secondaryAccount.email)
            } 
            if(this.defaultIDTemaMember){
              let teamMember = this.secondariesAccount.find((member)=>  member.id == this.defaultIDTemaMember)
              if(teamMember){ 
                this.toUpdate(teamMember)
              } 
            } 
          }
        },
        (erro) => {

        }
      );

  }
  getSecondaryUserRole(email) { 
    this.collaborationTeamService.getSecondaryUserHabilitation(email, this.credentials).subscribe(
      response => { 
        let roleTilte: any
        let selectedUserHabilitations = response['data']['habilitations']
        if (selectedUserHabilitations != undefined) {
          selectedUserHabilitations = selectedUserHabilitations.split(',')
          for (let g of this.checkBoxGroups) {
            roleTilte = ""
            for (let role of g.roleCheckBoxes) {
              for (let hab of selectedUserHabilitations) {
                if (role.id == hab) {
                  roleTilte = g.roleTitle 
                  role.checked = true
                }
              }
            }
            this.userRole.push({ email: email, role: roleTilte }) 
          }
 
        }
      }
    )
  }
  getStatus(status) {
    if (status == "0") {
      return "Pending";
    }
    if (status == "1") {
      return "Joined";
    }
  }

  toInvite() {
    if (this.isAllowed("1")) {
      this.invitation = true;
      this.updating = false; 
      this.initializeForm()
    } else {
      this.openSnackbar("You are not allowed");
    }


  }

  isAllowed(habl: string) {

    let habilitations = []
    let isAllow = false
    if (this.userData.secondaryAccount) {
      return false 
    } else {
      isAllow = true
    }
    return isAllow
  }
  openSnackbar(message) {
    this.snackBar.open(message, 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'right',
    });
  }


  toUpdate(user) {
    this.invitation = true;
    this.updating = true;
    this.selectedUser = user
    this.businessForm.patchValue({
      email: this.selectedUser.email
    });
    //this.getSecondaryUserRole(user.email)
    //this.getPermissions() 
    this.getSecondaryUserHabilitations()
  }

  toCancel() {
    this.invitation = false;
  }

  chechGroupsIsValid() { 
    this.isChechGroupsValid = false
    let found = false
    for (let g of this.checkBoxGroups) {
      if (g.roleCheckBoxes.find(c => c.checked)) {
        found = true
        break;
      }
    }
    for (let g of this.checkBoxGroups) {
      if (g['checked']) {
        found = true
      }
    }
    if (found) this.isChechGroupsValid = true



  }

  selectAuthorization(r, g, event) {


    this.checkBoxGroups[r].roleCheckBoxes[g].checked = event.checked;

    if (this.checkBoxGroups[r].roleCheckBoxes[g].id == "1" && this.checkBoxGroups[r].roleCheckBoxes[g].checked == true) {

      for (let sG of this.checkBoxGroups) {
        if (sG.id != "1") {
          sG.disabled = true
          sG.checked = false
          for (let role of sG.roleCheckBoxes) {
            if (role.id != "1") {
              role.disabled = true
              role.checked = false
            }

            /*
            for (let rl of role.checkBoxes) {
              if (rl.id != "1") {
                rl.disabled = true
                rl.checked = false
              }
            }
            */

          }

        }
      }


    }
    if (this.checkBoxGroups[r].roleCheckBoxes[g].id == "1" && this.checkBoxGroups[r].roleCheckBoxes[g].checked == false) {
      for (let sG of this.checkBoxGroups) {
        if (sG.id != "1") {
          sG.disabled = false
          sG.checked = false
          for (let role of sG.roleCheckBoxes) {
            if (role.id != "1") {
              role.disabled = false
              role.checked = false
            } 
          }

        }
      }

    } 
    this.chechGroupsIsValid();
  }



  selectRoleAuthorization(r, event) {
    this.checkBoxGroups[r].checked = event.checked;
    for (let c of this.checkBoxGroups[r].roleCheckBoxes) {
      c.checked = event.checked 
      if (c.id == "1" && c.checked == true) { 
        for (let sG of this.checkBoxGroups) {
          if (sG.id != "1") {
            sG.disabled = true
            sG.checked = false
            for (let role of sG.roleCheckBoxes) {
              if (role.id != "1") {
                role.disabled = true
                role.checked = false
              } 
            } 
          }
        } 
      }
      if (c.id == "1" && c.checked == false) {
        for (let sG of this.checkBoxGroups) {
          if (sG.id != "1") {
            sG.disabled = false
            sG.checked = false
            for (let role of sG.roleCheckBoxes) {

              if (role.id != "1") {
                role.disabled = false
                role.checked = false
              } 

            }

          }
        }

      }

 
    }

    this.chechGroupsIsValid();
  }

  
  selectRoleAuthorizationCustom(r) {
    //this.checkBoxGroups[r].checked = event.checked;
    for (let c of this.checkBoxGroups[r].roleCheckBoxes) {
      //c.checked = event.checked 
      if (c.id == "1" && c.checked == true) { 
        for (let sG of this.checkBoxGroups) {
          if (sG.id != "1") {
            sG.disabled = true
            sG.checked = false
            for (let role of sG.roleCheckBoxes) {
              if (role.id != "1") {
                role.disabled = true
                role.checked = false
              } 
            } 
          }
        } 
      }
      if (c.id == "1" && c.checked == false) {
        for (let sG of this.checkBoxGroups) {
          if (sG.id != "1") {
            sG.disabled = false
            sG.checked = false
            for (let role of sG.roleCheckBoxes) {

              if (role.id != "1") {
                role.disabled = false
                role.checked = false
              } 

            }

          }
        }

      }

 
    }

    //this.chechGroupsIsValid();
  }


  openAuthorisationConfirmation() {
    this.inviting = true
    this.collaborationTeamService.sendInvitation(this.businessForm.get('first_name').value, this.businessForm.get('last_name').value, this.businessForm.get('email').value, this.getPermissions(), this.credentials).subscribe(
      response => {
        if (response['status'] == true) {
          this.inviting = false
          this.invitation = false
          this.openSnackbar(response['message']);
          this.getSecondariesAccount()
          this.initializeForm()
        } else {
          this.inviting = false
          if (response['status'] == false) {
            this.openSnackbar(response['message']);
          } else {
            this.showInternalServerErrorMessage()
          }

        }
      }
    )
  }
  showInternalServerErrorMessage() {
    this.errorMessage = "Error. Please retry again later."
    this.openSnackbar("Error. Please retry again later.");
  }
  initializeForm() {
    this.businessForm = this.formBuilder.group({
      first_name: [''],
      last_name: [''],
      email: ['', [Validators.required, Validators.pattern(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)]],
    });

    this.checkBoxGroups = [


      {
        id: "1",
        roleTitle: "Admin",
        roleCheckBoxes: [
          { id: "1", text: "Will have all right as you", disabled: false, checked: false }
        ],
        disabled: false,
        checked: false
      },
      {
        id: "2",
        roleTitle: "Compliance Officer",
        roleCheckBoxes: [
          { id: "2", text: "Can edit business profile", disabled: false, checked: false },
        ],
        disabled: false,
        checked: false
      },
      {
        id: "3",
        roleTitle: "Account Manager",
        roleCheckBoxes: [
          { id: "3", text: "Can view balance", disabled: false, checked: false },
          { id: "4", text: "Can fund balance", disabled: false, checked: false },
          { id: "5", text: "Can view balance histories", disabled: false, checked: false },
          { id: "6", text: "Can create and view transactions", disabled: false, checked: false },
          { id: "7", text: "Can download report", disabled: false, checked: false },
        ],
        disabled: false,
        checked: false
      },
      {
        id: "4",
        roleTitle: "Developper",
        roleCheckBoxes: [
          { id: "8", text: "Can view API keys", disabled: false, checked: false },
        ],
        disabled: false,
        checked: false
      }


    ]



  }
  updateUserHabilitations() {
    this.inviting = true
    this.collaborationTeamService.updateAccountHabilitations(this.selectedUser.email, this.getPermissions(), this.credentials).subscribe(
      response => {
        if (response['status'] == true) {
          this.inviting = false
          this.invitation = false
          this.openSnackbar(response['message']);
          this.getSecondariesAccount()
          this.initializeForm()
        } else { 
          this.inviting = false
          if(response['status'] == false){
            this.openSnackbar(response['message']);
          }else{
            this.showInternalServerErrorMessage()
          } 
        }
      }
    )
  }
  removeUser(user) {
    this.collaborationTeamService.removeSecondaryUser(user.email, this.credentials).subscribe(
      response => {
        if (response['status'] == true) {
          this.openSnackbar(response['message']);
          this.getSecondariesAccount()
        }else{
          if(response['status'] == false){
            this.openSnackbar(response['message']);
          }else{
            this.showInternalServerErrorMessage()
          }
        }
      }
    )
  }

  getSecondaryUserHabilitations() {  
    this.collaborationTeamService.getSecondaryUserHabilitation(this.selectedUser.email, this.credentials).subscribe(
      response => { 
        this.selectedUserHabilitations = response['data']['habilitations']  
        if (this.selectedUserHabilitations != undefined) {
          this.selectedUserHabilitations = this.selectedUserHabilitations.split(',')
          this.checkBoxGroups = [ 
            {
              id: "1",
              roleTitle: "Admin",
              roleCheckBoxes: [
                { id: "1", text: "Will have all right as you", disabled: false, checked: false }
              ],
              disabled: false,
              checked: false
            },
            {
              id: "2",
              roleTitle: "Compliance Officer",
              roleCheckBoxes: [
                { id: "2", text: "Can edit business profile", disabled: false, checked: false },
              ],
              disabled: false,
              checked: false
            },
            {
              id: "3",
              roleTitle: "Account Manager",
              roleCheckBoxes: [
                { id: "3", text: "Can view balance", disabled: false, checked: false },
                { id: "4", text: "Can fund balance", disabled: false, checked: false },
                { id: "5", text: "Can view balance histories", disabled: false, checked: false },
                { id: "6", text: "Can create and view transactions", disabled: false, checked: false },
                { id: "7", text: "Can download report", disabled: false, checked: false },
              ],
              disabled: false,
              checked: false
            },
            {
              id: "4",
              roleTitle: "Developper",
              roleCheckBoxes: [
                { id: "8", text: "Can view API keys", disabled: false, checked: false },
              ],
              disabled: false,
              checked: false
            } 
          ]  
          for (let [index, g] of this.checkBoxGroups.entries()) {
            for (let role of g.roleCheckBoxes) {
              for (let hab of this.selectedUserHabilitations) {
                if (role.id == hab) {
                  role.checked = true  
                  this.selectRoleAuthorizationCustom(index)
                }
              }
            }
          } 
        }
      }
    )
  }

  sendInvitation() {
    this.openAuthorisationConfirmation();
  }

  getPermissions() {
    let permission_ids = [];

    for (let g of this.checkBoxGroups) {
      for (let role of g.roleCheckBoxes) {

        if (role.checked) {
          permission_ids.push(role.id)
        }

        /*
        for (let checkbox of role.checkBoxes) {
          if (checkbox.checked) {
            permission_ids.push(checkbox.id)
          }
        }
        */
      }
    }


    return permission_ids.join(",")
  }



}
