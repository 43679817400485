import { ApiComponent } from './pages/dashboards/api/api.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { HomeComponent } from './pages/dashboards/home/home.component';
import { TopUpTransactionComponent } from './pages/dashboards/top-up-transaction/top-up-transaction.component';
import { ProfilComponent } from './pages/dashboards/profil/profil.component';
import { TransactionsComponent } from './pages/dashboards/transactions/transactions.component';
import { ReportsComponent } from './pages/dashboards/reports/reports.component';
import { DashboardAnalyticsComponent } from './pages/dashboards/dashboard-analytics/dashboard-analytics.component';
import { CollectionsReportComponent } from './pages/dashboards/reports/collections-report/collections-report.component';
import { BulkDisbursementComponent } from './pages/dashboards/disburse-cash/bulk-disbursement/bulk-disbursement.component';
import {AllTransfersComponent} from './pages/dashboards/reports/all-transfers/all-transfers.component';
import {MakeTransferComponent} from './pages/dashboards/make-transfer/make-transfer.component';
import {ChangePasswordComponent} from './pages/dashboards/change-password/change-password.component';
import { DisburseCashComponent } from './pages/dashboards/disburse-cash/disburse-cash.component';
import { MakeMobileDisbursmentComponent } from './pages/dashboards/make-mobile-disbursment/make-mobile-disbursment.component';
import { MakeTransferChoiceComponent } from './pages/dashboards/make-transfer-choice/make-transfer-choice.component';
import { CollaboratorsTeamComponent } from './pages/dashboards/profil/collaborators-team/collaborators-team.component';
import { WalletComponent } from './pages/dashboards/wallet/wallet.component';
import { ViewTransactionsComponent } from './pages/dashboards/view-transactions/view-transactions.component';
import { BeneficiariesComponent } from './pages/dashboards/beneficiaries/beneficiaries.component';
import { AddFundsComponent } from './pages/dashboards/add-funds/add-funds.component';
import { SwapComponent } from './pages/dashboards/swap/swap.component';
import { DeleteAccountComponent } from './pages/pages/auth/delete-account/delete-account.component';
import { FinancialStatmentComponent } from './pages/dashboards/financial-statment/financial-statment.component';
import { FinancialStatmentReportComponent } from './pages/dashboards/financial-statment-report/financial-statment-report.component';
import { UserNotificationModalComponent } from './pages/dashboards/user-notification-modal/user-notification-modal.component';
import { Step2Component } from './pages/pages/auth/register/step2/step2.component';
import { HistoricalTransactionsComponent } from './pages/dashboards/historical-transactions/historical-transactions.component';

const routes: VexRoutes = [
  /* vtx
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  */

  {
    path: 'auth',
    children: [
      
      {
        path: 'login',
        loadChildren: () =>
          import('./pages/pages/auth/login/login.module').then(
            (m) => m.LoginModule
          ),
      },
      {
        path: 'register',
        loadChildren: () =>
          import('./pages/pages/auth/register/register.module').then(
            (m) => m.RegisterModule
          ),
      },

      { 
        path: 'join-team',
        loadChildren: () =>
          import('./pages/pages/auth/join-register/join-register.module').then(
            (m) => m.JoinRegisterModule
          ),
      },
      {
        path: 'two-factor',
        loadChildren: () =>
          import('./pages/pages/auth/two-factor/two-factor.module').then(
            (m) => m.TwoFactorModule
          ),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import(
            './pages/pages/auth/forgot-password/forgot-password.module'
          ).then((m) => m.ForgotPasswordModule),
      },
      {
        path: 'email-verification',
        loadChildren: () =>
          import(
            './pages/pages/auth/email-verification/email-verification.module'
          ).then((m) => m.EmailVerificationModule),
      },
      {
        path: 'code-verification',
        loadChildren: () =>
          import(
            './pages/pages/auth/code-verification/code-verification.module'
          ).then((m) => m.CodeVerificationModule),
      },
      {
        path: 'delete-account',
        loadChildren: () =>
          import('./pages/pages/auth/delete-acount2/delete-acount2.module').then(
            (m) => m.DeleteAcount2Module
          ),
      },
    ],
  },

  {
    path: 'dashboards',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'analytics',
        component: DashboardAnalyticsComponent,
      },
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'balance',
        component: TopUpTransactionComponent,
        data:{
          title: "Balance"
        }
      },
      {
        path: 'balance/:currency',
        component: TopUpTransactionComponent,
        data:{
          title: "Balance"
        }
      },




      {
        path: 'financial-statment',
        component: FinancialStatmentComponent,
        data:{
          title: "Financial Statment"
        }
      },
      {
        path: 'financial-statment/:currency',
        component: FinancialStatmentComponent,
        data:{
          title: "Financial Statment"
        }
      },
       

      {
        path: 'financial-statment-report/:currency/:date_start/:date_end',
        component: FinancialStatmentReportComponent,
        data:{
          title: "Financial Statment"
        }
      },


      {
        path: 'user-notification',
        component: UserNotificationModalComponent,
        data:{
          title: "Notifications"
        }
      },

 
      {
        path: 'cash-out-transactions',
        component: ReportsComponent,
        data:{
          title: "Mobile Wallet"
        }
      },
      {
        path: 'cash-in-transactions',
        component: CollectionsReportComponent,
        data:{
          title: "Mobile Wallet"
        }
      },
      {
        path: 'transactions',
        component:  HistoricalTransactionsComponent, //ViewTransactionsComponent,
        data:{
          title: "Transactions"
        }
      },
      {
        path: 'transactions/:type_transaction',
        component: HistoricalTransactionsComponent, //ViewTransactionsComponent,
        data:{
          title: "Transactions"
        }
      },
      {
        path: 'make-transfer-c',
        component:MakeTransferChoiceComponent,
        data:{
          title: "Make Transfer"
        }
      },
      {
        path: 'wallet',
        component:WalletComponent,
        data:{
          title: "Wallets"
        }
      },
      {
        path: 'swap',
        component:SwapComponent,
        data:{
          title: "Swap"
        }
      },
      {
        path: 'make-transfer',
        component: MakeTransferComponent,
        data:{
          title: "Bank"
        }
      },
      {
        path: 'make-transfer/:country',
        component: MakeTransferComponent,
        data:{
          title: "Bank"
        }
      },
      {
        path: 'make-mobile-disbursment',
        component: MakeMobileDisbursmentComponent,
        data:{
          title: "Mobile"
        }
      },
      {
        path: 'all-transactions',
        component: AllTransfersComponent,
        data:{
          title: "Bank"
        }
      },
      
      {
        path: 'beneficiary',
        component: BeneficiariesComponent,
        data:{
          title: "Beneficiaries"
        }
      },
      {
        path: 'profile',
        component: ProfilComponent,
        data:{
          title: "Settings"
        }
      },
      
      {
        path: 'profile/:team_option',
        component: ProfilComponent,
        data:{
          title: "Settings"
        }
      },
      {
        path: 'team',
        component: CollaboratorsTeamComponent,
        data:{
          title: "Team"
        }
      },
      {
        path: 'team/:id_team_member',
        component: CollaboratorsTeamComponent,
        data:{
          title: "Team"
        }
      },
      
      {
        path: 'add-funds',
        component: AddFundsComponent,
        data:{
          title: "Add Funds"
        }
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        data:{
          title: "Reset"
        }
      },
      {
        path: 'bulk-disbursement',
        component: BulkDisbursementComponent,
        data:{
          title: "Bulk Disbursement"
        }
      },
      {
        path: 'api',
        component: ApiComponent,
        data:{
          title: "API ",
          id: "api"
        }
      },
      {
        path: '',
        loadChildren: () =>
          import(
            './pages/dashboards/dashboard-analytics/dashboard-analytics.module'
          ).then((m) => m.DashboardAnalyticsModule),
      },
      {
        path: 'apps',
        children: [
          {
            path: 'chat',
            loadChildren: () =>
              import('./pages/apps/chat/chat.module').then((m) => m.ChatModule),
            data: {
              toolbarShadowEnabled: true,
            },
          },
          {
            path: 'mail',
            loadChildren: () =>
              import('./pages/apps/mail/mail.module').then((m) => m.MailModule),
            data: {
              toolbarShadowEnabled: true,
              scrollDisabled: true,
            },
          },
          {
            path: 'social',
            loadChildren: () =>
              import('./pages/apps/social/social.module').then(
                (m) => m.SocialModule
              ),
          },
          {
            path: 'contacts',
            loadChildren: () =>
              import('./pages/apps/contacts/contacts.module').then(
                (m) => m.ContactsModule
              ),
          },
          {
            path: 'calendar',
            loadChildren: () =>
              import('./pages/apps/calendar/calendar.module').then(
                (m) => m.CalendarModule
              ),
            data: {
              toolbarShadowEnabled: true,
            },
          },
          {
            path: 'aio-table',
            loadChildren: () =>
              import('./pages/apps/aio-table/aio-table.module').then(
                (m) => m.AioTableModule
              ),
          },
          {
            path: 'help-center',
            loadChildren: () =>
              import('./pages/apps/help-center/help-center.module').then(
                (m) => m.HelpCenterModule
              ),
          },
          {
            path: 'scrumboard',
            loadChildren: () =>
              import('./pages/apps/scrumboard/scrumboard.module').then(
                (m) => m.ScrumboardModule
              ),
          },
          {
            path: 'editor',
            loadChildren: () =>
              import('./pages/apps/editor/editor.module').then(
                (m) => m.EditorModule
              ),
          },
        ],
      },
      {
        path: 'pages',
        children: [
          {
            path: 'pricing',
            loadChildren: () =>
              import('./pages/pages/pricing/pricing.module').then(
                (m) => m.PricingModule
              ),
          },
          {
            path: 'faq',
            loadChildren: () =>
              import('./pages/pages/faq/faq.module').then((m) => m.FaqModule),
          },
          {
            path: 'guides',
            loadChildren: () =>
              import('./pages/pages/guides/guides.module').then(
                (m) => m.GuidesModule
              ),
          },
          {
            path: 'invoice',
            loadChildren: () =>
              import('./pages/pages/invoice/invoice.module').then(
                (m) => m.InvoiceModule
              ),
          },
          {
            path: 'error-404',
            loadChildren: () =>
              import('./pages/pages/errors/error-404/error-404.module').then(
                (m) => m.Error404Module
              ),
          },
          {
            path: 'error-500',
            loadChildren: () =>
              import('./pages/pages/errors/error-500/error-500.module').then(
                (m) => m.Error500Module
              ),
          },
        ],
      },
      {
        path: 'ui',
        children: [
          {
            path: 'components',
            loadChildren: () =>
              import('./pages/ui/components/components.module').then(
                (m) => m.ComponentsModule
              ),
          },
          {
            path: 'forms/form-elements',
            loadChildren: () =>
              import(
                './pages/ui/forms/form-elements/form-elements.module'
              ).then((m) => m.FormElementsModule),
            data: {
              containerEnabled: true,
            },
          },
          {
            path: 'forms/form-wizard',
            loadChildren: () =>
              import('./pages/ui/forms/form-wizard/form-wizard.module').then(
                (m) => m.FormWizardModule
              ),
            data: {
              containerEnabled: true,
            },
          },
          {
            path: 'icons',
            loadChildren: () =>
              import('./pages/ui/icons/icons.module').then(
                (m) => m.IconsModule
              ),
          },
          {
            path: 'page-layouts',
            loadChildren: () =>
              import('./pages/ui/page-layouts/page-layouts.module').then(
                (m) => m.PageLayoutsModule
              ),
          },
        ],
      },
      {
        path: 'documentation',
        loadChildren: () =>
          import('./pages/documentation/documentation.module').then(
            (m) => m.DocumentationModule
          ),
      },
      {
        path: '**',
        loadChildren: () =>
          import('./pages/pages/errors/error-404/error-404.module').then(
            (m) => m.Error404Module
          ),
      },
    ],
  },

  {path:'delete-account2', component: DeleteAccountComponent},
  
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/pages/auth/login/login.module').then(
        (m) => m.LoginModule
      ),
  },
  {
    path: 'delete-account',
    loadChildren: () =>
      import('./pages/pages/auth/delete-acount2/delete-acount2.module').then(
        (m) => m.DeleteAcount2Module
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/pages/auth/register/register.module').then(
        (m) => m.RegisterModule
      ),
  },
   
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/pages/auth/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'coming-soon',
    loadChildren: () =>
      import('./pages/pages/coming-soon/coming-soon.module').then(
        (m) => m.ComingSoonModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'corrected',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule, QuicklinkModule],
})
export class AppRoutingModule {}
