<!-- (ngSubmit)="save()" -->

<form>
    <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
        <h3 class="m-0" fxFlex="auto">
            <!-- <span>
          <mat-icon>work_outline</mat-icon>
        </span> -->
            ACCOUNT UNDERGOING VERIFICATION
        </h3>
        <!-- <button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
          <mat-icon [icIcon]="icMoreVert"></mat-icon>
        </button> -->
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
    </div>
    <mat-divider class="-mx-6 text-border"></mat-divider>
    <mat-dialog-content fxLayout="column" class="content-part" >
         
        <h5> Our team is currently reviewing your account. Upon successful  </h5>
        <h5>completion of the verification process, you will enjoy unrestricted  </h5>  
        <h5>access to all the functionalities and benefits of PAL. </h5>

    </mat-dialog-content>
    <mat-dialog-actions align="end" class="button">
        <button mat-button mat-dialog-close type="button" class="cancel-button">CANCEL</button>
        <button color="primary" mat-button type="button" (click)="redirect()" class="send-button">Go to Profile</button>
    </mat-dialog-actions>
</form>