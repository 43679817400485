// This file can be replaced during build by using the 'fileReplacements' array.
// 'ng build --prod' replaces 'environment.ts' with 'environment.prod.ts'.
// The list of file replacements can be found in 'angular.json'.
export const baseurlExp = {
  baseurl: 'https://api-prod-v0.pals.africa/api',
  imageBaseURL : 'https://pals.africa/api-test2'
}
export const environment = {
  production: false,
  test: false,
  payStackCheckoutUrl: baseurlExp.baseurl+'/securewithpaystack',
  payStackReleaseUrl: baseurlExp.baseurl+'/initiaterelease',
  processPayoutUrl: baseurlExp.baseurl+'/processbusinesspayout',
  checkTransactionStatusUrl: baseurlExp.baseurl+'/chektransactionstatus',
  addAccountUrl: baseurlExp.baseurl+'/createrecipient',
  updateAccountUrl: baseurlExp.baseurl+'/updateuseraccount',
  createTransactionUrl: baseurlExp.baseurl+'/newtransaction',
  deleteAccountUrl: baseurlExp.baseurl+'/deleteduseraccount',
  getTransactionByIdUrl: baseurlExp.baseurl+'/getusertransaction',

  getTransactionsListUrl: baseurlExp.baseurl+'/getmerchanttransactions/', 
  getHistoricalTransactionsListUrl: baseurlExp.baseurl+'/historicaltransactions?user_id=',

  verifyReleaseCodeUrl: baseurlExp.baseurl+'/verifycode',
  cancelTransactionUrl: baseurlExp.baseurl+'/cancelescrowtransaction',
  getBusinessUserPayoutsUrl: baseurlExp.baseurl+'/getbusinessuserpayouts',
  getBusinessTransactionsSummaryUrl: baseurlExp.baseurl+'/getmerchantusertransactionssummary/',
  updateDeliveyUrl: baseurlExp.baseurl+'/updatedeliveryphone',
  getUserAccountDetailsUrl: baseurlExp.baseurl+'/getbusinessuseraccountdetails',
  registerPalUserUrl: baseurlExp.baseurl+'/registerpaluser',
  registerUserUrl: baseurlExp.baseurl+'/register',
  loginUrl: baseurlExp.baseurl+'/login',
  refreshUrl: baseurlExp.baseurl+'/refresh',
  
  enableTwoFactorAuthUrl:baseurlExp.baseurl+'/enabletwofactorauth',
  disableTwoFactorAuthUrl:baseurlExp.baseurl+'/disabletwofactorauth',
  sendCodeForTwoFactorAuthUrl:baseurlExp.baseurl+'/sendcodefortwofactorauth',
  verifyCodeForTwoFactorAuthUrl:baseurlExp.baseurl+'/verifycodefortwofactorauth',

  getBusinessSummaryUrl: baseurlExp.baseurl+'/getmerchantusersummary/',
  getBusinessDataUrl: baseurlExp.baseurl+'/getuserbusiness/',
  addBusinessUrl: baseurlExp.baseurl+'/addbusiness',
  updateBusinessUrl: baseurlExp.baseurl+'/updatebusiness/',
  getModulesDataUrl: baseurlExp.baseurl+'/getmodulesdata',
  createTransferUrl: baseurlExp.baseurl+'/createtransfer',
  createBankTransferUrl: baseurlExp.baseurl+'/initiatebanktransfer',
  getbanklist: baseurlExp.baseurl+'/getbankslist/',
  getbankbranches: baseurlExp.baseurl+'/getbankbranches/',
  createBulkTransferUrl: baseurlExp.baseurl+'/createbulktransfer',
  requestTopUpUrl: baseurlExp.baseurl+'/requesttopup',
  getUserTopUpsUrl: baseurlExp.baseurl+'/getusertopups/',
  getUserBalancesUrl: baseurlExp.baseurl+'/getbusinessuserbalances/',
  getUserCollectionsBalancesUrl: baseurlExp.baseurl+'/getbusinessusercollectionbalances/',
  sendOTPtoEmailUrl: baseurlExp.baseurl+'/initresetpwd',
  
  sendOTPtoEmailForRegisterUrl: baseurlExp.baseurl+'/sendotptoemail',
  updatePasswordUrl:  baseurlExp.baseurl+'/forgotpassupdate',
  updatePswd: baseurlExp.baseurl+'/updatepwd',
  resetPswd: baseurlExp.baseurl+'/resetpwd',
  verifyEmailUrl: baseurlExp.baseurl+'/verifyemail',

  getTransactionsReportUrl: baseurlExp.baseurl+'/gettransactionsreport/',
  getBankTransactionsReportUrl: baseurlExp.baseurl+'/getbusinessbanktransactions/',

  getClientDetailsUrl: baseurlExp.baseurl+'/getmomoclientdata',
  getBulkClientDetailsUrl: baseurlExp.baseurl+'/getbulkmomoclientdata',
  getBulkClientsUrl: baseurlExp.baseurl+'/getmomoclientsdata',

  getOperatorsDataUrl: baseurlExp.baseurl+'/getoperatorsdata',

  getbankbeneficiaries: baseurlExp.baseurl+'/getbankbeneficiaries/',
  addbankbeneficiaryUrl: baseurlExp.baseurl+'/addbankbeneficiary',
  deletebankbeneficiaryUrl:baseurlExp.baseurl+'/deletebankbeneficiary',

  getmobilebeneficiaries: baseurlExp.baseurl+'/getmobilebeneficiaries/',
  addmobilebeneficiaryUrl: baseurlExp.baseurl+'/addmobilebeneficiary',
  deletemobilebeneficiaryUrl:baseurlExp.baseurl+'/deletemobilebeneficiary',

  
  inviteBySmsUrl:baseurlExp.baseurl+'/invitebysms',
  getSecondariesAccountUrl:baseurlExp.baseurl+'/getsecondariesaccount',
  updateAccountHabilitationsUrl:baseurlExp.baseurl+'/updateaccounthabilitations',
  getSecondaryUserHabilitationUrl: baseurlExp.baseurl+'/getsecondaryuserhabilitation',
  removeSecondaryUserUrl: baseurlExp.baseurl+'/removesecondaryuser',
  getSecoundaryAccountEmailUrl: baseurlExp.baseurl+'/getsecoundaryaccountemail',
  

  exchangeRateUrl: baseurlExp.baseurl+'/exchangerates', 
  processExchangeUrl: baseurlExp.baseurl+'/processexchange',
  userExchangeUrl: baseurlExp.baseurl+'/userexchanges/',
  deleteAccountRequestUrl: baseurlExp.baseurl+'/deleteaccountrequest',
  validateDeleteAccountRequestUrl: baseurlExp.baseurl+'/validatedeleteaccountrequest',


  getUserFinancialStatment: baseurlExp.baseurl+'/getuserfinancialstatment/',
  getNotificationsUrl : baseurlExp.baseurl+'/getnotifications/',
  MarkNotificationReadedUrl : baseurlExp.baseurl+'/marknotificationreaded/'

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as 'zone.run', 'zoneDelegate.invokeTask'.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
