import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeInUp400ms } from '../../../../@vex/animations/fade-in-up.animation';
import { stagger40ms } from '../../../../@vex/animations/stagger.animation';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BANK_BRANCHES, BUSINESS_DATA_KEY, COUNTRIES, COUNTRY_CODES, USER_SESSION_KEY } from '../../../Models/constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TransactionsService } from 'src/app/services/transactions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BeneficiariesService } from 'src/app/services/beneficiaries.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export class BankTransfer {
    country: any;
    amount: number;
    bank: string;
    narration: string;
    accountNumber: string;
    accountName: string;
}

//BankTransferXOFData
export interface BankTransferData {
    account_bank: string;
    account_number: string;
    beneficiary_name: string;
    amount: number;
    currency: string;
    narration: string;
    reference?: string;
    user_id: string;
    country?: string;
    rib?: string;
    bank_code?: string;
    bank_name?: string;
    phone_no?: string;
    email: string;
    counter_code?: string;
    swift_code?: string;
    bank_branch: string;

    bank_transfer_api: string;
    bank_id: string
}

@Component({
    selector: 'vex-make-transfer',
    templateUrl: './make-transfer.component.html',
    styleUrls: ['./make-transfer.component.scss'],
    animations: [fadeInUp400ms, stagger40ms],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline',
            } as MatFormFieldDefaultOptions,
        },
    ],
})
export class MakeTransferComponent implements OnInit, OnDestroy {
    layoutCtrl = new FormControl('boxed');

    unsubscribe$ = new Subject();
    form: FormGroup;
    countries = COUNTRIES;
    bankTransfer: BankTransferData;

    countryData = {
        BJ: {
            currency: 'XOF',
            code: '+229',
            operators: [
                { name: 'MTN', value: 'mtn' },
                { name: 'MOOV', value: 'moov' },
            ],
        },
        CI: {
            currency: 'XOF',
            code: '+225',
            operators: [
                { name: 'MTN', value: 'mtn' },
                { name: 'ORANGE', value: 'orange' },
                { name: 'MOOV', value: 'moov' },
            ],
        },
        GH: {
            currency: 'GHS',
            code: '+233',
            operators: [
                { name: 'MTN', value: 'mtn' },
                { name: 'VODAFONE', value: 'vodafone' },
                { name: 'AIRTEL-TIGO', value: 'airtel-tigo' },
            ],
        },
        TG: {
            currency: 'XOF',
            code: '+228',
            operators: [{ name: 'MOOV', value: 'moov' }],
        },
        SN: {
            currency: 'XOF',
            code: '+221',
            operators: [
                { name: 'MTN', value: 'MTN' },
                { name: 'ORANGE', value: 'orange' },
            ],
        },
        NG: {
            currency: 'NGN',
            code: '+234',
            operators: [
                { name: 'MTN', value: 'MTN' },
                { name: 'AIRTEL-TIGO', value: 'airtel-tigo' },
            ],
        },
    };
    banks = ['BSIC', 'NSIA', 'BOA', 'ECOBANK'];
    availableCountries = [
        { code: 'BJ', label: 'BENIN', type: "" },
        { code: 'CI', label: 'COTE D\'IVOIRE', type: "" },
        { code: 'TG', label: 'TOGO', type: "" },
        { code: 'SN', label: 'SENEGAL', type: "" },
        { code: 'GH', label: 'GHANA' },
        { code: 'ML', label: 'MALI', type: "" },
        { code: 'GW', label: 'GUINEE B.', type: "" },
        { code: 'NE', label: 'NIGER', type: "" },
        { code: 'NG', label: 'NIGERIA', type: "finpesa" }, // vtx 
        { code: 'BF', label: 'BURKINA FASO', type: "" }];

    networkProviders = ['mtn', 'orange'];
    currencies = ['GHS', 'XOF', 'XAF', 'NGN'];
    bankBranches;
    confirmedTransfer = false;
    submitBtnText = 'Confirm Transfer';
    errorMessage = '';
    banksData: any;
    userBusinessData: any;
    credentials: string;
    isDisbursing: boolean;
    hasError: boolean;
    narration = '';
    isSwiftCodeNeeded = false;
    isBankBranchNeeded = false;
    phoneNumberValidationPattern = /^[+][0-9]{0,15}$/;
    dailingCode: string = '+229';
    currency = 'XOF'
    maxLength: number = 8;
    placeHolder = '96040522';
    bankTransferApi; string;
    accountNumberLength: number = 24;
    maxAccountNumberLength: number = 24
    maxAmount: number = 20000000;
    accountNumberTitle: string = 'Account number / IBAN';
    accountNumberPlaceholder: string = 'Ex : BJ0670150550509788803290';
    // tslint:disable-next-line:variable-name
    country_codes = COUNTRY_CODES;
    operatorPrefixData;
    validationMessages = {
        repeat_phone_no: {
            pattern: 'Only digits allowed starting with ',
            required: 'Receiver\'s Phone Field  is required.',
            min: 'Please provide a correct phone number',
        },
        phone_no: {
            pattern: 'Only digits allowed starting with ',
            required: 'Receiver\'s Phone Field  is required.',
            min: 'Please provide a correct phone number',
            invalidPrefix: 'Invalid phone number prefix, Operator not found!',
        },
        amount: {
            pattern: 'Only number allowed',
            required: 'Amount This Field  is required.',
            amountExceededLimit: 'Amount in XOF should not exceed 1 700 000',
            hasComma: 'XOF does not support decimals',
        },
    };
    isLoadingBankData: boolean;
    unsubscribeBankChanges = new Subject();
    userData: any;
    beneficiaries: any;
    beneficiary: any;


    selectBenefeficiary: boolean = false
    afterBeneficiarySelected: boolean = false
    showAmountInput: boolean = true
    showTransferReasonInput: boolean = true
    showTransferReciepientEmail: boolean = true
    showCancelSelectBenef: boolean = false
    essaie: boolean = false
    countryBanks: any = []
    countryWithoutBanks: any = []
    defaultCountry: string = 'CI'

    constructor(
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private transactionService: TransactionsService,
        private beneficiariesService: BeneficiariesService,
        private router: Router,
        private route: ActivatedRoute,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
    ) {

        this.route.paramMap.subscribe((params) => {
            const id = params.get('country');
            this.defaultCountry = id
        });
        const businessData = localStorage.getItem(BUSINESS_DATA_KEY);
        if (businessData !== 'undefined') {
            this.userBusinessData = JSON.parse(businessData);
        }
        const sessionData = JSON.parse(localStorage.getItem(USER_SESSION_KEY));
        this.userData = sessionData;
        this.credentials = `${this.userData?.api_secret_key_live}:${this.userData?.api_public_key_live}`;

        //this.credentials = `${this.userBusinessData?.api_secret_key_live}:${this.userBusinessData?.api_public_key_live}`;
        this.setOperatorDialingCodes();

        iconRegistry.addSvgIcon(
            'side-cash-in',
            sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svgs/cash-in.svg')
        );
    }

    ngOnInit(): void {

        this.getBeneficiariesList()
        this.getBankList()
        if (this.defaultCountry == '' || this.defaultCountry == undefined) {
            this.defaultCountry = 'CI'
        }
        this.form = this.formBuilder.group({
            country: [this.defaultCountry, [Validators.required]],
            amount: ['', [Validators.required, Validators.pattern(/^[0-9]*$/), Validators.max(this.maxAmount)]],
            account_bank: ['', [Validators.required]],
            narration: [''],
            account_number: ['', [Validators.required,
            Validators.pattern(`^[a-zA-Z0-9]{${this.accountNumberLength}}$`)]],
            beneficiary_name: ['', [Validators.required]],
            currency: [''],
            bank_branch: [''],
            phone_no: [''],
            email: ['', Validators.email],
            swift_code: [],
            beneficiary: [''],
            beneficiary_id: [0]
        });

        this.form.get('country').valueChanges.subscribe((value) => {


            this.setBankTransferApi(value);
            if (value == 'GH') {
                this.currency = 'GHS';
            } else if (value == 'NG') {
                this.currency = 'NGN';
            } else {
                this.currency = 'XOF';
            }
            this.setAccountNumberLength(value);
            this.setAccountNumberTitle(value);
            this.setAccountNumberPlaceholder(value);
            this.setMaxAmount(value);

            // mettre a jour la validation pour prendre en compte ce changement de country dailing code
            // this.form.get('phone_no').setValue(this.country_codes.find(country => country.code === value).dial_code);

            this.setIsSwiftCodeNeeded(value);
            this.setIsBankBranchNeeded(value);
            let bankGetParam = ''
            bankGetParam = this.bankTransferApi == 'finpesa' ? this.currency : value;
            this.getbankForSelectedCountry(value)
        });
        this.form.get('account_bank').valueChanges.pipe(takeUntil(this.unsubscribeBankChanges)).subscribe((value) => {

            this.getBankBranches(this.credentials, value);
        });
        //this.form.get('country').setValue(this.availableCountries[1]?.code);  
        this.form.get('country').setValue(this.defaultCountry);
        this.form.get('beneficiary').valueChanges.subscribe(checked => {
            if (checked) {
                this.selectBenefeficiary = true
            } else {
                this.selectBenefeficiary = false;
            }
            this.form.patchValue({
                country: 'CI',
                account_number: '',
                amount: '',
                account_bank: '',
                beneficiary_name: '',
                email: '',
                bank_code: '',
                swift_code: '',
                beneficiary_id: 0
            });
            this.currency = 'XOF'
        });
        this.form.get('beneficiary_id').valueChanges.subscribe((value) => {
            value == 0 ? this.afterBeneficiarySelected = false : this.afterBeneficiarySelected = true
            if (!this.selectBenefeficiary) {
                this.showAmountInput = true
                this.showTransferReasonInput = true
                this.showTransferReciepientEmail = true
                this.showCancelSelectBenef = false
            } else if (this.afterBeneficiarySelected) {
                this.showAmountInput = true
                this.showTransferReasonInput = true
                this.showTransferReciepientEmail = true
                this.showCancelSelectBenef = true
            } else {
                this.showAmountInput = false
                this.showTransferReasonInput = false
                this.showTransferReciepientEmail = false
                this.showCancelSelectBenef = false
            }

            this.setBeneficiary(value)
        })

    }
    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    setOperatorDialingCodes() {
        this.transactionService
            .getOperatorDailingPrefixes(this.credentials)
            .pipe(take(1))
            .subscribe((response) => {
                if (response && response?.status === true) {
                    this.operatorPrefixData = response.data;
                }
            });
    }
    setIsSwiftCodeNeeded(country: string) {
        if (country === 'BJ' || country === 'CI' || country === 'SN' || country === 'NG') {
            this.isSwiftCodeNeeded = false;
        } else {
            this.isSwiftCodeNeeded = true;
        }
    }
    setIsBankBranchNeeded(country: string) {
        if (country === 'NG') {
            this.isBankBranchNeeded = false;
        } else {
            this.isBankBranchNeeded = true;
        }
    }
    setBankTransferApi(country: string) {
        const bankTransaferApi = this.availableCountries.find((data) => data.code === country)
        if (bankTransaferApi) {
            this.bankTransferApi = bankTransaferApi.type
        } else {
            this.bankTransferApi = ''
        }
    }
    setAccountNumberLength(country: string) {
        country === 'NG' ? this.accountNumberLength = 10 : this.accountNumberLength = 24
        country === 'NG' ? this.maxAccountNumberLength = 10 : this.maxAccountNumberLength = 24
        const accountNumberControl = this.form.get('account_number');
        if (accountNumberControl) {
            accountNumberControl.setValidators([
                Validators.required,
                Validators.pattern(`^[a-zA-Z0-9]{${this.accountNumberLength}}$`)
            ]);
            accountNumberControl.updateValueAndValidity();
        }
    }
    setAccountNumberTitle(country: string) {
        country === 'NG' ? this.accountNumberTitle = 'Account number' : this.accountNumberTitle = 'Account number / IBAN'
    }
    setAccountNumberPlaceholder(country: string) {
        country === 'NG' ? this.accountNumberPlaceholder = 'Ex : 9788803290' : this.accountNumberPlaceholder = 'Ex : BJ0670150550509788803290'
    }
    setMaxAmount(country: string) {
        country === 'NG' ? this.maxAmount = 3000000 : this.maxAmount = 20000000;
        const maxAmountControl = this.form.get('amount');
        if (maxAmountControl) {
            const validators = [
                Validators.required,
                Validators.pattern(/^[0-9]*$/)
            ];
            validators.push(Validators.max(this.maxAmount));
            maxAmountControl.setValidators(validators);
            maxAmountControl.updateValueAndValidity();
        }
    }
    setBeneficiary(beneficiary_id: number) {

        if (beneficiary_id != 0) {
            this.afterBeneficiarySelected = true;
            this.beneficiary = this.beneficiaries.find((value) => value.id == beneficiary_id)
            //account bank prend le code oi l'id de la banque
            //mais pour passer le validators dans le cas des countriss sans bank du beneficaire
            //jai set let this.beneficiary.bank_name
            this.form.get('account_bank').setValue(this.beneficiary.bank_name);
            this.form.get('country').setValue(this.beneficiary.beneficiary_country);
            this.form.get('swift_code').setValue(this.beneficiary.swift_code);
            this.form.patchValue({
                account_number: this.beneficiary.account_number,
                beneficiary_name: this.beneficiary.beneficiary_name,
                email: this.beneficiary.email,
                bank_code: this.beneficiary.bank_code,
                currency: this.beneficiary.currency,
            });
            this.currency = this.beneficiary.currency
            this.countryBanks.forEach((result) => {
                if (result.country === this.beneficiary.beneficiary_country) {
                    this.form.get('account_bank').setValue(this.getBankId());
                }
            });
        }
        this.afterBeneficiarySelected = false;

    }

    /*
    getBeneficiaryCountryByBenefCurrency(currency = '') {

        if (currency != '') {

            if (currency == 'NGN') {
                return 'NG'
            }
            else {
                return 'CI'
            }
        }
    }
    */


    getBankName() {
        let bankName = ''
        if (this.selectBenefeficiary) {
            // PARCOURIR LA LISTE DES BANKS DU PAYS
            this.countryBanks.forEach((result) => {
                if (result.country == this.beneficiary.beneficiary_country) {
                    for (let bank of result.banklist) {
                        if (bank.name == this.beneficiary.bank_name) {
                            bankName = bank.name
                        }
                    }
                }
            });
            // SI LE COUNTRY N'A PAS DE BANKS RETOURNES
            this.countryWithoutBanks.forEach((result) => {
                if (result == this.beneficiary.beneficiary_country) {
                    bankName = this.beneficiary.bank_name
                }
            })

        } else {

            if (!this.isSwiftCodeNeeded) {
                let bank
                if (this.form.get('country').value == 'NG') {
                    this.countryBanks.forEach((result) => {
                        bank = result.banklist.find(
                            (data) => data.id == this.form.get('account_bank').value
                        );
                    });
                } else {
                    this.countryBanks.forEach((result) => {
                        for (let bankp of result.banklist) {
                            if (bankp.code == this.form.get('account_bank').value) {
                                bank = bankp
                            }
                        }
                    });
                }
                bankName = bank?.name || 'N/A';
            } else {
                bankName = this.form.get('account_bank').value;
            }
        }
        return bankName
    }
    getCountryNameByCode(countryCode: string) {

        const country = this.availableCountries.find(
            (data) => data.code === countryCode
        );
        return country?.label || 'N/A';

    }
    getBankId() {

        let res = ''
        if (this.selectBenefeficiary) {
            this.countryBanks.forEach((result) => {

                if (result.country == this.beneficiary.beneficiary_country) {

                    for (let bank of result.banklist) {
                        if (bank.name == this.beneficiary.bank_name) {
                            if (this.beneficiary.beneficiary_country == 'NG') {
                                res = bank.id
                            } else {
                                res = bank.code
                            }
                        }
                    }

                }
            });
        } else {

            res = this.form.get('account_bank').value

        }
        return res

    }


    getCountryName() {
        const country = this.availableCountries.find(
            (data) => data.code === this.form.get('country').value
        );
        return country?.label || 'N/A';
    }

    getBankBranches(credentials, bankCode) {
        const selectedBank = this.banksData?.find(bank => bank.code === bankCode);

        if (selectedBank) {
            this.transactionService
                .getBankBranchDetails(credentials, selectedBank.id)
                .pipe(take(1))
                .subscribe((data) => {
                    this.bankBranches = data;
                    this.form.get('bank_branch').setValue(this.bankBranches && this.bankBranches[0].branch_name);
                });
        } else {
            this.bankBranches = null;
            this.form.get('bank_branch').setValue('');
        }
    }
    getMaxLength(country) {
        if (country == 'BJ') {
            return 8;
        } else {
            return 10;
        }
    }

    get formControl() {
        return this.form.controls;
    }

    get isFormReady(): boolean {
        return true;
    }

    cancelAction() {
        this.confirmedTransfer = false;
        this.submitBtnText = 'Confirm Transfer';
    }

    submitAction() {
        if (!this.form.valid) {
            this.errorMessage = 'Please fill up required fields';
            return;
        }
        this.confirmedTransfer = true;
        this.submitBtnText = 'Approve Transfer';
    }

    confirmTransfer() {
        this.confirmedTransfer = true;
        this.submitBtnText = 'Approve Transfer';
    }

    cancelConfirmTransfer() {
        this.form.reset();
        this.confirmedTransfer = false;
    }

    approveTransfer() {
        this.confirmedTransfer = false;
        this.submitBtnText = 'Confirm Transfer';
    }

    cancelApprovedTransfer() {
        this.confirmedTransfer = false;
        this.submitBtnText = 'Confirm Transfer';
    }
    cancelSelectBenef() {
        this.selectBenefeficiary = false
        this.form.patchValue({
            beneficiary: '',
            swift_code: '',
            account_bank: ''
        })
    }
    getFlag(country) {
        switch (country.toLowerCase()) {

            case "benin":
                return "<img src='assets/contryFlg/flags/1/bj.png'>"
            case "ghana":
                return "<img src='assets/contryFlg/flags/1/gh.png'>"
            case "togo":
                return "<img src='assets/contryFlg/flags/1/tg.png'>"
            case "nigeria":
                return "<img src='assets/contryFlg/flags/1/ng.png'>"
            case "cote d'ivoire":
                return "<img src='assets/contryFlg/flags/1/ci.png'>"
            case "senegal":
                return "<img src='assets/contryFlg/flags/1/se.png'>"
            case "mali":
                return "<img src='assets/contryFlg/flags/1/ml.png'>"
            case "niger":
                return "<img src='assets/contryFlg/flags/1/ne.png'>"
            case "côte d'ivoire":
                return "<img src='assets/contryFlg/flags/1/ci.png'>"
            case "guinee b.":
                return "<img src='assets/contryFlg/flags/1/gw.png'>"
            case "burkina faso":
                return "<img src='assets/contryFlg/flags/1/bf.png'>"
            default:
                return ''

        }

    }


    getBankList() {

        this.isLoadingBankData = true;

        const results = [];
        this.availableCountries.forEach((country) => {
            let bankGetParam = ''
            if (country.code == 'NG') {
                bankGetParam = 'NGN'
            } else {
                bankGetParam = country.code
            }


            this.transactionService
                .getBankDetails(this.credentials, bankGetParam, country.type)
                .pipe(take(1))
                .subscribe(
                    (bankDetails) => {
                        this.isLoadingBankData = false;


                        if (bankDetails != null && bankDetails != undefined) {

                            const result = { country: country.code, banklist: bankDetails };
                            this.countryBanks.push(result);



                            //INITIALISATION DE A LISTE DES BANK USED DANS LE FORMULAIRE
                            /*
                            if (country.code == 'CI') {
                                this.getbankForSelectedCountry(country.code)
                            }
                            */
                            if (country.code == this.defaultCountry) {
                                this.getbankForSelectedCountry(country.code)
                            }


                        } else {
                            this.countryWithoutBanks.push(country.code)
                            //this.form.get('account_bank').setValue('');
                        }
                    },
                    (error) => {
                        this.isLoadingBankData = false;
                        // Gérez les erreurs ici si nécessaire
                    }
                );
        });


    }
    getbankForSelectedCountry(country) {

        this.banksData = []
        this.countryBanks.forEach((result) => {

            if (result.country == country) {

                for (let bank of result.banklist) {
                    this.banksData.push(bank)
                }

            } 
        });

        if (this.selectBenefeficiary == false) {
            if (this.banksData.length > 0) {
                const selectedValue = this.banksData[0]?.code ?? this.banksData[0]?.id ?? '';
                this.form.get('account_bank').setValue(selectedValue);
            } else {
                this.form.get('account_bank').setValue('');
            }
        } else {
            // Autre logique si nécessaire
        }

    }
    getBeneficiariesList(filteredData = null) {
        this.beneficiariesService.getBankBeneficiaries(this.userData?.user_id, this.credentials)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                (beneficiaries) => {
                    this.beneficiaries = beneficiaries
                },
                (error) => {
                    console.error(error.message);
                }
            );

    }
    onSelectBeneficiary() {
        this.selectBenefeficiary = true
        this.form.patchValue({
            country: 'CI',
            account_number: '',
            amount: '',
            account_bank: '',
            beneficiary_name: '',
            email: '',
            bank_code: '',
            beneficiary_id: 0
        });
        this.currency = 'XOF'
    }



    initiateBankTransfer() {
        if (this.isAllowed("6")) {

            this.submitAction();
            this.isDisbursing = true;
            if (this.confirmedTransfer) {
                const transactiondetails: BankTransferData = {

                    account_bank: this.form.value.account_bank,
                    account_number: this.form.value.account_number,
                    beneficiary_name: this.form.value.beneficiary_name,
                    amount: this.form.value.amount,
                    currency: this.currency,
                    narration: this.form.value.narration,
                    user_id: this.userBusinessData.user_id,
                    country: this.form.value.country,
                    bank_branch: this.form.value.bank_branch,
                    bank_name: this.getBankName(),
                    // bank_code: this.form.value.bank_code,
                    phone_no: this.form.value.phone_no,
                    email: this.form.value.email,
                    swift_code: this.form.value.swift_code, 
                    bank_transfer_api: this.bankTransferApi,
                    bank_id: this.getBankId(),

                }; 
                this.transactionService
                    .createBankTransfer(transactiondetails, this.credentials)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(
                        (response) => {
                            this.isDisbursing = false;
                            if (response && response['status'] === true) {
                                this.openSnackbar(response['message']);
                                this.router.navigate(['dashboards/transactions']);
                            } else {
                                if (response && response['status'] === false) {
                                    this.hasError = true;
                                    this.errorMessage = response['message'];
                                    this.openSnackbar(response['message']);
                                }else{
                                    this.showInternalServerErrorMessage()
                                }
                            }
                        },
                        (error) => {
                            /*
                            this.isDisbursing = false;
                            this.hasError = true;
                            this.errorMessage =
                                error.message ||
                                'Something went wrong please try again or contact support';
                            this.openSnackbar(this.errorMessage);
                            */
                            this.showInternalServerErrorMessage()
                        }
                    );
            }

        } else {
            this.openSnackbar("You are not allowed");
        }


    }
    showInternalServerErrorMessage() {
        this.isDisbursing = false;
        this.hasError = true;
        this.errorMessage = "Error. Please retry again later."
        this.openSnackbar("Error. Please retry again later.");
    }

    openSnackbar(message) {
        this.snackBar.open(message, 'CLOSE', {
            duration: 5000,
            horizontalPosition: 'right',
        });
    }
    isAllowed(habl: string) {

        let habilitations = []
        let isAllow = false
        if (this.userData.secondaryAccount) {
            if (this.userData.habilitations != undefined) {
                habilitations = this.userData.habilitations.split(',')
                for (let hab of habilitations) {
                    if (hab == habl) {
                        isAllow = true
                    }
                }
            }
        } else {
            isAllow = true
        }
        return isAllow
    }














}
